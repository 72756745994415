import React, { useState, useEffect } from "react";
import Topbar from "./topBar";
import Sidebar from "./sideBar";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "react-tagsinput/react-tagsinput.css";
import { Link } from "react-router-dom";
import { getAllOrders } from "../actions/adminAction";
import { formatDate } from "../utils/dateUtils";
import { FiEye } from "react-icons/fi";
import { getStatusString } from "../constants/enumConstants";
import { FaImage } from "react-icons/fa";
import ImageModal from "../images/imageModal";
import { SEARCH_ORDERS, VIEW_DET, VIEW_IMG } from "../constants/url";
import { Tooltip } from "react-tooltip";
import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableContainer,
  TableHead,
  TableRow,
  LinearProgress,
  Grid,
  Button,
  Dialog,
  IconButton,
  Collapse,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  ALERT_BUTTON_OK,
  ERR_ALERT_TITLE,
  ERR_GETTING,
} from "../constants/alertMessage";
import { API as url1 } from "../config";
import { STRING_CONSTANTS } from "../constants/stringConstants";
import { privateApiCall } from "../api/privateApi";
import { getOrderStatus } from "../constants/orderStatus";

import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const Orders = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const localRole = JSON.parse(localStorage.getItem("role"));
  const permissions =
    localRole && localRole.permissions ? localRole.permissions : "All";
  const [values, setValues] = useState({
    error: "",
    orders: [],
    removed: false,
  });
  const [count, setCount] = useState(0);
  const [images, setImages] = useState([]);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [details, setDetails] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [expandedRows, setExpandedRows] = useState({});
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const { orders } = values;
   console.log(orders,"orders");
   
  const handleClose = () => {
    setOpen(false);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };

  const handleChangeStart = (date) => {
    setStartDate(date ? dayjs(date).format("MM/DD/YYYY") : "");
  };
  const handleChangeEnd = (date) => {
    setEndDate(date ? dayjs(date).format("MM/DD/YYYY") : "");
  };

  useEffect(() => {
    handleSearch(searchValue);
  }, [startDate, endDate, searchValue]);
  // useEffect(()=>{
  //   loadOrders()
  // },[])
  // useEffect(()=>{
  //   if (searchValue || startDate || endDate) {
  //     handleSearch();
  //   } else {
  //     loadOrders();
  //   }
  // },[startDate, endDate, searchValue])
  const loadOrders = () => {
    getAllOrders().then((response) => {
      console.log(response, "all order");
      if (response.status) {
        setValues({ ...values, orders: response.orders });

      } else {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `${ERR_GETTING}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      }
    });
  };
  const handleSearch = async (searchValue) => {
    setLoading(true);
    try {
      const requestUrl = `/app/orderSearch?&query=${searchValue}&startDate=${startDate}&endDate=${endDate}`;
      const response = await privateApiCall(requestUrl, "GET");
      console.log(response, "order response data");

      if (response.status) {
        setValues({ ...values, orders: response.data.orders });
        setPage(0)
      }
      setCount(response.data.orders.length);

    } catch (error) {}
    finally{
      setLoading(false);
    }
  };
  const edit = (cell, row, extraData, index) => {
    const hasImageData = [row.image1, row.image2, row.image3, row.image4].some(
      (image) => image !== undefined && image !== null && image !== ""
    );
    const handleImageClick = () => {
      const imagesData = [row.image1, row.image2, row.image3, row.image4];

      const filteredImagesData = imagesData.filter(
        (image) => image !== undefined && image !== null && image !== ""
      );

      setImages(filteredImagesData);
      setOpen(true);
    };

    const handlePOP = (e) => {
      setDetails(row);
      setOpen1(true);
    };

    return (
      <span>
        {hasImageData && (
          <i
            className="ml-1"
            data-tooltip-id="5"
            data-tooltip-content={`${VIEW_IMG}`}
          >
            {" "}
            <FaImage onClick={handleImageClick} color="green" size="17" />
          </i>
        )}
        <Tooltip id="5" />
        {permissions.some(
          (item) => item.keyword === "orders" && item.isDetailedInfo === true
        ) && (
          <>
            <i
              className="ml-1"
              data-tooltip-id="1"
              data-tooltip-content={`${VIEW_DET}`}
              style={{ fontStyle: "normal" }}
            >
              {" "}
              <FiEye onClick={handlePOP} color="#9368f3" size="20" />
              <Tooltip id="1" />
            </i>
          </>
        )}
      </span>
    );
  };



  const handleRowClick = (orderId) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [orderId]: !prevExpandedRows[orderId],
    }));
  };

  return (
    <div id="wrapper">
      <Topbar backgroundColor="#769A0F" />
      <Sidebar />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        {" "}
                        <Link to="/dashboard">
                          {STRING_CONSTANTS.DASHBOARD}
                        </Link>
                      </li>
                      <li className="breadcrumb-item active">
                        {STRING_CONSTANTS.ORDERS}
                      </li>
                    </ol>
                  </div>
                  <h4 className="page-title">{STRING_CONSTANTS.ORDERS} ({count})</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card-box">
                  <div
                    style={{
                      width: "98%",
                    }}
                  >
                    <div
                      className="row container-fluid"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {permissions.some(
                        (item) =>
                          item.keyword === "orders" &&
                          item.isDateSearch === true
                      ) && (
                        <>
                          <div style={{ paddingRight: "16px" }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DatePicker"]}>
                                <DatePicker
                                  label="Start Date&nbsp;&nbsp;&nbsp;"
                                  format="DD/MM/YYYY"
                                  defaultValue={dayjs(startDate)}
                                  name="startDate"
                                  onChange={handleChangeStart}
                                  required
                                  slotProps={{
                                    textField: {
                                      error:
                                        !!startDate &&
                                        !dayjs(startDate).isValid(),
                                      sx: {
                                        width: "160px", // Adjust width
                                        "& .MuiInputBase-root": {
                                          fontSize: "11px", // Adjust font size
                                          padding: "-4px", // Adjust padding
                                        },
                                        "& .MuiInputLabel-root": {
                                          fontSize: "14px", // Adjust label size
                                        },
                                      },
                                    },
                                  }}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </div>

                          <div>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DatePicker"]}>
                                <DatePicker
                                  label="End Date&nbsp;&nbsp;&nbsp; "
                                  format="DD/MM/YYYY"
                                  defaultValue={dayjs(endDate)}
                                  name="endDate"
                                  onChange={handleChangeEnd}
                                  required
                                  // You can control the error state with this condition
                                  slotProps={{
                                    textField: {
                                      error:
                                        !!endDate && !dayjs(endDate).isValid(),
                                      sx: {
                                        width: "160px", // Adjust width
                                        "& .MuiInputBase-root": {
                                          fontSize: "11px", // Adjust font size
                                          padding: "-4px", // Adjust padding
                                        },
                                        "& .MuiInputLabel-root": {
                                          fontSize: "14px", // Adjust label size
                                        },
                                      },
                                    },
                                  }}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </div>
                        </>
                      )}

                      <div style={{ position: "relative", marginLeft: "auto" }}>
                        {permissions.some(
                          (item) =>
                            item.keyword === "orders" && item.isSearch === true
                        ) && (
                          <>
                            <input
                              type="text"
                              placeholder={STRING_CONSTANTS.SEARCH_PLACEHOLDER}
                              className="form-control"
                              style={{
                                width: "200px",
                                paddingLeft: "35px",
                                boxSizing: "border-box",
                                marginLeft: "auto",
                              }}
                              onChange={(e) => setSearchValue(e.target.value)}
                            />
                            <i
                              className="fas fa-search"
                              style={{
                                position: "absolute",
                                left: "10px",
                                top: "50%",
                                transform: "translateY(-50%)",
                                color: "#aaa",
                              }}
                            ></i>
                          </>
                        )}
                      </div>
                    </div>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>{STRING_CONSTANTS.SN_NO}</TableCell>
                            <TableCell>{STRING_CONSTANTS.ORDER_NO}</TableCell>
                            <TableCell>{STRING_CONSTANTS.PAYMENT_ID}</TableCell>
                            <TableCell>{STRING_CONSTANTS.BUYER_NAME}</TableCell>
                            <TableCell>
                              {STRING_CONSTANTS.SELLER_NAME}
                            </TableCell>
                            <TableCell>{STRING_CONSTANTS.ORDER_DATE}</TableCell>
                            <TableCell>{STRING_CONSTANTS.PRICE}</TableCell>
                            <TableCell>{STRING_CONSTANTS.P_TYPE}</TableCell>
                            <TableCell>{STRING_CONSTANTS.STATUS}</TableCell>
                            <TableCell>{STRING_CONSTANTS.ACTIONS}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {loading?(
                            <TableRow>
                            <TableCell colSpan={10} style={{ textAlign: 'center' }}>
                              <CircularProgress/>
                               
                            </TableCell>
                          </TableRow>
                          ) : orders.length === 0 ? (
                            <TableRow>
                              <TableCell colSpan={10} style={{ textAlign: 'center' }}>
                                No data found
                              </TableCell>
                            </TableRow>
                          ):(
                            
                          
                          orders
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row, index) => (
                              <React.Fragment key={index}>
                                <TableRow>
                                  <TableCell>
                                    {page * rowsPerPage + index + 1}
                                  </TableCell>
                                  <TableCell>
                                    {row.order_no}
                                    {row.details.length > 1 && (
                                      <IconButton
                                        onClick={() =>
                                          handleRowClick(row.order_id)
                                        }
                                      >
                                        {expandedRows[row.order_id] ? (
                                          <ExpandLessIcon />
                                        ) : (
                                          <ExpandMoreIcon />
                                        )}
                                      </IconButton>
                                    )}
                                  </TableCell>
                                  <TableCell>{row.payment_id}</TableCell>
                                  <TableCell>{row.buyerName}</TableCell>
                                  <TableCell>
                                    {row.details[0].sellerName}
                                  </TableCell>
                                  <TableCell>
                                    {formatDate(row.orderDate)}
                                  </TableCell>
                                  <TableCell>{row.price}</TableCell>
                                  <TableCell>{row.type}</TableCell>
                                  <TableCell>
                                    <span className="btn-sm btn-success">
                                      {getOrderStatus(row.status)}
                                    </span>
                                  </TableCell>
                                  <TableCell>
                                    {edit(null, row, null, index)}
                                  </TableCell>
                                </TableRow>
                                {expandedRows[row.order_id] && (
                                  <TableRow>
                                    <TableCell colSpan={8}>
                                      <Table>
                                        <TableHead>
                                          <TableRow>
                                            <TableCell>
                                              {STRING_CONSTANTS.PRODUCT_ID}
                                            </TableCell>

                                            <TableCell>
                                              {STRING_CONSTANTS.BUYER_NAME}
                                            </TableCell>
                                            <TableCell>
                                              {STRING_CONSTANTS.SELLER_NAME}
                                            </TableCell>
                                            <TableCell>
                                              {STRING_CONSTANTS.ORDER_DATE}
                                            </TableCell>
                                            <TableCell>
                                              {STRING_CONSTANTS.PRICE}
                                            </TableCell>
                                            <TableCell>
                                              {STRING_CONSTANTS.STATUS}
                                            </TableCell>
                                            <TableCell>
                                              {STRING_CONSTANTS.ACTIONS}
                                            </TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {row.details.map(
                                            (detail, detailIndex) => (
                                              <TableRow key={detailIndex}>
                                                <TableCell>
                                                  {detail.product_id}
                                                </TableCell>

                                                <TableCell>
                                                  {detail.buyerName}
                                                </TableCell>

                                                <TableCell>
                                                  {detail.sellerName}
                                                </TableCell>
                                                <TableCell>
                                                  {formatDate(detail.orderDate)}
                                                </TableCell>
                                                <TableCell>
                                                  {detail.price}
                                                </TableCell>
                                                <TableCell>
                                                  <span className="btn-sm btn-success">
                                                    {getOrderStatus(
                                                      detail.status
                                                    )}
                                                  </span>
                                                </TableCell>
                                                <TableCell>
                                                  {edit(
                                                    null,
                                                    detail,
                                                    null,
                                                    index
                                                  )}
                                                </TableCell>
                                              </TableRow>
                                            )
                                          )}
                                        </TableBody>
                                      </Table>
                                    </TableCell>
                                  </TableRow>
                                )}
                              </React.Fragment>
                            )))}
                          <ImageModal
                            handleClose={handleClose}
                            open={open}
                            imageSrc={images}
                          />
                          <Dialog
                            open={open1}
                            onClose={handleClose1}
                            aria-labelledby="details-modal-title"
                            aria-describedby="details-modal-description"
                            style={{
                              maxHeight: "80vh",
                              overflowY: "auto",
                              marginTop: "100px",
                            }}
                            fullWidth
                            disableEnforceFocus
                            maxWidth="lg"
                          >
                            <Grid
                              container
                              justifyContent="center"
                              alignItems="center"
                              className="modal-content"
                            >
                              <Grid item xs={12} className="modal-header">
                                <h5 id="details-modal-title">
                                  {STRING_CONSTANTS.DETAILS}
                                </h5>
                                <Button onClick={handleClose1} color="inherit">
                                  <span
                                    style={{ fontSize: "20px" }}
                                    aria-hidden="true"
                                  >
                                    &#128473;
                                  </span>
                                </Button>
                              </Grid>
                              <Grid item xs={12} className="modal-body">
                                <Table className="col-12">
                                 
                                <TableRow>
                                    <TableCell className="text-left">
                                      {STRING_CONSTANTS.PAYMENT_ID}
                                    </TableCell>
                                    <TableCell className="text-dark text-right font-weight-normal">
                                      {details.payment_id}
                                    </TableCell>
                                  </TableRow>

                                  <TableRow>
                                    <TableCell className="text-left">
                                      {STRING_CONSTANTS.SELLER_INVOICE}
                                    </TableCell>
                                    <TableCell className="text-dark text-right font-weight-normal">
                                      {details.sellerInvoice}
                                    </TableCell>
                                  </TableRow>
                             
                                <TableRow>
                                    <TableCell className="text-left">
                                      {STRING_CONSTANTS.BUYER_MOB}
                                    </TableCell>
                                    <TableCell className="text-dark text-right font-weight-normal">
                                      {details.buyerMobile}
                                    </TableCell>
                                  </TableRow>
                                 

                                 


                                  <TableRow>
                                    <TableCell className="text-left">
                                      {STRING_CONSTANTS.BUYER_MOB}
                                    </TableCell>
                                    <TableCell className="text-dark text-right font-weight-normal">
                                      {details.buyerMobile}
                                    </TableCell>
                                  </TableRow>
                                
                                 
                                
                                  <TableRow>
                                    <TableCell className="text-left">
                                      {STRING_CONSTANTS.PRICE}
                                    </TableCell>
                                    <TableCell className="text-dark text-right font-weight-normal">
                                      {details.price}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell className="text-left">
                                      {STRING_CONSTANTS.QUANTITY}
                                    </TableCell>
                                    <TableCell className="text-dark text-right font-weight-normal">
                                      {details?.details?.length &&details.details.length}
                                    </TableCell>
                                  </TableRow>
                                 
                                  <TableRow>
                                    <TableCell className="text-left">
                                      {STRING_CONSTANTS.LOCATION}
                                    </TableCell>
                                    <TableCell className="text-dark text-right font-weight-normal">
                                      {details.location}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell className="text-left">
                                      {STRING_CONSTANTS.STATUS}
                                    </TableCell>
                                    <TableCell className="text-dark text-right font-weight-normal">
                                      <span className="btn-sm btn-success">
                                        {getOrderStatus(details.status)}
                                      </span>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell className="text-left">
                                      {STRING_CONSTANTS.FREE_DELEVERY}
                                    </TableCell>
                                    <TableCell className="text-dark text-right font-weight-normal">
                                      {details.freeDelivery
                                        ? details.freeDelivery
                                        : STRING_CONSTANTS.ZERO}
                                      {STRING_CONSTANTS.KM}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell className="text-left">
                                      {STRING_CONSTANTS.CHARGES_AFTER_FREE}
                                    </TableCell>
                                    <TableCell className="text-dark text-right font-weight-normal">
                                      {STRING_CONSTANTS.RUPEE}
                                      {details.chargesAfter
                                        ? details.chargesAfter
                                        : STRING_CONSTANTS.ZERO}
                                    </TableCell>
                                  </TableRow>
                                </Table>
                              </Grid>
                              <Grid item xs={12} className="modal-footer">
                                <Button
                                  onClick={handleClose1}
                                  variant="outlined"
                                  color="error"
                                >
                                  {STRING_CONSTANTS.CLOSE}
                                </Button>
                              </Grid>
                            </Grid>
                          </Dialog>
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[10, 50, 100]}
                      component="div"
                      count={orders.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Orders;
