import { useEffect } from "react";
import Topbar from "./topBar";
import Sidebar from "./sideBar";
import Content from "./auditorContent";

const Dashboard = () => {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div id="wrapper">
      <Topbar backgroundColor="#769A0F" />
      <Sidebar />
      <Content />
    </div>
  );
};

export default Dashboard;
