import React, { useState, useEffect } from "react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { privateApiCall } from "../api/privateApi";
import { FaRegCalendar } from "react-icons/fa";
import { toast, Bounce } from "react-toastify";
import {
  ALERT_BUTTON_OK,
  ALERT_ICON_ERR,
  ERR_ALERT_TITLE,
  ERR_GETTING,
} from "../constants/alertMessage";
import { useNavigate } from "react-router-dom";
import { STRING_CONSTANTS } from "../constants/stringConstants";

const Dashboard = () => {
  const navigate = useNavigate();

  const [counts, setCounts] = useState({
    invoiceCount: 0,
    paymentCount: 0,
    orderCount: 0,
  });

  useEffect(() => {
    const fetchAuditorDashboard = async () => {
      try {
        const requestUrl = "/app/getAuditorDashboard";
        const data = await privateApiCall(requestUrl, "GET");
console.log(data.data.data);

        setCounts({
          invoiceCount: data.data.data.invCount,
          paymentCount: data.data.data.paymentCount,
          orderCount: data.data.data.orderCount,
        });
        
      } catch (err) {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `${ALERT_ICON_ERR}`,
          text: `${ERR_GETTING}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      }
    };

    fetchAuditorDashboard();
  }, []);

  return (
    <div id="wrapper">
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        {STRING_CONSTANTS.PAGES}
                      </li>
                      <li className="breadcrumb-item active">
                        {STRING_CONSTANTS.AUDITOR_DASHBOARD}
                      </li>
                    </ol>
                  </div>
                  <h4 className="page-title">
                    {STRING_CONSTANTS.AUDITOR_DASHBOARD}
                  </h4>

                  <div className="clearfix"></div>
                </div>
              </div>
            </div>

            <div className="row ">
              <div className="col-xs-12 col-md-6 col-lg-4 col-xl-4">
                <Link to="/invoices">
                  <div
                    className="card-box tilebox-one"
                    style={{ height: "250px", color: "black" }}
                  >
                    <i
                      className="fe-file-text float-right"
                      style={{ backgroundColor: "#769A0F" }}
                    ></i>

                    <h5 className="text-muted font-14 text-uppercase mb-3">
                      {STRING_CONSTANTS.INVOICES}
                    </h5>
                    <h4
                        className="ml-1"
                        data-tooltip-id="4"
                        data-tooltip-content={`Inovices`}
                        style={{
                          fontSize: "50px",
                          fontWeight: "bold",
                          color: "#769A0F",
                          marginTop: "25px",
                        }}
                        data-plugin="counterup"
                      >
                        {counts.invoiceCount}{" "}
                        <Tooltip id="4" style={{ fontSize: "12px" }} />
                      </h4>
                  </div>
                </Link>
              </div>
              <div className="col-xs-12 col-md-6 col-lg-4 col-xl-4">
                <Link to="/payment">
                  <div
                    className="card-box tilebox-one"
                    style={{ height: "250px", color: "black" }}
                  >
                    <i
                      className="fe-dollar-sign float-right"
                      style={{ backgroundColor: "#769A0F" }}
                    ></i>

                    <h5 className="text-muted font-14 text-uppercase mb-3">
                      {STRING_CONSTANTS.PAYMENTS}
                    </h5>

                    <div className="row mb-5">
                      <h4
                        className="ml-1"
                        data-tooltip-id="4"
                        data-tooltip-content={`Payments`}
                        style={{
                          fontSize: "50px",
                          fontWeight: "bold",
                          color: "#769A0F",
                          marginTop: "25px",
                        }}
                        data-plugin="counterup"
                      >
                        {counts.paymentCount}{" "}
                        <Tooltip id="4" style={{ fontSize: "12px" }} />
                      </h4>
                      <span>
                        <div
                          className="ml-2"
                          id="customers"
                          data-tip="Customer"
                        ></div>
                      </span>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-xs-12 col-md-6 col-lg-4 col-xl-4">
                <Link to="/orders" target="_blank">
                  <div
                    className="card-box tilebox-one"
                    style={{ height: "250px", color: "black" }}
                  >
                    <i
                      className="fas fa-shopping-cart float-right"
                      style={{ backgroundColor: "#769A0F" }}
                    ></i>

                    <h5 className="text-muted font-14 text-uppercase mb-3">
                      {STRING_CONSTANTS.ORDERS}
                    </h5>

                    <div className="row mb-5">
                      <h4
                        className="ml-1"
                        data-tooltip-id="5"
                        data-tooltip-content={`Orders`}
                        style={{
                          fontSize: "50px",
                          fontWeight: "bold",
                          color: "#769A0F",
                          marginTop: "25px",
                        }}
                        data-plugin="counterup"
                      >
                        {counts.orderCount}{" "}
                        <Tooltip id="5" style={{ fontSize: "12px" }} />
                      </h4>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
