import React, { Component } from "react";
import { Link } from "react-router-dom";
import Topbar from "../topBar";
import Sidebar from "../sideBar";
import { formatDate } from "../../utils/dateUtils";
import {
  Table,
  TableBody,
  TableCell,
  Modal,
  Box,
  IconButton,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  TableRow,
  Paper,
  Button,
  Grid,
  Dialog,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FiMessageCircle, FiEye } from "react-icons/fi";
import { FaImage } from "react-icons/fa";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Tooltip } from "react-tooltip";
import Cookies from "universal-cookie";
import {
  BUY_PRODUCT,
  DASHBOARD,
  FEED,
  VIEW_DET,
  VIEW_IMG,
} from "../../constants/url";
import ImageModal from "../../images/imageModal";
import {
  ALERT_BUTTON_OK,
  ALERT_ICON_ERR,
  NO_DATA_FOUND,
  NO_EXP,
  ALERT_ICON_SUCC,
  ERR_ALERT_TITLE,
  ERR_GETTING,
  ERR_POSTING,
  SUCC_ALERT_TITLE,
  SUCC_FEEDBACK,
} from "../../constants/alertMessage";
import io from "socket.io-client";
import { privateApiCall } from "../../api/privateApi";
import { STRING_CONSTANTS } from "../../constants/stringConstants";
import { API } from "../../config";
const cookies = new Cookies();
class expList extends Component {
  constructor() {
    const localRole = JSON.parse(localStorage.getItem("role"));
    super();
    this.state = {
      data: "",
      userId: cookies.get("agritech_id"),
      replay: "",
      page: 0,
      rowsPerPage: 10,
      openn: false,
      images: [],
      message: "",
      messages: [],
      open1: false,
      open2: false,
      open: false,
      feedId: "",
      customer: "",
      mobile_no: "",
      title: "",
      description: "",
      qty: "",
      unit: "",
      endDate: "",
      
      startDate: "",
      permissions:
        localRole && localRole.permissions ? localRole.permissions : "All",
    };
    this.socket = React.createRef(); 

    this.edit = this.edit.bind(this);
    this.handleSend = this.handleSend.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleRowPageChange = this.handleRowPageChange.bind(this);
  }
  componentDidMount() {
    this.socket.current = io(API);
    this.socket.current.emit("new-user-add", this.state.userId);  
    this.socket.current.on("receiver-msg", (message) => {
 
      this.setState((prevState) => ({
        messages: [...prevState.messages, message],
      }));
    });
    const requestUrl = `${BUY_PRODUCT}/expiredbuylist/`;
    privateApiCall(requestUrl, "GET")
      .then(
        (res) => {
          const data = res.data.data;

         if(data.length===0){
          Swal.fire({
            title:`${NO_DATA_FOUND}`,
            icon: "info",
            text: `${NO_EXP}`,
            allowOutsideClick: false,
            confirmButtonText: `${ALERT_BUTTON_OK}`,
          });

         }
         else
         {
           // Renumber the items
           for (let i = 0; i < data.length; i++) {
            data[i].sno = i + 1;;
          }

          this.setState({ data});
         }
        },
        (err) => {
          
        }
      )
      .catch((err) => {
         
      });
  }
  handlePageChange(e, newPage) {
    this.setState({ page: newPage });
  }
   
  handleRowPageChange(event) {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  }

  addSerialNumbers(data) {
    return data.map((item, index) => ({
      ...item,
      sno: index + 1,
    }));
  }

  handleSearch(e) {
    const searchValue = e.target.value;
    const requestUrl = `${BUY_PRODUCT}/search_buy_expired_list?query=${searchValue}&`;

    privateApiCall(requestUrl, "GET")
      .then((response) => {
        if (response.data && response.data.data) {
          const dataWithSerialNumbers = this.addSerialNumbers(
            response.data.data
          );
          this.setState({ data: dataWithSerialNumbers });
          this.setState({page:0})
        } else {
          this.setState({ data: [] });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `${ALERT_ICON_ERR}`,
          text: `${ERR_GETTING}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      });
  }

  async handleSend() {
    const { chatId, message, senderId, productId } = this.state;
     
    const newMessage = {
      ChatId: chatId,
      text: message,
      receiverId:this.state.receiverId,
      senderId: senderId,
      productId: productId,
    };
    console.log(newMessage,"receiverId data")
    if (this.socket) {
      this.socket.current.emit("send-message", newMessage);
    } else {
      console.error("Socket is not initialized.");
    }
    try {
      const requestUrl = "/api/buyproduct/admsg";
      await privateApiCall(requestUrl, "POST", newMessage);
      this.setState((prevState) => ({
        messages: [...prevState.messages, newMessage],
        message: "",
      }));
    } catch (error) {
      console.error("Error sending message:", error);
    }
  }

  handleClose = () => {
    this.setState({ open: false });
  };
  handleClose1 = () => {
    this.setState({ open1: false });
  };
  handleClose2 = () => {
    this.setState({ open2: false });
  };
  handleReplay = (event) => {
    event.preventDefault();

    const requestUrl = `${BUY_PRODUCT}/replayToBProduct/?&_id=${this.state.feedId}&replay=${this.state.replay}`;
    privateApiCall(requestUrl, "GET")
      .then((res) => {
        if (res.data.status) {
          const requestUrl = `${BUY_PRODUCT}/expiredbuylist/`;
          privateApiCall(requestUrl, "GET")
            .then(
              (res) => {
                const k = 1;
                for (let i = 0; i < res.data.data.length; i++) {
                  res.data.data[i].sno = k + i;
                }
                this.setState({ open2: false });
                this.setState({ replay: "" });
                this.setState({ data: res.data.data });

                Swal.fire({
                  title: `${SUCC_ALERT_TITLE}`,
                  text: `${SUCC_FEEDBACK}`,
                  icon: `${ALERT_ICON_SUCC}`,
                  allowOutsideClick: false,
                  showCancelButton: false,
                  confirmButtonText: `${ALERT_BUTTON_OK}`,
                });
              },
              (err) => {
                Swal.fire({
                  title: `${ERR_ALERT_TITLE}`,
                  icon: `${ALERT_ICON_ERR}`,
                  text: `${ERR_GETTING}`,
                  allowOutsideClick: false,
                  showCancelButton: false,
                  confirmButtonText: `${ALERT_BUTTON_OK}`,
                });
              }
            )
            .catch((err) => {
              Swal.fire({
                title: `${ERR_ALERT_TITLE}`,
                icon: `${ALERT_ICON_ERR}`,
                text: `${ERR_POSTING}`,
                allowOutsideClick: false,
                showCancelButton: false,
                confirmButtonText: `${ALERT_BUTTON_OK}`,
              });
            });
        } else {
          Swal.fire({
            title: `${ERR_ALERT_TITLE}`,
            icon: `${ALERT_ICON_ERR}`,
            text: `${ERR_POSTING}`,
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: `${ALERT_BUTTON_OK}`,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `${ALERT_ICON_ERR}`,
          text: `${ERR_POSTING}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      });
  };
  handleClosc = () => {
    this.setState({ openn: false });
  };

  handleChange(event) {
    this.setState({
      replay: event.target.value,
    });
  }
  edit(cell, row, ex, index) {
    const handleImageClick = () => {
      if (row.image1) {
        this.setState({ images: [row.image1], open: true });
      }
    };
    const isOnlyImage1Present =
      row.image1 !== undefined && row.image1 !== null && row.image1 !== "";

    const handleS = (e) => {
      // Perform the state updates only if confirmed
      this.setState({ feedId: row.id });
      this.setState({ open2: true });
    };

    const handlePOP = (e) => {
      const {
        customer,
        mobile_no,
        name,
        expDate,
        description,
        title,
        qty,
        unit,
        startDate,
        endDate,
        location,
        safe_status,
        organic_status,
        transpot_status,
      } = row;
      this.setState({
        customer: customer,
        mobile_no: mobile_no,
        name: name,
        expDate: expDate,
        description: description,
        title: title,
        qty: qty,
        unit: unit,
        location: location,
        startDate: startDate,
        endDate: endDate,
        safe_status,
        organic_status,
        transpot_status,
      });
      this.setState({ open1: true });
    };
    const createChat = async (e) => {
      console.log("createchat clicked");
      const { userId } = this.state;
      console.log(userId);
      const payload = {
        senderId: userId,
        receiverName: row.customer,
        productId: row.id,
      };
      const requestUrl = "/api/buyproduct/createChat";
      const response = await privateApiCall(requestUrl, "POST", payload);
      console.log(response);
      if (response && response.data && response.data.chat) {
        this.setState({ openn: true });
        this.setState({
          chatId: response.data.chat._id,
          receiverId: response.data.chat.receiverId,
          senderId: response.data.chat.senderId,
          productId: response.data.chat.productId,
          messages:response.data.messages
        });
      }
    };
    return (
      <span>
        {this.state.permissions.some(
          (item) =>
            item.keyword === "buyProductExpiredList" && item.isFeedback === true
        ) && (
          <>
            {row.replay !== "" ? (
              <i
                className="ml-1"
                data-tooltip-id="2"
                data-tooltip-content={`${FEED}`}
              >
                {" "}
                <FiMessageCircle onClick={handleS} color="#769A0F" size="20" />
              </i>
            ) : (
              <i
                className="ml-1"
                data-tooltip-id="2"
                data-tooltip-content={`${FEED}`}
              >
                {" "}
                <FiMessageCircle onClick={handleS} color="#f9bc0b" size="20" />
              </i>
            )}
            <Tooltip id="2" />
          </>
        )}
        {this.state.permissions.some(
          (item) =>
            item.keyword === "buyProductExpiredList" &&
            item.isViewDetails === true
        ) && (
          <>
            <i
              className="ml-1"
              data-tooltip-id="3"
              data-tooltip-content={`${VIEW_DET}`}
            >
              {" "}
              <FiEye onClick={handlePOP} color="#9368f3" size="20" />
            </i>
            <Tooltip id="3" />
          </>
        )}

        {this.state.permissions.some(
          (item) =>
            item.keyword === "buyProductExpiredList" &&
            item.isShowImage === true
        ) && (
          <>
            {isOnlyImage1Present && (
              <i
                className="ml-1"
                data-tooltip-id="4"
                data-tooltip-content={`${VIEW_IMG}`}
              >
                {" "}
                <FaImage onClick={handleImageClick} color="green" size="17" />
              </i>
            )}
            <Tooltip id="4" />
          </>
        )}
        <button onClick={createChat}>chat</button>
      </span>
    );
  }

  render() {
    return (
      <div id="wrapper">
        <Topbar backgroundColor="#769A0F" />
        <Sidebar type="buyProducts" />
        <div className="content-page">
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box">
                    <h4 className="page-title float-left">
                      {STRING_CONSTANTS.BP_EXPIRED_LIST} ({this.state.data.length})
                    </h4>

                    <ol className="breadcrumb float-right">
                      <li className="breadcrumb-item">
                        {" "}
                        <Link to="/dashboard">{STRING_CONSTANTS.DASHBOARD}</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        {STRING_CONSTANTS.BUY_PRODUCTS}
                      </li>
                      <li className="breadcrumb-item active">
                        {STRING_CONSTANTS.EXPIRED_LIST}
                      </li>
                    </ol>

                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card-box">
                    <div style={{ width: "98%" }}>
                      <div
                        style={{
                          marginLeft: "auto",
                          width: "200px",
                          marginBottom: "20px",
                          position: "relative",
                        }}
                      >
                        {this.state.permissions.some(
                          (item) =>
                            item.keyword === "buyProductExpiredList" &&
                            item.isSearch === true
                        ) && (
                          <>
                            <input
                              type="text"
                              placeholder="Search..."
                              className="form-control"
                              style={{
                                width: "100%",
                                paddingLeft: "30px",
                                boxSizing: "border-box",
                              }}
                              onChange={(e) => {
                                this.handleSearch(e);
                                if (e.target.value) {
                                  e.target.nextSibling.style.display = "none";
                                } else {
                                  e.target.nextSibling.style.display = "block";
                                }
                              }}
                            />
                            <i
                              className="fas fa-search"
                              style={{
                                position: "absolute",
                                left: "10px",
                                top: "50%",
                                transform: "translateY(-50%)",
                                color: "#aaa",
                                pointerEvents: "none",
                              }}
                            ></i>
                          </>
                        )}
                      </div>

                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell align="left">
                                {STRING_CONSTANTS.SN_NO}
                              </TableCell>
                              <TableCell align="left">
                                {STRING_CONSTANTS.CUSTOMER}
                              </TableCell>
                              <TableCell align="left">
                                {STRING_CONSTANTS.MOBILE}
                              </TableCell>
                              <TableCell align="left">
                                {STRING_CONSTANTS.TITLE}
                              </TableCell>
                              <TableCell align="left">
                                {STRING_CONSTANTS.ACTIONS}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {Array.isArray(this.state.data) &&
                              this.state.data.slice(
                                this.state.page * this.state.rowsPerPage,
                                this.state.page * this.state.rowsPerPage + this.state.rowsPerPage
                              ).map((row, index) => (
                                <TableRow key={index}>
                                  <TableCell align="left">{row.sno}</TableCell>
                                  <TableCell align="left">
                                    {row.customer}
                                  </TableCell>
                                  <TableCell align="left">
                                    {row.mobile_no}
                                  </TableCell>
                                  <TableCell align="left">
                                    {row.title}
                                  </TableCell>

                                  <TableCell align="left">
                                    {this.edit(null, row, null, index)}
                                  </TableCell>
                                </TableRow>
                              ))}
                            <ImageModal
                              open={this.state.open}
                              handleClose={this.handleClose}
                              imageSrc={this.state.images}
                            />
                            <Dialog
                              open={this.state.open1}
                              onClose={this.handleClose1}
                              aria-labelledby="details-modal-title"
                              aria-describedby="details-modal-description"
                              style={{
                                maxHeight: "80vh",
                                overflowY: "auto",
                                marginTop: "100px",
                              }}
                              fullWidth
                              disableEnforceFocus
                              maxWidth="lg"
                            >
                              <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                className="modal-content"
                              >
                                <Grid item xs={12} className="modal-header">
                                  <h5 id="details-modal-title">
                                    {" "}
                                    {STRING_CONSTANTS.PRODUCT_DETAILS}
                                  </h5>
                                  <Button
                                    onClick={this.handleClose1}
                                    color="inherit"
                                  >
                                    {" "}
                                    <span
                                      style={{ fontSize: "20px" }}
                                      aria-hidden="true"
                                    >
                                      &#128473;
                                    </span>
                                  </Button>
                                </Grid>
                                <Grid item xs={12} className="modal-body">
                                  <Table className="col-12">
                                    <TableRow>
                                      <TableCell className="text-left">
                                        {STRING_CONSTANTS.CUSTOMER_NAME}
                                      </TableCell>
                                      <TableCell className="text-dark text-right font-weight-normal">
                                        {this.state.customer}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell className="text-left">
                                        {STRING_CONSTANTS.CUSTOMER_MOBILE}
                                      </TableCell>
                                      <TableCell className="text-dark text-right font-weight-normal">
                                        {this.state.mobile_no}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell className="text-left">
                                        {STRING_CONSTANTS.PRODUCT_TITLE}
                                      </TableCell>
                                      <TableCell className="text-dark text-right font-weight-normal">
                                        {this.state.title}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell className="text-left">
                                        {STRING_CONSTANTS.DESCRIPTION}
                                      </TableCell>
                                      <TableCell className="text-dark text-right font-weight-normal">
                                        {this.state.description}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell className="text-left">
                                        {STRING_CONSTANTS.QUANTITY}
                                      </TableCell>
                                      <TableCell className="text-dark text-right font-weight-normal">
                                        {this.state.qty}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell className="text-left">
                                        {STRING_CONSTANTS.UNIT}
                                      </TableCell>
                                      <TableCell className="text-dark text-right font-weight-normal">
                                        {this.state.unit}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell className="text-left">
                                        {STRING_CONSTANTS.LOCATION}
                                      </TableCell>
                                      <TableCell className="text-dark text-right font-weight-normal">
                                        {this.state.location}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell className="text-left">
                                        {STRING_CONSTANTS.START_DATE}
                                      </TableCell>
                                      <TableCell className="text-dark text-right font-weight-normal">
                                        {formatDate(this.state.startDate)}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell className="text-left">
                                        {STRING_CONSTANTS.END_DATE}
                                      </TableCell>
                                      <TableCell className="text-dark text-right font-weight-normal">
                                        {formatDate(this.state.endDate)}
                                      </TableCell>
                                    </TableRow>
                                  </Table>
                                </Grid>
                                <Grid item xs={12} className="modal-footer">
                                  <Button
                                    onClick={this.handleClose1}
                                    variant="outlined"
                                    color="error"
                                  >
                                    {STRING_CONSTANTS.CLOSE}
                                  </Button>
                                </Grid>
                              </Grid>
                            </Dialog>
                            <Dialog
                              open={this.state.open2}
                              onClose={this.handleClose2}
                              maxWidth="lg"
                              fullWidth
                              centered
                              disableEnforceFocus
                            >
                              <div
                                style={{
                                  backgroundColor: "#fff",
                                  padding: "20px",
                                  // minWidth: '500px',
                                  borderRadius: "8px",
                                }}
                              >
                                <h3>{STRING_CONSTANTS.FEEDBACK_LABEL}</h3>
                                <form onSubmit={this.handleReplay}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                      <div className="text-left text-dark mb-1">
                                        {STRING_CONSTANTS.MESSAGE}
                                      </div>
                                      <TextField
                                        type="text"
                                        fullWidth
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                        value={this.state.replay}
                                        placeholder={STRING_CONSTANTS.MESSAGE}
                                        onChange={this.handleChange}
                                        required
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    container
                                    justifyContent="flex-end"
                                    style={{ marginTop: "20px" }}
                                  >
                                    <Button
                                      variant="outlined"
                                      color="error"
                                      onClick={this.handleClose2}
                                      style={{ marginRight: "8px" }}
                                    >
                                      {STRING_CONSTANTS.CLOSE}
                                    </Button>
                                    <Button
                                      type="submit"
                                      variant="contained"
                                      style={{
                                        backgroundColor: "#769A0F",
                                        color: "#fff",
                                      }}
                                    >
                                      {STRING_CONSTANTS.SEND}
                                    </Button>
                                  </Grid>
                                </form>
                              </div>
                            </Dialog>
                            <Modal
                              open={this.state.openn}
                              onClose={this.handleClosc}
                              aria-labelledby="chat-modal-title"
                              aria-describedby="chat-modal-description"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Box
                                sx={{
                                  width: 500,
                                  bgcolor: "background.paper",
                                  p: 2,
                                  borderRadius: "8px",
                                  boxShadow: 24,
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <Typography variant="h6">
                                    Chat with{" "}
                                  </Typography>
                                  <IconButton onClick={this.handleClosc}>
                                    <CloseIcon />
                                  </IconButton>
                                </Box>

                                {/* Chat History */}
                                <Box
                                  sx={{
                                    height: 300,
                                    overflowY: "auto",
                                    p: 2,
                                    bgcolor: "#f1f1f1",
                                    borderRadius: "8px",
                                    marginBottom: 2,
                                  }}
                                >
                                  {this.state.messages.length > 0 ? (
                                    this.state.messages.map((msg, index) => (
                                      <Box
                                        key={index}
                                        mb={1}
                                        sx={{
                                          display: "flex",
                                          justifyContent: msg.senderId ===  this.state.userId ? "flex-end" : "flex-start",
                                        }}
                                      >
                                        <Typography
                                          variant="body1"
                                          sx={{
                                            bgcolor: msg.senderId ===  this.state.userId ? "#d1ffd6" : "#f0f0f0",
                                            borderRadius: "8px",
                                            p: 1,
                                            maxWidth: "70%",
                                          }}
                                        >
                                          {msg.text}
                                        </Typography>
                                      </Box>
                                    ))
                                  ) : (
                                    <Typography variant="body2">
                                      No messages yet
                                    </Typography>
                                  )}
                                </Box>

                                {/* Message Input */}
                                <TextField
                                  variant="outlined"
                                  multiline
                                  rows={2}
                                  placeholder="Type your message..."
                                  value={this.state.message}
                                  onChange={(e) =>
                                    this.setState({ message: e.target.value })
                                  }
                                  fullWidth
                                />

                                <Box
                                  display="flex"
                                  justifyContent="flex-end"
                                  mt={2}
                                >
                                  <Button
                                    onClick={this.handleSend}
                                    variant="contained"
                                  >
                                    Send
                                  </Button>
                                </Box>
                              </Box>
                            </Modal>
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[10, 50, 100]}
                        component="div"
                        count={this.state.data.length}
                        rowsPerPage={this.state.rowsPerPage||0}
                        page={this.state.page||0}
                        onPageChange={this.handlePageChange}
                        onRowsPerPageChange={this.handleRowPageChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default expList;
