import React, { useState, useEffect } from "react";
import Topbar from "./topBar";
import Sidebar from "./sideBar";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  TableRow,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Grid,
} from "@mui/material";
import Cookies from "universal-cookie";
import { Link } from "react-router-dom";
import { deleteSurvey } from "../actions/adminAction";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {
  FiEye,
  FiTrash2,
  FiMapPin,
  FiArrowUp,
  FiArrowDown,
} from "react-icons/fi";

import { FaImage } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import {
  SURVEY,
  VIEW_DET,
  VIEW_IMG,
  DELETE,
  TREE_LOC,
  SURVEY_SEARCH,
} from "../constants/url";
import { SURVEY_QUESTIONS } from "../constants/surveyQuestionnaire";
import {
  ERROR,
  ERR,
  ERR_ALERT_TITLE,
  CONFIRM_TITLE,
  CONFIRM_TXT_DEL,
  ALERT_BUTTON_OK,
  DELETE_YES_BUTTON,
} from "../constants/alertMessage";
import { privateApiCall } from "../api/privateApi";
import ImageModal from "../images/imageModal";
import { STRING_CONSTANTS } from "../constants/stringConstants";

const cookies = new Cookies();

const Survey = () => {
  const localRole = JSON.parse(localStorage.getItem("role"));
  const permissions =
    localRole && localRole.permissions ? localRole.permissions : "All";
  const [values, setValues] = useState({
    data: "",
    orders: [],
    questions: false,
    qHeader: "View Questions",
    user: "All",
    users: ["All", "Farmer", "Organisation"],
    removed: false,
  });
  const [page,setPage]=useState(0)
  const [rowsPerPage,setRowsPerPage]=useState(10)
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const { orders, questions, user, users } = values;
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [survey, setSurvey] = useState({});
  const [showDetails, setShowDetails] = useState(false);
  const [count, setCount] = useState(0);


  useEffect(() => {
    loadSurvey();
  }, []);

  const loadSurvey = () => {
    // Get the JWT token from cookies

    const requestUrl = `${SURVEY}/?token=${cookies.get(
      "agritech_token"
    )}&type=All`;
    privateApiCall(requestUrl, "GET")
      .then((res) => {
        setValues((prevState) => ({
          ...prevState,
          orders: res.data.data,
        }));
        setCount(res.data.data.length);

      })
      .catch((err) => {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          text: `${err}`,
          icon: "error",
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      });
  };

  const handleSearch = async (e) => {
    const searchValue = e.target.value;

    try {
      const requestUrl = `${SURVEY_SEARCH}?query=${searchValue}&type=${type}`;

      const response = await privateApiCall(requestUrl, "GET");

      if (response.data && response.data.data) {
        setValues((prevState) => ({
          ...prevState,
          orders: response.data.data,
        }));
      } else {
        setValues((prevState) => ({
          ...prevState,
          orders: [],
        }));
      }
    } catch (error) {
      Swal.fire({
        title: `${ERROR}`,
        text: `${error}`,
        icon: `${ERR}`,
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonText: `${ALERT_BUTTON_OK}`,
      });
    }
  };

  const handleImageClick = (survey) => {
    const imageData = [
      survey.image1,
      survey.image2,
      survey.image3,
      survey.image4,
    ].filter((image) => image !== "" && image !== null);

    if (imageData.length > 0) {
      setImages(imageData);
      setOpen(true);
    } else {
      console.error("No image data available for this survey.");
    }
  };

  const handleClose1 = () => {
    setOpen(false);
  };
  const [type, setType] = useState('');
  const handleChange = (e) => {
    console.log(e.target.value,"etarget value")
    const selectedType = e.target.value;
  setType(selectedType); 
    e.persist();
    const requestUrl = `${SURVEY}/?token=${cookies.get(
      "agritech_token"
    )}&type=${selectedType}`;
    privateApiCall(requestUrl, "GET")
      .then((res) => {
        console.log(res,"type changed")
        setValues((prevState) => ({
          ...prevState,
          orders: res.data.data,
          user: res.data.type,
        }));
      })
      .catch((err) => {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: "error",
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      });
  };

  const handleClose = () => {
    setShowDetails(false);
    setShow(false);
  };

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };
  const deletea = (cell, row) => {
    const handleDelete = (e) => {
      Swal.fire({
        title: `${CONFIRM_TITLE}`,
        text: `${CONFIRM_TXT_DEL}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: " #3085d6",
        confirmButtonText: `${DELETE_YES_BUTTON}`,
      }).then((result) => {
        if (result.isConfirmed) {
          deleteSurvey(row.id, cookies.get("agritech_token")).then((data) => {
            if (data.error) {
              Swal.fire({
                title: `${data.error}`,
                icon: "error",
                allowOutsideClick: false,
                showCancelButton: false,
                confirmButtonText: `${ALERT_BUTTON_OK}`,
              });
            } else {
              setValues((prevState) => ({
                ...prevState,
                orders: prevState.orders.filter(
                  (survey) => survey.id !== row.id
                ),
              }));
            }
          });
        }
      });
    };

    const handlePOP = (e) => {
      setSurvey(row);
      setShow(true);
    };

    return (
      <div className="d-flex align-items-start justify-content-start">
        <i
          className="mx-1 cursor-pointer "
          data-tooltip-id="1"
          data-tooltip-content={`${VIEW_DET}`}
        >
          {" "}
          <FiEye onClick={handlePOP} color="#9368f3" size="20" />
        </i>
        {row.image1 !== "" ? (
          <i
            className="mx-1 cursor-pointer "
            data-tooltip-id="2"
            data-tooltip-content={`${VIEW_IMG}`}
          >
            {" "}
            <FaImage
              onClick={() => handleImageClick(row)}
              color="green"
              size="17"
            />
          </i>
        ) : null}
        {permissions.some(
          (item) => item.keyword === "survey" && item.isTreeLocation === true
        ) && row.treelocation !== "" ? (
          <a
            id="data"
            href={
              `https://maps.google.com?q=` + row.latitude + `,` + row.longitude
            }
            target="_blank"
          >
            <i
              className="mx-1 cursor-pointer "
              data-tooltip-id="3"
              data-tooltip-content={`${TREE_LOC}`}
            >
              {" "}
              <FiMapPin color="#ff9800" size="20" />
            </i>{" "}
          </a>
        ) : null}

        {permissions.some(
          (item) => item.keyword === "survey" && item.isDelete === true
        ) && (
          <i
            className="mx-1 cursor-pointer "
            data-tooltip-id="4"
            data-tooltip-content={`${DELETE}`}
          >
            {" "}
            <FiTrash2 onClick={handleDelete} color="red" size="20" />
          </i>
        )}
        <Tooltip id="1" />
        <Tooltip id="2" />
        <Tooltip id="3" />
        <Tooltip id="4" />
        <Tooltip id="5" />

        <div
          className="modal fade"
          id={"myModal" + row.id}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg" modal-sm role="dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {STRING_CONSTANTS.ALL_IMAGES}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="root">
                  <div className="row">
                    <div className="box">
                      <img
                        className="img-responsive"
                        src={row.image1}
                        alt="NoImage"
                        style={{ width: "320px", height: "320px" }}
                      />
                    </div>
                    <div className="box">
                      {row.image2 !== "" && (
                        <img
                          className="img-responsive"
                          src={row.image2}
                          alt="NoImage"
                          style={{ width: "320px", height: "320px" }}
                        />
                      )}
                    </div>
                    <div className="box mt-3">
                      {row.image3 !== "" && (
                        <img
                          className="img-responsive"
                          src={row.image3}
                          alt="NoImage"
                          style={{ width: "320px", height: "320px" }}
                        />
                      )}
                    </div>
                    <div className="box mt-3">
                      {row.image4 !== "" && (
                        <img
                          className="img-responsive"
                          src={row.image4}
                          alt="NoImage"
                          style={{ width: "320px", height: "320px" }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div id="wrapper">
      <Topbar backgroundColor="#769A0F" />
      <Sidebar />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title float-left">
                    {STRING_CONSTANTS.SURVEY} ({count})
                  </h4>

                  <ol className="breadcrumb float-right">
                    <li className="breadcrumb-item">
                      {" "}
                      <Link to="/dashboard">{STRING_CONSTANTS.DASHBOARD}</Link>
                    </li>
                    <li className="breadcrumb-item active">
                      {STRING_CONSTANTS.SURVEY}
                    </li>
                  </ol>

                  <div className="clearfix"></div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card-box">
                  <div
                    className="row"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className="col-md-3 ">
                      {permissions.some(
                        (item) =>
                          item.keyword === "survey" && item.isSort === true
                      ) && (
                        <select
                          id="single"
                          className="form-control"
                          value={user}
                          
                          onChange={handleChange}
                        >
                          {users.map((person, i) => (
                            <option key={i} value={person}>
                              {" "}
                              {person}
                            </option>
                          ))}
                        </select>
                      )}
                    </div>
                    <div style={{ position: "relative", marginLeft: "auto" }}>
                      <input
                        type="text"
                        placeholder="Search..."
                        className="form-control"
                        style={{
                          width: "200px",
                          paddingLeft: "35px",
                          boxSizing: "border-box",
                          marginLeft: "auto",
                        }}
                        onChange={(e) => {
                          handleSearch(e);
                          if (e.target.value.trim() === "") {
                            e.target.nextElementSibling.style.display =
                              "inline-block";
                          } else {
                            e.target.nextElementSibling.style.display = "none";
                          }
                        }}
                      />
                      <i
                        className="fas fa-search"
                        style={{
                          position: "absolute",
                          left: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          color: "#aaa",
                        }}
                      ></i>
                    </div>
                  </div>
                  {questions === true && (
                    <div className="row">
                      <div className="col-md-6">
                        <div>
                          <link
                            href="https://getbootstrap.com/docs/4.0/dist/css/bootstrap.min.css"
                            rel="stylesheet"
                          />
                          <div className="container"></div>
                          <p>
                            <span className="Apple-converted-space">
                              &nbsp;&nbsp;{" "}
                            </span>
                            <strong>{STRING_CONSTANTS.ENGLISH}</strong>
                          </p>

                          <ol>
                            <li>
                              {STRING_CONSTANTS.DO_YOU_HAVE_JACKFRUIT}
                              <span className="Apple-converted-space">
                                &nbsp;
                              </span>
                            </li>
                          </ol>
                          <p>
                            {STRING_CONSTANTS.ANSWER} : {STRING_CONSTANTS.YES}{" "}
                            {STRING_CONSTANTS.OR} {STRING_CONSTANTS.NO}
                            <span className="Apple-converted-space">
                              &nbsp;
                            </span>
                          </p>
                          <ol start="2">
                            <li>
                              {STRING_CONSTANTS.WILLING_TO_MAKE_INCOME}
                              <span className="Apple-converted-space"></span>
                            </li>
                          </ol>
                          <p>
                            {STRING_CONSTANTS.ANSWER} : {STRING_CONSTANTS.YES}{" "}
                            {STRING_CONSTANTS.OR} {STRING_CONSTANTS.NO}
                            <span className="Apple-converted-space"></span>
                          </p>
                          <ol start="3">
                            <li>
                              {STRING_CONSTANTS.HOW_MANY_JACK_TREE}
                              <span className="Apple-converted-space">
                                &nbsp;
                              </span>
                            </li>
                          </ol>
                          <ol>
                            <p>
                              {STRING_CONSTANTS.HOW_MANY_KOOZHA}
                              <span className="Apple-converted-space">
                                {" "}
                                &nbsp;
                              </span>
                              {STRING_CONSTANTS.HOW_MANY_VARIKKA}
                            </p>
                          </ol>
                          <ol start="4">
                            <li>
                              {STRING_CONSTANTS.WHAT_ARE_THE_RANDOM_TREES}
                            </li>
                          </ol>
                          <p>{STRING_CONSTANTS.OPTIONS} :</p>
                          <p>{STRING_CONSTANTS.MUTTOM_VARIKKA}</p>
                          <p>{STRING_CONSTANTS.SINGAPORE_JACK}</p>
                          <p>{STRING_CONSTANTS.THEENVARIKKA}</p>
                          <p>{STRING_CONSTANTS.SINDOOR}</p>
                          <p>{STRING_CONSTANTS.VIETNAM_SUPER_EARLY}</p>
                          <p>{STRING_CONSTANTS.AYUR_JACK}</p>
                          <p>{STRING_CONSTANTS.JACKFRUIT_OTHERS}</p>
                          <ol start="5">
                            <li>
                              {STRING_CONSTANTS.WHAT_IS_USUAL_HARVERST_TIME}
                              <span className="Apple-converted-space"></span>
                            </li>
                          </ol>
                          <p>
                            {STRING_CONSTANTS.DATE_FROM_TO}
                            <span className="Apple-converted-space">
                              &nbsp;
                            </span>
                          </p>
                          <ol start="6">
                            <li>
                              {STRING_CONSTANTS.HOW_MUCH_YOU_EARNED}
                              <span className="Apple-converted-space"></span>
                            </li>
                          </ol>
                          <p>
                            {STRING_CONSTANTS.ENTER_THE_FIGURE}
                            <span className="Apple-converted-space"></span>
                          </p>
                          <ol start="7">
                            <li>
                              {STRING_CONSTANTS.ARE_YOUR_WILLING_TO_SELL}
                              <span className="Apple-converted-space">
                                &nbsp;{" "}
                              </span>
                              {STRING_CONSTANTS.THROUGH_JACKFRUITWORLD}
                              <span className="Apple-converted-space">
                                &nbsp;
                              </span>
                            </li>
                          </ol>
                          <p>
                            {STRING_CONSTANTS.YES} {STRING_CONSTANTS.OR}{" "}
                            {STRING_CONSTANTS.NO}
                            <span className="Apple-converted-space">
                              &nbsp;
                            </span>
                          </p>
                          <ol start="8">
                            <li>
                              {STRING_CONSTANTS.ARE_YOUR_WILLING_TO_DELEVER}
                              <span className="Apple-converted-space"></span>
                              {STRING_CONSTANTS.TO_YOUR_LOCAL}
                            </li>
                          </ol>
                          <p>
                            {STRING_CONSTANTS.YES} {STRING_CONSTANTS.OR}{" "}
                            {STRING_CONSTANTS.NO}
                          </p>
                          <ol start="9">
                            <li>
                              {STRING_CONSTANTS.ARE_YOUR_WILLING_TO_PLANT}
                            </li>
                          </ol>
                          <p>
                            {STRING_CONSTANTS.YES} {STRING_CONSTANTS.OR}{" "}
                            {STRING_CONSTANTS.NO}
                          </p>
                          <ol start="10">
                            <li>
                              {STRING_CONSTANTS.IF_ANSER_IS_YES}-
                              <span className="Apple-converted-space">
                                &nbsp;{" "}
                              </span>
                              {
                                STRING_CONSTANTS.DO_YOU_INTRESTED_BACK_FROM_PROFESSIONAL
                              }
                              <span className="Apple-converted-space">
                                &nbsp;
                              </span>
                            </li>
                          </ol>
                          <p>
                            {STRING_CONSTANTS.YES} {STRING_CONSTANTS.OR}{" "}
                            {STRING_CONSTANTS.NO}
                            <span className="Apple-converted-space">
                              &nbsp;
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div>
                          <link
                            href="https://getbootstrap.com/docs/4.0/dist/css/bootstrap.min.css"
                            rel="stylesheet"
                          />
                          <div className="container"></div>
                          <p>
                            <strong>{STRING_CONSTANTS.MALAYALAM}</strong>
                          </p>
                          <p>1. നിങ്ങളുടെ വീട്ടിൽ പ്ലാവുണ്ടോ ?</p>
                          <p>
                            2. ചക്കയുടെ വിപണത്തിൽ കൂടി വരുമാനം ഉണ്ടാക്കുവാൻ
                            താത്പ്പര്യം ഉണ്ടോ ?
                            <span className="Apple-converted-space">
                              &nbsp;
                            </span>
                          </p>
                          <p>
                            3
                            <span className="Apple-converted-space">
                              &nbsp;{" "}
                            </span>
                            . ഉണ്ടെങ്കിൽ എത്ര പ്ലാവുണ്ട് ?
                          </p>
                          <p>
                            <span className="Apple-converted-space">
                              &nbsp;
                            </span>
                            .a എത്ര കൂഴ ? b
                            <span className="Apple-converted-space">
                              &nbsp;{" "}
                            </span>
                            . എത്ര വരിക്ക ?
                          </p>
                          <p>4. നിങളുടെ വീട്ടിൽ എന്തൊക്കെ ഇനം പ്ലാവുണ്ട് ?</p>
                          <p>options:</p>
                          <p>മുട്ടം വരിക്ക</p>
                          <p>സിംഗപ്പൂർ പ്ലാവ്</p>
                          <p>തേൻ വരിക്ക</p>
                          <p>സിന്ദൂർ</p>
                          <p>വിയറ്റ്നാം സൂപ്പർ ഏർളി</p>
                          <p>ആയുർ ജാക്</p>
                          <p>മറ്റുള്ളവ (Others )</p>
                          <p>&nbsp;</p>
                          <p>5. നിങ്ങളുടെ പ്ലാവ് കായ്ക്കുന്ന സമയം ?</p>
                          <p>മാസം ( starting Month ) (ending month )</p>
                          <p>
                            6 .പോയവർഷം ചക്കയുടെ വിപണനത്തിൽകൂടി എത്ര വരുമാനം
                            ലഭിച്ചു ?
                            <span className="Apple-converted-space"></span>
                          </p>
                          <p>
                            7 . കൂടുതൽ വരുമാനം ലഭിച്ചാൽ ജാക്ക്ഫ്രൂട്ട് വേൾഡ്
                            <span className="Apple-converted-space">
                              &nbsp;{" "}
                            </span>
                            (Jackfruitworld) മുഘേനെ{" "}
                            <span className="Apple-converted-space"> </span>ചക്ക
                            കൊടുക്കാൻ നിങ്ങൾക്ക്
                            <span className="Apple-converted-space">
                              &nbsp;{" "}
                            </span>
                            താത്പ്പര്യം ഉണ്ടോ
                            <span className="Apple-converted-space">
                              &nbsp;{" "}
                            </span>
                            ?
                          </p>
                          <p>
                            8 . നിങ്ങളുടെ വീട്ടിലെ ചക്ക കേടുപാട് കൂടാതെ ഇട്ട്
                            അടുത്തുള്ള കളക്ഷൻ പോയിന്റിൽ
                            <span className="Apple-converted-space">
                              &nbsp;{" "}
                            </span>
                            എത്തിച്ചു തരാൻ നിങ്ങൾക് സാധിക്കുമോ
                            <span className="Apple-converted-space"> </span>?
                          </p>
                          <p>
                            9 .ചക്ക വ്യവസായ അടിസ്ഥാനത്തിൽ കൃഷി ചെയ്യുവാൻ
                            താത്പ്പര്യമുണ്ടോ ?
                          </p>
                          <p>
                            {STRING_CONSTANTS.IF_ANSER_IS_YES}
                            <span className="Apple-converted-space"></span>
                          </p>
                          <p>
                            10 .ഞങ്ങളുടെ പ്ലാവുകൃഷി വിദക്തരുടെ കൺസൾട്ടൻസി
                            ആവശ്യമുണ്ടോ ?
                          </p>
                          <p>
                            {STRING_CONSTANTS.YES} {STRING_CONSTANTS.OR}{" "}
                            {STRING_CONSTANTS.NO}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  <div
                    style={{
                      width: "98%",
                    }}
                  >
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell width="80px">
                              {STRING_CONSTANTS.SN_NO}
                            </TableCell>
                            <TableCell width="180px">
                              {STRING_CONSTANTS.NAME}
                            </TableCell>
                            <TableCell width="180px">
                              {STRING_CONSTANTS.DISPLAY_NAME}
                            </TableCell>
                            <TableCell width="180px">
                              {STRING_CONSTANTS.AGENT}
                            </TableCell>
                            <TableCell width="180px">
                              {STRING_CONSTANTS.USER_TYPE}
                            </TableCell>
                            <TableCell width="180px">
                              {STRING_CONSTANTS.MOBILE}
                            </TableCell>
                            <TableCell width="180px">
                              {STRING_CONSTANTS.ACTIONS}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {orders.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            ).length > 0 ? (
                            orders.map((row, index) => (
                              <TableRow key={index}>
                                <TableCell>  {page * rowsPerPage + index + 1}</TableCell>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>
                                  <span
                                    data-tooltip-id="5"
                                    data-tooltip-content={`${row.orgname}`}
                                  >
                                    {row.organisation}
                                  </span>
                                </TableCell>
                                <TableCell>{row.agent}</TableCell>
                                <TableCell>{row.type}</TableCell>
                                <TableCell>{row.mobile_no}</TableCell>
                                <TableCell>{deletea(null, row)}</TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell colSpan={7} align="center">
                                {STRING_CONSTANTS.NO_DATA_FOUND}
                              </TableCell>
                            </TableRow>
                          )}
                          <Dialog
                            open={show}
                            onClose={handleClose}
                            // aria-labelledby="survey-details-dialog-title"

                            aria-labelledby="details-modal-title"
                            aria-describedby="details-modal-description"
                            fullWidth
                            disableEnforceFocus
                            maxWidth="lg"
                          >
                            <Grid item xs={12} className="modal-header">
                              <h3 id="details-modal-title">
                                {" "}
                                {STRING_CONSTANTS.SURVEY_DETAILS}
                              </h3>
                              <Button onClick={handleClose} color="inherit">
                                <span
                                  style={{ fontSize: "20px" }}
                                  aria-hidden="true"
                                >
                                  &#128473;
                                </span>
                              </Button>
                            </Grid>
                            <DialogContent dividers>
                              <Table>
                                <TableBody>
                                  <TableRow>
                                    <TableCell>
                                      {SURVEY_QUESTIONS.QUESTION1}
                                    </TableCell>
                                    <TableCell align="right">
                                      {survey.ans1}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      {SURVEY_QUESTIONS.QUESTION2}
                                    </TableCell>
                                    <TableCell align="right">
                                      {survey.ans2}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      {SURVEY_QUESTIONS.QUESTION2A}
                                    </TableCell>
                                    <TableCell align="right">
                                      {survey.ans2a}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      {SURVEY_QUESTIONS.QUESTION2B}
                                    </TableCell>
                                    <TableCell align="right">
                                      {survey.ans2b}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      {SURVEY_QUESTIONS.QUESTION3}
                                    </TableCell>
                                    <TableCell align="right">
                                      {survey.ans3}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      {SURVEY_QUESTIONS.QUESTION4}
                                    </TableCell>
                                    <TableCell align="right">
                                      {survey.ans4}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      {SURVEY_QUESTIONS.QUESTION5}
                                    </TableCell>
                                    <TableCell align="right">
                                      {survey.ans5}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      {SURVEY_QUESTIONS.QUESTION6}
                                    </TableCell>
                                    <TableCell align="right">
                                      {survey.ans6}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      {SURVEY_QUESTIONS.QUESTION7}
                                    </TableCell>
                                    <TableCell align="right">
                                      {survey.ans7}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      {SURVEY_QUESTIONS.QUESTION8}
                                    </TableCell>
                                    <TableCell align="right">
                                      {survey.ans8}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      {SURVEY_QUESTIONS.QUESTION9}
                                    </TableCell>
                                    <TableCell align="right">
                                      {survey.specialJackfruitInfo && (
                                        <Button
                                          onClick={toggleDetails}
                                          aria-label={
                                            showDetails ? "Collapse" : "Expand"
                                          }
                                          style={{
                                            border: "none",
                                            background: "none",
                                            padding: 0,
                                            margin: 0,
                                          }}
                                        >
                                          {showDetails ? (
                                            <span>
                                              Hide Details
                                              <FiArrowUp
                                                style={{
                                                  verticalAlign: "middle",
                                                  paddingRight: "5px",
                                                }}
                                              />
                                            </span>
                                          ) : (
                                            <span>
                                              Show Details
                                              <FiArrowDown
                                                style={{
                                                  verticalAlign: "middle",
                                                  paddingRight: "5px",
                                                }}
                                              />
                                            </span>
                                          )}
                                        </Button>
                                      )}
                                      {survey.ans9}
                                    </TableCell>
                                  </TableRow>
                                  {survey.specialJackfruitInfo &&
                                    showDetails && (
                                      <>
                                        <TableRow>
                                          <TableCell>
                                            {
                                              SURVEY_QUESTIONS.SPECIAL_INFO_LOCAL_NAME
                                            }
                                          </TableCell>
                                          <TableCell align="right">
                                            {
                                              survey.specialJackfruitInfo
                                                .hasLocalName
                                            }
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>
                                            {SURVEY_QUESTIONS.SPECIAL_INFO_AGE}
                                          </TableCell>
                                          <TableCell align="right">
                                            {
                                              survey.specialJackfruitInfo
                                                .ageOfTree
                                            }
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>
                                            {
                                              SURVEY_QUESTIONS.SPECIAL_INFO_HEIGHT
                                            }
                                          </TableCell>
                                          <TableCell align="right">
                                            {
                                              survey.specialJackfruitInfo
                                                .heightOfTree
                                            }
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>
                                            {
                                              SURVEY_QUESTIONS.SPECIAL_INFO_WIDTH
                                            }
                                          </TableCell>
                                          <TableCell align="right">
                                            {
                                              survey.specialJackfruitInfo
                                                .widthOfTrunk
                                            }
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>
                                            {
                                              SURVEY_QUESTIONS.SPECIAL_INFO_YIELD
                                            }
                                          </TableCell>
                                          <TableCell align="right">
                                            {
                                              survey.specialJackfruitInfo
                                                .fruitsPerYear
                                            }
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>
                                            {
                                              SURVEY_QUESTIONS.SPECIAL_INFO_SEASON
                                            }
                                          </TableCell>
                                          <TableCell align="right">
                                            {
                                              survey.specialJackfruitInfo
                                                .harvestSeason
                                            }
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>
                                            {SURVEY_QUESTIONS.SPECIAL_INFO_SIZE}
                                          </TableCell>
                                          <TableCell align="right">
                                            {
                                              survey.specialJackfruitInfo
                                                .fruitSize
                                            }
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>
                                            {
                                              SURVEY_QUESTIONS.SPECIAL_INFO_BULBS
                                            }
                                          </TableCell>
                                          <TableCell align="right">
                                            {
                                              survey.specialJackfruitInfo
                                                .numberOfBulbs
                                            }
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>
                                            {
                                              SURVEY_QUESTIONS.SPECIAL_INFO_BULB_COLOR
                                            }
                                          </TableCell>
                                          <TableCell align="right">
                                            {
                                              survey.specialJackfruitInfo
                                                .bulbColor
                                            }
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>
                                            {
                                              SURVEY_QUESTIONS.SPECIAL_INFO_RIND_COLOR
                                            }
                                          </TableCell>
                                          <TableCell align="right">
                                            {
                                              survey.specialJackfruitInfo
                                                .jackfruitRindColor
                                            }
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>
                                            {
                                              SURVEY_QUESTIONS.SPECIAL_INFO_BULB_SIZE
                                            }
                                          </TableCell>
                                          <TableCell align="right">
                                            {
                                              survey.specialJackfruitInfo
                                                .bulbSize
                                            }
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>
                                            {
                                              SURVEY_QUESTIONS.SPECIAL_INFO_BULB_TEXTURE
                                            }
                                          </TableCell>
                                          <TableCell align="right">
                                            {
                                              survey.specialJackfruitInfo
                                                .bulbTexture
                                            }
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>
                                            {
                                              SURVEY_QUESTIONS.SPECIAL_INFO_TRADITIONAL_USE
                                            }
                                          </TableCell>
                                          <TableCell align="right">
                                            {
                                              survey.specialJackfruitInfo
                                                .traditionalUse
                                            }
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>
                                            {
                                              SURVEY_QUESTIONS.SPECIAL_INFO_OTHER_DETAILS
                                            }
                                          </TableCell>
                                          <TableCell align="right">
                                            {
                                              survey.specialJackfruitInfo
                                                .otherDetails
                                            }
                                          </TableCell>
                                        </TableRow>
                                      </>
                                    )}
                                  <TableRow>
                                    <TableCell>
                                      {SURVEY_QUESTIONS.QUESTION10}
                                    </TableCell>
                                    <TableCell align="right">
                                      {survey.ans10}
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </DialogContent>
                            <DialogActions>
                              <Button
                                onClick={handleClose}
                                variant="outlined"
                                color="error"
                              >
                                Close
                              </Button>
                            </DialogActions>
                          </Dialog>
                          <ImageModal
                            open={open}
                            handleClose={handleClose1}
                            imageSrc={images}
                          />
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 50, 100]}
                        component="div"
                        count={orders.length}
                        rowsPerPage={rowsPerPage||0}
                        page={page||0}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Survey;
