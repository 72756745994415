import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { Link, useNavigate } from "react-router-dom";
import { GET_PROFILE_PATH } from "../constants/url";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { privateApiCall } from "../api/privateApi";
import { ERR_ALERT_TITLE, ERR_GETTING } from "../constants/alertMessage";
import Swal from "sweetalert2/dist/sweetalert2";
import { STRING_CONSTANTS } from "../constants/stringConstants";

const Topbar = ({ backgroundColor }) => {
  const cookies = new Cookies();
  const [dname, setDname] = useState("");
  const [dimage, setDimage] = useState("/assets/images/jack_logo.png"); // Set default image URL here
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {

        const requestUrl = `${GET_PROFILE_PATH}/${cookies.get(
          "agritech_id"
        )}/?token=${cookies.get("agritech_token")}`
        const res = await privateApiCall(requestUrl,'GET')
      
        
        setDname(res.data.admin[0].name);
        localStorage.setItem("userNameInfo", res.data.admin[0].name);
        // Update dimage state with the fetched image URL, or fallback to default image URL
        setDimage(
          res.data.admin[0].image || "/assets/images/jack_logo.png"
        );
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: `${ERR_ALERT_TITLE}`,
          text: `${ERR_GETTING}`,
          confirmButtonText: "OK",
        });
        // document.location = "/#/App/editprofile";
      }
    };

    fetchData();
  }, []);

  const logout = () => {
    cookies.remove("agritech_token");
    cookies.remove("agritech_id");
    cookies.remove("login_type");
    navigate("/");
  };

  const handleMobileMenuClick = (event) => {
    event.preventDefault();
    document.body.classList.toggle("sidebar-enable");
    if (window.innerWidth >= 768) {
      document.body.classList.toggle("enlarged");
    } else {
      document.body.classList.remove("enlarged");
    }
  };

  const handleNavbarToggleClick = (event) => {
    event.preventDefault();
    const navigation = document.getElementById("navigation");
    if (navigation) {
      event.currentTarget.classList.toggle("open");
      navigation.style.transition = "height 0.4s ease-in-out";
      navigation.style.overflow = "hidden";
      navigation.style.height =
        navigation.clientHeight === 0
          ? `${navigation.scrollHeight}px`
          : "0";
    }
  };

  return (
    <div className="navbar-custom" style={{ background: backgroundColor }}>
      <div className="container-fluid">
        <ul className="list-unstyled topnav-menu float-right mb-0">
          <li className="dropdown notification-list">
            <a
              className="navbar-toggle nav-link"
              onClick={handleNavbarToggleClick}
            >
              <div className="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </a>
          </li>

          <li className="dropdown">
            <a
              className="nav-link waves-effect waves-light nav-user mr-5"
              data-toggle="dropdown"
              href="#"
              role="button"
              aria-haspopup="false"
              aria-expanded="false"
            >
              <img
                src={dimage} // Use dynamic image URL here
                alt=" "
                className="rounded-circle"
              />
              <span className="ml-1" style={{ color: "white" }}>
                {dname}
                <i className="mdi mdi-chevron-down"></i>{" "}
              </span>
            </a>

            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <Link
                to="/profiles/editprofile"
                className="dropdown-item notify-item"
              >
                <i className="fi-head"></i> <span>{STRING_CONSTANTS.MY_ACCOUNT}</span>
              </Link>
              <Link
                to="/profiles/mfasettings"
                className="dropdown-item notify-item"
              >
                <i className="fi-head"></i> <span>{STRING_CONSTANTS.MFA_CONFIGURATION}</span>
              </Link>
              <Link
                to="/"
                className="dropdown-item notify-item"
                onClick={logout}
              >
                <i className="fi-power"></i> <span>{STRING_CONSTANTS.LOGOUT}</span>
              </Link>
            </div>
          </li>
        </ul>

        <div className="logo-box">
          <Link to="/dashboard" className="logo text-center">
            <span className="logo-lg">
              <img
                src="/assets/images/jack_logo.png"
                height={60}
              />
            </span>
            <span className="logo-sm">
              <img
                src="/assets/images/jack_logo.png"
                height={60}
              />
            </span>
          </Link>
        </div>

        <ul className="list-unstyled topnav-menu topnav-menu-left m-0">
          <li>
            <button
              className="button-menu-mobile waves-effect waves-light"
              onClick={handleMobileMenuClick}
            >
              <i className="fe-menu"></i>
            </button>
          </li>
        </ul>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition="Bounce"
      />
    </div>
  );
};

export default Topbar;
