import React, { Component } from "react";
import Topbar from "../topBar";
import Sidebar from "../sideBar";
import Cookies from "universal-cookie";
import {
  FiMessageCircle,
  FiXCircle,
  FiCheckCircle,
  FiEye,
} from "react-icons/fi";
import { FaImage } from "react-icons/fa";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { formatDate } from "../../utils/dateUtils";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import {
  APPROVE,
  FEED,
  REJECT,
  SELL_PRODUCT,
  VIEW_DET,
  VIEW_IMG,
} from "../../constants/url";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  TableRow,
  Checkbox,
  Paper,
  Button,
  Grid,
  Dialog,
  TextField,
} from "@mui/material";
import {
  ALERT_BUTTON_OK,
  ALERT_BUTTON_YES,
  ALERT_ICON_ERR,
  NO_DATA_FOUND,
  NO_S_PEN,
  CONFIRM_TITLE,
  CONFIRM_TXT_ACC_ALL,
  CONFIRM_TXT_REJ_ALL,
  CONFIRM__ACC_ALL_YES_BUTTON,
  CONFIRM__REJ_ALL_YES_BUTTON,
  ERR_ALERT_TITLE,
  ERR_GETTING,
  ERR_POSTING,
  SUCC_ALERT_TITLE,
  SUCC_APPROVE_TEXT,
  SUCC_APPROVE_TITLE,
  SUCC_FEEDBACK,
  SUCC_REJECTPRODUCT_TITLE,
  SUCC_REJECTPRODUCT_TXT,
} from "../../constants/alertMessage";
import ImageModal from "../../images/imageModal";
import { privateApiCall } from "../../api/privateApi";
import { STRING_CONSTANTS } from "../../constants/stringConstants";

const cookies = new Cookies();
class pendingList extends Component {
  constructor() {
    const localRole = JSON.parse(localStorage.getItem("role"));
    super();
    this.state = {
      data: "",
      replay: "",
      selectedRows: [],
      images: [],
      page: 0,
      rowsPerPage: 10,
      open: false,
      open1: false,
      open2: false,
      open3: false,
      feedId: "",
      name: "",
      customer: "",
      mobile_no: "",
      title: "",
      description: "",
      qty: "",
      unit: "",
      expDate: "",
      transpot_status: "",
      organic_status: "",
      safe_status: "",
      permissions:
        localRole && localRole.permissions ? localRole.permissions : "All",
        gstCategoryList:[],
        gstId:"",
        prodID:""
    };
    this.acceptAll = this.acceptAll.bind(this);
    this.rejectAll = this.rejectAll.bind(this);
    this.edit = this.edit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckBox = this.handleCheckBox.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleRowPageChange = this.handleRowPageChange.bind(this);
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  handleClose1 = () => {
    this.setState({ open1: false });
  };
  handleClose2 = () => {
    this.setState({ open2: false });
  };
  handlePageChange(e, newPage) {
    this.setState({ page: newPage });
  }
  
  handleRowPageChange(event) {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  }
 
  componentDidMount() {
    this.fetchPendingListData();
  }

  addSerialNumbers(data) {
    return data.map((item, index) => ({
      ...item,
      sno: index + 1,
    }));
  }
  handleClose3 = () => {
    this.setState({ open3: false });
  };

  handleGstCategoryChange = async()=>{
    const requestUrl = `/app/getGstCategoryList`;
    const response = await privateApiCall(requestUrl,"GET")
    if(response.data.status){
      this.setState({
       gstCategoryList: response.data.category,
       open1:false,
       open3:true
      })
    }else{
      Swal.fire({
        title: `${ERR_POSTING}`,
        icon: `${ALERT_ICON_ERR}`,
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonText: `${ALERT_BUTTON_OK}`,
      });
    }

  }
  handleGSTSubmit = async ()=>{
    const gstCatId = this.state.gstId

    const productID = this.state.prodID
    const data = {productID,gstCatId}
    const requestUrl = `/app/changeGstCategory`
    const response = await privateApiCall(requestUrl,"POST",{data})
    if(response.data.status){
     this.fetchPendingListData()
          this.setState({
            open3:false
          })
    }
  }

    fetchPendingListData() {
    const requestUrl = `${SELL_PRODUCT}/pendinglist/`;
    privateApiCall(requestUrl, "GET")
      .then(
        (res) => {
          console.log(res,"response pending list data")
          const data = res.data.data;
          if (data.length === 0) {
            Swal.fire({
              title: `${NO_DATA_FOUND}`,
              icon: "info",
              text: `${NO_S_PEN}`,
              allowOutsideClick: false,
              confirmButtonText: `${ALERT_BUTTON_OK}`,
            });
          } else {
            // Renumber the items
            const numberedData = data.map((item, index) => ({
              ...item,
              sno: index + 1, // Assign sno based on the current index
            }));

            // Set the state with the pending list data
            this.setState({ data: numberedData });
          }
        },
        (err) => {
          Swal.fire({
            title: `${err}`,
            icon: "error",
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: `${ALERT_BUTTON_OK}`,
          });
        }
      )
      .catch(() => {
        Swal.fire({
          title: `${ERR_GETTING}`,
          icon: `${ALERT_ICON_ERR}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      });
  }

  handleSearch(e) {
    const searchValue = e.target.value;
    const requestUrl = `${SELL_PRODUCT}/search_sell_pendinglist?query=${searchValue}&`;

    privateApiCall(requestUrl, "GET")
      .then((response) => {
        if (response.data && response.data.data) {
          const dataWithSerialNumbers = this.addSerialNumbers(
            response.data.data
          );
          this.setState({ data: dataWithSerialNumbers });
        } else {
          this.setState({ data: [] });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: `${ERR_GETTING}`,
          icon: `${ALERT_ICON_ERR}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      });
  }

  handleCheckBox(e, row) {
    const { selectedRows } = this.state;
    const selectedIndex = selectedRows.indexOf(row);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, row);
    } else {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }
    this.setState({ selectedRows: newSelected });
  }

  handleChange(event) {
    this.setState({
      replay: event.target.value,
    });
  }

  handleReplay = (event) => {
    event.preventDefault();

    const { feedId, replay } = this.state;

    const requestUrl = `${SELL_PRODUCT}/replayToSProduct/?_id=${feedId}&replay=${replay}`;
    privateApiCall(requestUrl, "GET")
      .then((res) => {
        if (res.data.status) {
          const requestUrl = `${SELL_PRODUCT}/pendinglist/`;
          privateApiCall(requestUrl, "GET")
            .then((res) => {
              const k = 1;
              for (let i = 0; i < res.data.data.length; i++) {
                res.data.data[i].sno = k + i;
              }
              this.setState({
                open2: false,
                replay: "",
                data: res.data.data,
              });

              // Show success message
              Swal.fire({
                title: `${SUCC_ALERT_TITLE}`,
                text: `${SUCC_FEEDBACK}`,
                icon: "success",
                allowOutsideClick: false,
                showCancelButton: false,
                confirmButtonText: `${ALERT_BUTTON_OK}`,
              });
            })
            .catch((err) => {
              Swal.fire({
                title: `${ERR_POSTING}`,
                icon: `${ALERT_ICON_ERR}`,
                allowOutsideClick: false,
                showCancelButton: false,
                confirmButtonText: `${ALERT_BUTTON_OK}`,
              });
            });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: `${ERR_POSTING}`,
          icon: `${ALERT_ICON_ERR}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      });
  };

  edit(cell, row, extraData, index) {
    console.log(row,"row");
    
    const handleImageClick = () => {
      const imagesData = [row.image1, row.image2, row.image3, row.image4];

      const filteredImagesData = imagesData.filter(
        (image) => image !== undefined && image !== null && image !== ""
      );

      this.setState({ images: filteredImagesData });
      // Show the modal
      this.setState({ open: true });
    };
    const hasImageData = [row.image1, row.image2, row.image3, row.image4].some(
      (image) => image !== undefined && image !== null && image !== ""
    );

    const handleS = (e) => {
      this.setState({ feedId: row.id });
      this.setState({ open2: true });
    };

    const handleOnChange = (event) => {
      event.preventDefault();

      Swal.fire({
        title: `${APPROVE} ${row.title}`,
        text: "",
        icon: "success",
        allowOutsideClick: false,
        confirmButtonText: `${ALERT_BUTTON_YES}`,
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          const requestUrl = `${SELL_PRODUCT}/approveProduct/${row.id}/`;
          privateApiCall(requestUrl, "POST", null)
            .then((res) => {
              console.log(res, "response from the");
              if (res.status === 200) {
                let updatedSelections = [...this.state.data];
                updatedSelections.splice(index, 1);
                for (let i = 0; i < updatedSelections.length; i++) {
                  updatedSelections[i].sno = i + 1;
                }

                this.setState({
                  data: updatedSelections,
                });

                Swal.fire({
                  title: `${SUCC_APPROVE_TITLE}`,
                  text: `${SUCC_APPROVE_TEXT}`,
                  icon: "success",
                  allowOutsideClick: false,
                  showCancelButton: false,
                  confirmButtonText: `${ALERT_BUTTON_OK}`,
                });
              }
            })
            .catch((err) => {
              Swal.fire({
                title: `${ERR_POSTING}`,
                icon: `${ALERT_ICON_ERR}`,
                allowOutsideClick: false,
                showCancelButton: false,
                confirmButtonText: `${ALERT_BUTTON_OK}`,
              });
            });
        }
      });
    };

    const handlePOP = (e) => {
      const {
        customer,
        mobile_no,
        name,
        availableDate,
        expDate,
        description,
        freeDelivery,
        chargesAfter,
        title,
        qty,
        price,
        location,
        unit,
        auction_status,
        safe_status,
        organic_status,
        transpot_status,
        gstCategory,
        id
      } = row;
      console.log(id,"gstCateogy");
      
      this.setState({
        customer: customer,
        mobile_no: mobile_no,
        name: name,
        prodID:id,
        availableDate: availableDate,
        expDate: expDate,
        description: description,
        freeDelivery: freeDelivery,
        chargesAfter: chargesAfter,
        title: title,
        qty: qty,
        price: price,
        location: location,
        unit: unit,
        auction_status,
        safe_status,
        organic_status,
        transpot_status,
        gstCategory,
      });
      this.setState({ open1: true });
    };
    const handleOnReject = (event) => {
      event.preventDefault();
      Swal.fire({
        title: `${REJECT} ${row.title}`,
        text: "",
        icon: "error",
        allowOutsideClick: false,
        confirmButtonText: `${ALERT_BUTTON_YES}`,
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          const requestUrl = `${SELL_PRODUCT}/rejectProduct/${row.id}/`;
          privateApiCall(requestUrl, "GET")
            .then((res) => {
              if (res.status === 200) {
                let updatedSelections = [...this.state.data];

                updatedSelections.splice(index, 1);

                for (let i = 0; i < updatedSelections.length; i++) {
                  updatedSelections[i].sno = i + 1;
                }

                this.setState({
                  data: updatedSelections,
                });

                Swal.fire({
                  title: `${SUCC_REJECTPRODUCT_TITLE}`,
                  text: `${SUCC_REJECTPRODUCT_TXT}`,
                  icon: "success",
                  allowOutsideClick: false,
                  showCancelButton: false,
                  confirmButtonText: `${ALERT_BUTTON_OK}`,
                });
              }
            })
            .catch((err) => {
              Swal.fire({
                title: `${ERR_ALERT_TITLE}`,
                text: `${ERR_POSTING}`,
                icon: "error",
                allowOutsideClick: false,
                showCancelButton: false,
                confirmButtonText: `${ALERT_BUTTON_OK}`,
              });
            });
        }
      });
    };

    return (
      <span>
        {this.state.permissions.some(
          (item) =>
            item.keyword === "sellProductPendingList" && item.isApprove === true
        ) && (
          <>
            <i data-tooltip-id="1" data-tooltip-content={`${APPROVE}`}>
              <FiCheckCircle
                onClick={handleOnChange}
                color="#4fbde9"
                size="20"
              />
            </i>
            <Tooltip id="1" />
          </>
        )}
        {this.state.permissions.some(
          (item) =>
            item.keyword === "sellProductPendingList" && item.isReject === true
        ) && (
          <>
            <i
              className="ml-1"
              data-tooltip-id="2"
              data-tooltip-content={`${REJECT}`}
            >
              {" "}
              <FiXCircle onClick={handleOnReject} color="#f36270" size="20" />
            </i>
            <Tooltip id="2" />
          </>
        )}

        {this.state.permissions.some(
          (item) =>
            item.keyword === "sellProductPendingList" &&
            item.isFeedback === true
        ) && (
          <>
            {row.replay !== "" ? (
              <i
                className="ml-1"
                data-tooltip-id="3"
                data-tooltip-content={`${FEED}`}
              >
                {" "}
                <FiMessageCircle onClick={handleS} color="#769A0F" size="20" />
              </i>
            ) : (
              <i
                className="ml-1"
                data-tooltip-id="3"
                data-tooltip-content={`${FEED}`}
              >
                {" "}
                <FiMessageCircle onClick={handleS} color="#f9bc0b" size="20" />
              </i>
            )}
            <Tooltip id="3" />
          </>
        )}

        {this.state.permissions.some(
          (item) =>
            item.keyword === "sellProductPendingList" &&
            item.isDetailedInfo === true
        ) && (
          <>
            <i
              className="ml-1"
              data-tooltip-id="3"
              data-tooltip-content={`${VIEW_DET}`}
            >
              {" "}
              <FiEye onClick={handlePOP} color="#9368f3" size="20" />
            </i>
            <Tooltip id="3" />
          </>
        )}

        {this.state.permissions.some(
          (item) =>
            item.keyword === "sellProductPendingList" &&
            item.isShowImage === true
        ) && (
          <>
            {hasImageData && (
              <i
                className="ml-1"
                data-tooltip-id="4"
                data-tooltip-content={`${VIEW_IMG}`}
              >
                {" "}
                <FaImage onClick={handleImageClick} color="green" size="17" />
              </i>
            )}
            <Tooltip id="4" />
          </>
        )}
      </span>
    );
  }

  delete(cell, row) {
    return (
      <span>
        <button
          type="submit"
          className="btn btn-icon waves-effect waves-light btn-danger"
          data-toggle="modal"
          data-target={"#myModal" + row._id}
        >
          {" "}
          <i className="fa fa-remove"></i>{" "}
        </button>

        <div
          id={"myModal" + row._id}
          className="modal fade"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <form
              method="post"
              action={
                "/delete_category/" +
                row._id +
                "/?token=" +
                cookies.get("asia_page_token")
              }
            >
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    ×
                  </button>
                  <h5 className="modal-title" id="myModalLabel">
                    {STRING_CONSTANTS.WARNING}
                  </h5>
                </div>
                <div className="modal-body">
                  {STRING_CONSTANTS.ARE_YOU_SURE}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary waves-effect"
                    data-dismiss="modal"
                  >
                    {STRING_CONSTANTS.CLOSE}
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary waves-effect waves-light"
                  >
                    {STRING_CONSTANTS.DELETE}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </span>
    );
  }

  acceptAll = (event) => {
    event.preventDefault();

    Swal.fire({
      title: `${CONFIRM_TITLE}`,
      text: `${CONFIRM_TXT_ACC_ALL}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `${CONFIRM__ACC_ALL_YES_BUTTON}`,
    }).then((result) => {
      if (result.isConfirmed) {
        const requestUrl = `${SELL_PRODUCT}/approveProductAll/`;
        privateApiCall(requestUrl, "POST", {
          selectedRows: this.state.selectedRows,
        })
          .then((res) => {
            console.log(res,"response data")
            if (res.status==200) {
             
              this.setState({ selectedRows: [], data: "" }, () => {
                this.fetchPendingListData();
              });
              // Show success message
              Swal.fire({
                title: `${SUCC_APPROVE_TITLE}`,
                text: `${SUCC_APPROVE_TEXT}`,
                icon: "success",
                confirmButtonText: `${ALERT_BUTTON_OK}`,
              });
            }
          })
          .catch((error) => {
            // Show error message
            Swal.fire({
              title: `${ERR_ALERT_TITLE}`,
              text: `${ERR_POSTING}`,
              icon: "error",
              confirmButtonText: `${ALERT_BUTTON_OK}`,
            });
          });
      }
    });
  };

  rejectAll = (event) => {
    event.preventDefault();

    Swal.fire({
      title: `${CONFIRM_TITLE}`,
      text: `${CONFIRM_TXT_REJ_ALL}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `${CONFIRM__REJ_ALL_YES_BUTTON}`,
    }).then((result) => {
      if (result.isConfirmed) {
        const requestUrl = `${SELL_PRODUCT}/rejectProductAll/`;
        privateApiCall(requestUrl, "POST", {
          selectedRows: this.state.selectedRows,
        })
          .then((res) => {
            if (res.data.status) {
              this.setState({ selectedRows: [], data: "" }, () => {
                this.fetchPendingListData();
              });

              // Show success message
              Swal.fire({
                title: `${SUCC_REJECTPRODUCT_TITLE}`,
                text: `${SUCC_REJECTPRODUCT_TXT}`,
                icon: "success",
                confirmButtonText: `${ALERT_BUTTON_OK}`,
              });
            }
          })
          .catch((error) => {
            // Show error message
            Swal.fire({
              title: `${ERR_ALERT_TITLE}`,
              text: `${ERR_POSTING}`,
              icon: "error",
              confirmButtonText: `${ALERT_BUTTON_OK}`,
            });
          });
      }
    });
  };

  render() {
    const { data, selectedRows } = this.state;

    return (
      <div id="wrapper">
        <Topbar backgroundColor="#769A0F" />
        <Sidebar type="sellProducts" />
        <div className="content-page">
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box">
                    <h4 className="page-title float-left">
                      {STRING_CONSTANTS.SELL_PROD_PENLIST} ({this.state.data.length})
                    </h4>

                    <ol className="breadcrumb float-right">
                      <li className="breadcrumb-item">
                        {" "}
                        <Link to="/dashboard">
                          {STRING_CONSTANTS.DASHBOARD}
                        </Link>
                      </li>
                      <li className="breadcrumb-item active">
                        {STRING_CONSTANTS.SELL_PRODUCTS}
                      </li>
                      <li className="breadcrumb-item active">
                        {STRING_CONSTANTS.PENDING_LIST}
                      </li>
                    </ol>

                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card-box">
                    <div
                      style={{
                        width: "98%",
                      }}
                    >
                      <div className="row">
                        <div className="box1 ml-2">
                          <div id="toolbar">
                            {this.state.permissions.some(
                              (item) =>
                                item.keyword === "sellProductPendingList" &&
                                item.isApprove === true
                            ) && (
                              <form onSubmit={this.acceptAll}>
                                {this.state.selectedRows.length === 0 ? (
                                  <button
                                    type="submit"
                                    id="button"
                                    style={{
                                      marginBottom: "10px",
                                    }}
                                    className="btn btn-icon waves-effect waves-light btn-info"
                                    disabled
                                  >
                                    {" "}
                                    <i className="fa fa-check"></i>{" "}
                                    {STRING_CONSTANTS.APPROVE}
                                  </button>
                                ) : (
                                  <button
                                    type="submit"
                                    id="button"
                                    style={{
                                      marginBottom: "10px",
                                    }}
                                    className="btn btn-icon waves-effect waves-light btn-info"
                                  >
                                    {" "}
                                    <i className="fa fa-check"></i>{" "}
                                    {STRING_CONSTANTS.APPROVE}
                                  </button>
                                )}
                              </form>
                            )}
                          </div>
                        </div>

                        <div className="box1 ml-2">
                          <div id="toolbar">
                            {this.state.permissions.some(
                              (item) =>
                                item.keyword === "sellProductPendingList" &&
                                item.isReject === true
                            ) && (
                              <form onSubmit={this.rejectAll}>
                                {this.state.selectedRows.length === 0 ? (
                                  <button
                                    id="remove"
                                    type="submit"
                                    style={{
                                      marginBottom: "10px",
                                    }}
                                    className="btn btn-icon waves-effect waves-light btn-danger"
                                    disabled
                                  >
                                    {" "}
                                    <i className="fa fa-times"></i>{" "}
                                    {STRING_CONSTANTS.REJECT}
                                  </button>
                                ) : (
                                  <button
                                    id="remove"
                                    type="submit"
                                    style={{
                                      marginBottom: "10px",
                                    }}
                                    className="btn btn-icon waves-effect waves-light btn-danger"
                                  >
                                    {" "}
                                    <i className="fa fa-times"></i>{" "}
                                    {STRING_CONSTANTS.REJECT}
                                  </button>
                                )}
                              </form>
                            )}
                          </div>
                        </div>
                        <div
                          style={{
                            marginLeft: "auto",
                            width: "200px",
                            marginRight: "40px",
                            position: "relative",
                          }}
                        >
                          {this.state.permissions.some(
                            (item) =>
                              item.keyword === "sellProductPendingList" &&
                              item.isSearch === true
                          ) && (
                            <>
                              <input
                                type="text"
                                placeholder={
                                  STRING_CONSTANTS.SEARCH_PLACEHOLDER
                                }
                                className="form-control"
                                style={{
                                  width: "100%",
                                  paddingLeft: "30px",
                                  boxSizing: "border-box",
                                }}
                                onChange={(e) => {
                                  this.handleSearch(e);
                                  if (e.target.value) {
                                    e.target.nextSibling.style.display = "none";
                                  } else {
                                    e.target.nextSibling.style.display =
                                      "block";
                                  }
                                }}
                              />
                              <i
                                className="fas fa-search"
                                style={{
                                  position: "absolute",
                                  left: "10px",
                                  top: "40%",
                                  transform: "translateY(-50%)",
                                  pointerEvents: "none",
                                  color: "#aaa",
                                }}
                              ></i>
                            </>
                          )}
                        </div>
                      </div>
                      <div style={{ width: "98%" }}>
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell align="left">
                                  <Checkbox
                                    style={{ transform: "scale(0.7)" }}
                                    align="left"
                                    checked={
                                      data.length > 0 &&
                                      selectedRows.length === data.length
                                    }
                                    onChange={(e) => {
                                      const checked = e.target.checked;
                                      const newSelected = checked ? data : [];
                                      this.setState({
                                        selectedRows: newSelected,
                                      });
                                    }}
                                  />
                                </TableCell>
                                <TableCell align="left">
                                  {STRING_CONSTANTS.SN_NO}
                                </TableCell>
                                <TableCell align="left">
                                  {STRING_CONSTANTS.CUSTOMER}
                                </TableCell>
                                <TableCell align="left">
                                  {STRING_CONSTANTS.MOBILE}
                                </TableCell>
                                <TableCell align="left">
                                  {STRING_CONSTANTS.TITLE}
                                </TableCell>
                                <TableCell align="left">
                                  {STRING_CONSTANTS.PRICE}
                                </TableCell>
                                <TableCell align="left">
                                  {STRING_CONSTANTS.ACTIONS}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {Array.isArray(this.state.data) &&
                                this.state.data.slice(
                                  this.state.page * this.state.rowsPerPage,
                                  this.state.page * this.state.rowsPerPage + this.state.rowsPerPage
                                ).map((row, index) => (
                                  <TableRow key={index}>
                                    <TableCell align="left">
                                      <Checkbox
                                        style={{ transform: "scale(0.7)" }}
                                        checked={
                                          selectedRows.indexOf(row) !== -1
                                        }
                                        onChange={(e) => {
                                          this.handleCheckBox(e, row);
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell align="left">
                                      {row.sno}
                                    </TableCell>
                                    <TableCell align="left">
                                      {row.customer}
                                    </TableCell>
                                    <TableCell align="left">
                                      {row.mobile_no}
                                    </TableCell>
                                    <TableCell align="left">
                                      {row.title}
                                    </TableCell>
                                    <TableCell align="left">
                                      {row.price}
                                    </TableCell>

                                    <TableCell align="left">
                                      {this.edit(null, row, null, index)}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              <ImageModal
                                open={this.state.open}
                                handleClose={this.handleClose}
                                imageSrc={this.state.images}
                              />

                              <Dialog
                                open={this.state.open1}
                                onClose={this.handleClose1}
                                fullWidth
                                //disableEnforceFocus
                                maxWidth="lg"
                                aria-labelledby="details-modal-title"
                                aria-describedby="details-modal-description"
                                style={{
                                  maxHeight: "80vh",
                                  overflowY: "auto",
                                  marginTop: "100px",
                                }}
                              >
                                <Grid
                                  container
                                  justifyContent="center"
                                  alignItems="center"
                                  className="modal-content"
                                >
                                  <Grid item xs={12} className="modal-header">
                                    <h5 id="details-modal-title">
                                      {" "}
                                      {STRING_CONSTANTS.PRODUCT_DETAILS}
                                    </h5>
                                    <Button
                                      onClick={this.handleClose1}
                                      color="inherit"
                                    >
                                      {" "}
                                      <span
                                        style={{ fontSize: "20px" }}
                                        aria-hidden="true"
                                      >
                                        &#128473;
                                      </span>
                                    </Button>
                                  </Grid>
                                  <Grid item xs={12} className="modal-body">
                                    <Table className="col-12">
                                      <TableRow>
                                        <TableCell className="text-left">
                                          {STRING_CONSTANTS.CUSTOMER_NAME}
                                        </TableCell>
                                        <TableCell className="text-dark text-right font-weight-normal">
                                          {this.state.customer}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell className="text-left">
                                          {STRING_CONSTANTS.CUSTOMER_MOBILE}
                                        </TableCell>
                                        <TableCell className="text-dark text-right font-weight-normal">
                                          {this.state.mobile_no}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell className="text-left">
                                          {STRING_CONSTANTS.PRODUCT_TITLE}
                                        </TableCell>
                                        <TableCell className="text-dark text-right font-weight-normal">
                                          {this.state.title}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell className="text-left">
                                          {STRING_CONSTANTS.DESCRIPTION}
                                        </TableCell>
                                        <TableCell className="text-dark text-right font-weight-normal">
                                          {this.state.description}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell className="text-left">
                                          {STRING_CONSTANTS.UNIT}
                                        </TableCell>
                                        <TableCell className="text-dark text-right font-weight-normal">
                                          {this.state.unit}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell className="text-left">
                                          {STRING_CONSTANTS.QUANTITY}
                                        </TableCell>
                                        <TableCell className="text-dark text-right font-weight-normal">
                                          {this.state.qty}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell className="text-left">
                                          {STRING_CONSTANTS.PRICE}
                                        </TableCell>
                                        <TableCell className="text-dark text-right font-weight-normal">
                                          {this.state.price}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell className="text-left">
                                          {STRING_CONSTANTS.LOCATION}
                                        </TableCell>
                                        <TableCell className="text-dark text-right font-weight-normal">
                                          {this.state.location}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell className="text-left">
                                          {STRING_CONSTANTS.AVAIL_DATE}
                                        </TableCell>
                                        <TableCell className="text-dark text-right font-weight-normal">
                                          {formatDate(this.state.availableDate)}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell className="text-left">
                                          {STRING_CONSTANTS.EXPIRY_DATE}
                                        </TableCell>
                                        <TableCell className="text-dark text-right font-weight-normal">
                                          {formatDate(this.state.expDate)}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell className="text-left">
                                          {STRING_CONSTANTS.FREE_DELEVERY}
                                        </TableCell>
                                        <TableCell className="text-dark text-right font-weight-normal">
                                          {this.state.freeDelivery}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell className="text-left">
                                          {STRING_CONSTANTS.PRODUCT_GST}
                                        </TableCell>
                                        <TableCell className="text-dark text-right font-weight-normal">
                                          {this.state.gstCategory?.category} {"-"} {this.state.gstCategory?.percentage}{"%"}
                                          <span
                                          onClick={this.handleGstCategoryChange}
                                            style={{
                                              color: "blue",
                                              cursor: "pointer",
                                              marginLeft: "10px",
                                            }}
                                          >
                                            Change
                                          </span>
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell className="text-left">
                                          {STRING_CONSTANTS.CHARGES_AFTER}
                                        </TableCell>
                                        <TableCell className="text-dark text-right font-weight-normal">
                                          {this.state.chargesAfter}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell className="text-left">
                                          {STRING_CONSTANTS.FEATURES}
                                        </TableCell>
                                        <TableCell className="text-dark text-right font-weight-normal">
                                          <div className="row justify-content-end">
                                            {this.state.auction_status ===
                                              "1" && (
                                              <img
                                                className="img-responsive "
                                                src={
                                                  "/assets/icons/bid_icon.png"
                                                }
                                                alt="user"
                                                style={{
                                                  width: "40px",
                                                  height: "40px",
                                                }}
                                              />
                                            )}
                                            {this.state.safe_status === "1" &&
                                              this.state.organic_status ===
                                                "1" && (
                                                <img
                                                  className="img-responsive ml-1"
                                                  src={
                                                    "/assets/icons/organi_1.jpg"
                                                  }
                                                  alt="user"
                                                  style={{
                                                    width: "40px",
                                                    height: "40px",
                                                  }}
                                                />
                                              )}

                                            {this.state.safe_status === "1" &&
                                              this.state.organic_status ===
                                                "0" && (
                                                <img
                                                  className="img-responsive ml-1"
                                                  src={
                                                    "/assets/icons/safe_icon.jpg"
                                                  }
                                                  alt="user"
                                                  style={{
                                                    width: "40px",
                                                    height: "40px",
                                                  }}
                                                />
                                              )}

                                            {this.state.transpot_status ===
                                              "1" && (
                                              <img
                                                className="img-responsive ml-1"
                                                src={"/assets/icons/trucks.png"}
                                                alt="user"
                                                style={{
                                                  width: "40px",
                                                  height: "40px",
                                                }}
                                              />
                                            )}
                                          </div>
                                        </TableCell>
                                      </TableRow>
                                    </Table>
                                  </Grid>
                                  <Grid item xs={12} className="modal-footer">
                                    <Button
                                      onClick={this.handleClose1}
                                      variant="outlined"
                                      color="error"
                                    >
                                      {STRING_CONSTANTS.CLOSE}
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Dialog>
                              <Dialog
                                open={this.state.open2}
                                onClose={this.handleClose2}
                                maxWidth="lg"
                                fullWidth
                                centered
                                disableEnforceFocus
                              >
                                <div
                                  style={{
                                    backgroundColor: "#fff",
                                    padding: "20px",
                                    borderRadius: "8px",
                                  }}
                                >
                                  <h3>{STRING_CONSTANTS.FEEDBACK_LABEL}</h3>
                                  <form onSubmit={this.handleReplay}>
                                    <Grid container spacing={2}>
                                      <Grid item xs={12}>
                                        <div className="text-left text-dark mb-1">
                                          Message
                                        </div>
                                        <TextField
                                          type="text"
                                          fullWidth
                                          multiline
                                          rows={4}
                                          variant="outlined"
                                          value={this.state.replay}
                                          placeholder="Message"
                                          onChange={this.handleChange}
                                          required
                                        />
                                      </Grid>
                                    </Grid>
                                    <Grid
                                      container
                                      justifyContent="flex-end"
                                      style={{ marginTop: "20px" }}
                                    >
                                      <Button
                                        variant="outlined"
                                        color="error"
                                        onClick={this.handleClose2}
                                        style={{ marginRight: "8px" }}
                                      >
                                        {STRING_CONSTANTS.CLOSE}
                                      </Button>
                                      <Button
                                        type="submit"
                                        variant="contained"
                                        style={{
                                          backgroundColor: "#769A0F",
                                          color: "#fff",
                                        }}
                                      >
                                        {STRING_CONSTANTS.SEND}
                                      </Button>
                                    </Grid>
                                  </form>
                                </div>
                              </Dialog>

                              <Dialog
                          open={this.state.open3}
                          onClose={this.handleClose3}
                          aria-labelledby="details-modal-title"
                          aria-describedby="details-modal-description"
                          style={{
                            maxHeight: "80vh",
                            overflowY: "auto",
                            marginTop: "100px",
                          }}
                          fullWidth
                          disableEnforceFocus
                          maxWidth="md"
                        >
                          <Grid container justifyContent="center" alignItems="center" className="modal-content">
                            <Grid item xs={12} className="modal-header">
                              <h5 id="details-modal-title">
                                {STRING_CONSTANTS.CHANGE_GST}
                              </h5>
                              <Button onClick={this.handleClose3} color="inherit">
                                <span style={{ fontSize: "20px" }} aria-hidden="true">
                                  &#128473;
                                </span>
                              </Button>
                            </Grid>

                            <Grid item xs={12} className="modal-body">
                              <select
                                 
                                 onChange={(e) => this.setState({ gstId: e.target.value })}
                                style={{
                                  width: "100%",
                                  padding: "10px",
                                  borderRadius: "5px",
                                  border: "1px solid #ccc",
                                }}
                              >
                                <option value=""> {STRING_CONSTANTS.SELECT_GST_CAT}</option>
                                 {this.state.gstCategoryList && this.state.gstCategoryList.map((cat) => (
                                  <option key={cat._id}  value={cat._id} >
                                    {cat.category} {"- "} {cat.percentage}{"%"}
                                    <input type="hidden" value={cat._id} />
                                  </option>
                                ))}
                              </select>
                            </Grid>

                            <Grid item xs={12} className="modal-footer">
                              <Button 
                          onClick={() => this.handleGSTSubmit()}   variant="contained">
                                {STRING_CONSTANTS.CHANGE}
                              </Button>
                              <Button onClick={this.handleClose3} variant="outlined" color="error">
                                {STRING_CONSTANTS.CLOSE}
                              </Button>
                            </Grid>
                          </Grid>
                        </Dialog>
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                        rowsPerPageOptions={[10, 50, 100]}
                        component="div"
                        count={this.state.data.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page || 0}
                        onPageChange={this.handlePageChange}
                        onRowsPerPageChange={this.handleRowPageChange}
                      />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default pendingList;
