import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Topbar from "../topBar";
import Sidebar from "../sideBar";
import $ from "jquery";
import {
  getOrganisation,
  getOrgFilters,
  deleteOrganisation,
} from "../../actions/adminAction";
import { formatDate } from "../../utils/dateUtils";
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { voucherList } from "../../actions/settingsAction";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "react-tagsinput/react-tagsinput.css";
import Cookies from "universal-cookie";
import { FiEdit2, FiEye } from "react-icons/fi";
import { FaRegThumbsUp, FaRegThumbsDown } from "react-icons/fa";

import {
  Table,
  Typography,
  Button,
  Grid,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  TextField,
  Modal,
  Box,
  Checkbox,
  FormControlLabel,
  TablePagination,
} from "@mui/material";

import {
  ADD_ORG,
  BLOCK,
  EDIT,
  EDIT_ORG,
  UNBLOCK,
  VIEW_DET,
  SEARCH_ORG,
} from "../../constants/url";
import { Tooltip } from "react-tooltip";
import {
  ERR_GETTING,
  ERR_POSTING,
  ERROR,
  ERR,
  CONFIRM,
  SUCC_ALERT_TITLE,
  ALERT_BUTTON_OK,
  ALERT_BUTTON_YES,
  ALERT_ICON_ERR,
  RESTORE_ORG,
  ERR_ALERT_TITLE,
} from "../../constants/alertMessage";
import { privateApiCall } from "../../api/privateApi";
import { STRING_CONSTANTS } from "../../constants/stringConstants";
import IconButton from "@mui/material/IconButton";
const cookies = new Cookies();

const Organisation = () => {
   
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword,setNewPassword]=useState(false)
  const [reshowPassword,setReshowPassword]=useState(false)
   
  const localRole = JSON.parse(localStorage.getItem("role"));
  const permissions =
    localRole && localRole.permissions ? localRole.permissions : "All";
  const [details, setDetails] = useState({});
  const [values, setValues] = useState({
    name: "",
    displayName: "",
    gstin:"",
    state: "",
    place: "",
    district: "",
    pincode: "",
    file: [],
    mobile: "",
    email: "",
    password: "",
    person: "",
    error: "",
    org: [],
    vouchers: [],
    voucher_id: "",
    places: [],
    pincodes: [],
    header: "Add Organisation",
    type: "add",
    selected_place: "All",
    selected_pincode: "All",
    _id: "",
    success: false,
    removed: false,
    totalRecords: "",
    newPassword: "",
    retypeNewPassword: "",
    errorMessage: "",
    mobileError: "",  
    pincodeError: ""  
  });

  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const {
    displayName,
    email,
    password,
    gstin,
    selected_pincode,
    selected_place,
    state,
    place,
    district,
    pincode,
    file,
    mobile,
    person,
    org,
    name,
    _id,
  
    newPassword,
    retypeNewPassword,
  } = values;
  const getFileNameFromUrl = (url) => {
    const lastSlashIndex = url.lastIndexOf("/");
    const lastQuestionMarkIndex = url.indexOf("?");
    if (lastQuestionMarkIndex !== -1) {
      return url.substring(lastSlashIndex + 1, lastQuestionMarkIndex); // Extract filename before the query string
    }
    return url.substring(lastSlashIndex + 1); // Extract filename if no query string
  };
   

  const [orgId, setOrgId] = useState(null);
  const agritech_token = cookies.get("agritech_token");
  const [logo, setLogo] = useState("");
  const [imageURL, setImageURL] = useState("/assets/images/no_image.png");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [show, setShow] = useState(false);
  const [orgCount, setOrgCount] = useState(0);
  const handleClose = () => {
    setOrgId(null);
    setShow(false);
    setShowPasswordFields(false);
    setValues((prevValues) => ({
      ...prevValues,    
      errorMessage: "",  
    }));
    setSelectedFiles([])
    
  };
  const handleShow = () => {
    setOrgId(null);
    setShow(true);
    setImageURL("/assets/images/no_image.png");
  };

  const [open1, setOpen1] = useState(false);
  useEffect(() => {
    loadData();
  },[]);
   
 
  const handleChangePage = (event, newPage) => {
    
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page when changing the number of rows per page
  };
  const loadData = () => {
    voucherList("org")
      .then((vouch) => {
        if (vouch.error) {
          Swal.fire({
            title: `${ERR_GETTING}`,
            icon: `${ALERT_ICON_ERR}`,
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: `${ALERT_BUTTON_OK}`,
          });
        } else {
          getOrgFilters(agritech_token).then((filt) => {
            if (filt.error) {
              Swal.fire({
                title: `${ERR_GETTING}`,
                icon: `${ALERT_ICON_ERR}`,
                allowOutsideClick: false,
                showCancelButton: false,
                confirmButtonText: `${ALERT_BUTTON_OK}`,
              });
            } else {
              getOrganisation(
                agritech_token)
                .then((data) => {
                  console.log(data, "data ");
                  if (data.error) {
                    Swal.fire({
                      title: `${ERR_GETTING}`,
                      icon: `${ALERT_ICON_ERR}`,
                      allowOutsideClick: false,
                      showCancelButton: false,
                      confirmButtonText: `${ALERT_BUTTON_OK}`,
                    });
                  } else {
                    $("#File1").val("");
                    $("#logo").val("");
                    setImageURL("/assets/images/no_image.png");
                    setLogo("");
                    setValues({
                      ...values,
                      vouchers: vouch.voucher,
                      // places: filt.places,
                      // pincodes: filt.pincodes,
                      name: "",
                      logo: "",
                      email: "",
                      file: "",
                      displayName: "",
                      gstin:"",
                      person: "",
                      mobile: "",
                      state: "",
                      district: "",
                      place: "",
                      pincode: "",
                      org: data.org,
                      header: "Add Organisation",
                      _id: "",
                      type: "add",
                     
                    });
                    setOrgCount(data.org.length);
                  }
                })
                .catch(() => {
                  Swal.fire({
                    title: `${ERR_GETTING}`,
                    icon: `${ALERT_ICON_ERR}`,
                    allowOutsideClick: false,
                    showCancelButton: false,
                    confirmButtonText: `${ALERT_BUTTON_OK}`,
                  });
                });
            }
          });
        }
      })
      .catch(() => {
        Swal.fire({
          title: `${ERR_GETTING}`,
          icon: `${ALERT_ICON_ERR}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      });
  };
 
  const handleClose2 = () => {
    setOpen1(false);
  };
  const handleClearData = () => {
    setValues({
      ...values,
      name: "",
      logo: "",
      email: "",
      gstin:"",
      password: "",
      file: "",
      displayName: "",
      person: "",
      mobile: "",
      state: "",
      district: "",
      place: "",
      pincode: "",
    });
  };
  const handleSearch = async (e) => {
    const searchValue = e.target.value;
    try {
      const requestUrl = `${SEARCH_ORG}?query=${searchValue}`;

      const response = await privateApiCall(requestUrl, "GET");
      console.log(response,"response data for search")

      if (response.data && response.data.org) {
        setValues((prevState) => ({
          ...prevState,
          org: response.data.org,
          
        }));
        setPage(0)
        
      } else {
        setValues((prevState) => ({
          ...prevState,
          org: [],
        }));
      }
    } catch (error) {
      Swal.fire({
        title: `${ERR_ALERT_TITLE}`,
        icon: `${ALERT_ICON_ERR}`,
        text: `${ERR_GETTING}`,
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonText: `${ALERT_BUTTON_OK}`,
      });
    }
  };
  const [isGroup, setIsGroup] = React.useState(values.isGroup || false);
  const handleCheckboxChange = (event) => {
    setIsGroup(event.target.checked);
    setValues({ ...values, isGroup: event.target.checked });
  };
  const Actions = ({ cell, row }) => {
    const handleEditShow = (e) => {
      console.log(row, "setted data");
      setOrgId(row._id);
      setShow(true);
      setValues({
        ...values,
        error: false,
        name: row.name,
        displayName: row.displayName,
        gstin:row.gstin,
        pincode: row.pincode,
        state: row.state,
        person: row.person,
        place: row.place,
        mobile: row.mobile,
        district: row.district,
        email: row.email,
        password: row.password,
        logo: row.logo,
        file: row.file,
        isGroup: row.isGroup,
        header: "Edit Organisation",
        type: "edit",
        _id: row._id,
      });
      setIsGroup(row.isGroup);
      setImageURL(row.logo ? row.logo : "/assets/images/no_image.png");
    };
    const handleClick = (e) => {
      if (row.delete) {
        Swal.fire({
          title: `${RESTORE_ORG} ${row.name} ?`,
          text: "",
          icon: "question",
          allowOutsideClick: false,
          confirmButtonText: `${ALERT_BUTTON_YES}`,
          showCancelButton: true,
        })
          .then((result) => {
            if (result.isConfirmed) {
              deleteOrganisation(row._id, false).then((data) => {
                if (data.error) {
                  setValues({ ...values, error: data.error, loading: false });
                } else {
                  loadData();
                }
              });
            }
          })
          .catch(() => {
            Swal.fire({
              title: `${ERR_POSTING}`,
              icon: "error",
              allowOutsideClick: false,
              showCancelButton: false,
              confirmButtonText: `${ALERT_BUTTON_OK}`,
            });
          });
      } else {
        Swal.fire({
          title: `${STRING_CONSTANTS.REMOVE_ORGANISATION} ${row.name} ?`,
          text: "",
          icon: "question",
          allowOutsideClick: false,
          confirmButtonText: `${ALERT_BUTTON_YES}`,
          showCancelButton: true,
        })
          .then((result) => {
            if (result.isConfirmed) {
              deleteOrganisation(row._id, true).then((data) => {
                if (data.error) {
                  setValues({ ...values, error: data.error, loading: false });
                } else {
                  loadData();
                }
              });
            }
          })
          .catch(() => {
            Swal.fire({
              title: `${ERR_POSTING}`,
              icon: "error",
              allowOutsideClick: false,
              showCancelButton: false,
              confirmButtonText: `${ALERT_BUTTON_OK}`,
            });
          });
      }
    };

    const handleView = (e) => {
      setDetails(row);
      setOpen1(true);
    };

    return (
      <span>
        {permissions.some(
          (item) =>
            item.keyword === "profilesOrganisations" && item.isEdit === true
        ) && (
          <i
            className="mr-2"
            title="Edit"
            data-tooltip-id="3"
            data-tooltip-content={`${EDIT}`}
            style={{ fontStyle: "normal" }}
            onClick={handleEditShow}
          >
            <FiEdit2 size="18px" color="#00C5B2" />
            <Tooltip id="3" />
          </i>
        )}
        {row.delete
          ? permissions.some(
              (item) =>
                item.keyword === "profilesOrganisations" &&
                item.isUnblock === true
            ) && (
              <i
                className="me-2"
                onClick={handleClick}
                data-tooltip-id="1"
                data-tooltip-content={`${UNBLOCK}`}
                style={{ fontStyle: "normal" }}
              >
                <FaRegThumbsUp size="18px" color="green" />
                <Tooltip id="1" />
              </i>
            )
          : permissions.some(
              (item) =>
                item.keyword === "profilesOrganisations" &&
                item.isBlock === true
            ) && (
              <i
                className="me-2"
                onClick={handleClick}
                data-tooltip-id="2"
                data-tooltip-content={`${BLOCK}`}
                style={{ fontStyle: "normal" }}
              >
                <FaRegThumbsDown size="18px" color="red" />
                <Tooltip id="2" />
              </i>
            )}

        <i
          className="ml-1"
          data-tooltip-id="4"
          data-tooltip-content={`${VIEW_DET}`}
        >
          {" "}
          <FiEye onClick={handleView} color="#9368f3" size="20" />
        </i>
        <Tooltip id="4" />
      </span>
    );
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    let isValid = true;

    if (!/^(?!0)\d{10}$/.test(mobile)) {
      setValues((prevValues) => ({
        ...prevValues,
        mobileError: `${STRING_CONSTANTS.VAL_MOB}`,
      }));
      isValid = false;  
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        mobileError: "",
      }));
    }
  
    if (pincode.length !== 6) {
      setValues((prevValues) => ({
        ...prevValues,
        pincodeError: `${STRING_CONSTANTS.VAL_PIN}`,
      }));
      isValid = false;  
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        pincodeError: "",
      }));
    }
    if (!/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/.test(values.password)) {
      setValues((prevValues) => ({
          ...prevValues,
          errorMessage: "Password must be at least 6 characters long and contain letters, numbers, and a special character.",
      }));
      isValid = false;
  } else {
      setValues((prevValues) => ({
          ...prevValues,
          errorMessage: "",
      }));
  }
  
   
    if (!isValid) return;
    const formData = new FormData();
    formData.append("name", name);
    formData.append("displayName", displayName);
    formData.append("gstin", gstin);
    formData.append("person", person);
    formData.append("mobile", mobile);
    formData.append("state", state);
    formData.append("district", district);
    formData.append("place", place);
    formData.append("pincode", pincode);
    selectedFiles.forEach((file) => {
      formData.append("file", file);
    });
    formData.append("email", email);
    formData.append("password", password);
    formData.append("logo", logo);
    formData.append("isGroup", isGroup);
    const requestUrl = `${ADD_ORG}?token=${agritech_token}`;
    privateApiCall(requestUrl, "POST", formData)
      .then((res) => {
        if (res.data.status === false) {
          // Email already exists, show error message
         if(res.data.msg==`${STRING_CONSTANTS.EXIST_EMAIL}`){
          Swal.fire({
            title:res.data.msg,
            icon: `${ALERT_ICON_ERR}`,
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: `${ALERT_BUTTON_OK}`,
          });
          handleClose(false);
          loadData();
         }
         else if(res.data.msg==`${STRING_CONSTANTS.EXIST_MOBILE}`){
          Swal.fire({
            title:res.data.msg,
            icon: `${ALERT_ICON_ERR}`,
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: `${ALERT_BUTTON_OK}`,
          });
          handleClose(false);
          loadData();
         }
        } 
        
        else if (res.data.status === true) {
          // Organisation added successfully
          Swal.fire({
            title: res.data.msg,
            text: "",
            icon: "success",
            allowOutsideClick: false,
            confirmButtonText: `${ALERT_BUTTON_OK}`,
            showCancelButton: false,
          });
          handleClose(false);
          loadData();
        }
      })
      .catch((error) => {
        // Handle error if request fails
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `${ALERT_ICON_ERR}`,
          text: `${ERR_POSTING}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      });
  };
  const handleEditSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", name);
    formData.append("displayName", displayName);
    formData.append("gstin", gstin);
    formData.append("person", person);
    formData.append("mobile", mobile);
    formData.append("state", state);
    formData.append("district", district);
    formData.append("place", place);
    formData.append("pincode", pincode);
    selectedFiles.forEach((file) => {
      formData.append("file", file);
    });
    formData.append("email", email);
    // if (password) {
    //   formData.append("password", password);
    // }
    if (showPasswordFields) {
      if (newPassword && newPassword === retypeNewPassword) {
        formData.append("password", newPassword);
      } else {
        // If new passwords do not match, show an error

        return;
      }
    }
    formData.append("logo", logo);
    formData.append("isGroup", values.isGroup);
    const requestUrl = `${EDIT_ORG}?org_id=${_id}`;
    privateApiCall(requestUrl, "POST", formData).then((res) => {
      if (res.data.error) {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `${ALERT_ICON_ERR}`,
          text: `${ERR_GETTING}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      } else {
        Swal.fire({
          title: `${SUCC_ALERT_TITLE}`,
          icon: "success",
          text: res.data.message,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
        handleClose(false);
        loadData();

        setValues({
          ...values,
          name: "",
          displayName: "",
          gstin:"",
          person: "",
          mobile: "",
          state: "",
          district: "",
          place: "",
          pincode: "",
          file: [],
          email: "",
          password: "",
          logo: "",
        });
      }
    });
  };
 
  const [selectedFiles, setSelectedFiles] = useState([]);
  // const onFileChange = (event) => {
  //   const files = Array.from(event.target.files);
  //   setSelectedFiles((prevFiles) => [...prevFiles, ...files]); // Store the selected files in state
  // };
  const MAX_TOTAL_SIZE = 20 * 1024 * 1024; 

const onFileChange = (event) => {
  const files = Array.from(event.target.files);
  console.log(files,"filess")
  const currentTotalSize = selectedFiles.reduce((acc, file) => acc + file.size, 0);
  const newFilesSize = files.reduce((acc, file) => acc + file.size, 0);
  const totalSize = currentTotalSize + newFilesSize;

  if (totalSize > MAX_TOTAL_SIZE) {
    Swal.fire({
      title: "File Size Limit Exceeded",
      text: "Total file size exceeds the 20MB limit. Please select smaller files.",
      icon: "warning",
      confirmButtonText: "OK"
    });
  } else {
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  }
};

  const handleRemoveFile = (indexToRemove) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToRemove)
    );
  };
  const onLogoChange = (e) => {
    setLogo(e.target.files[0]);
    setImageURL(URL.createObjectURL(e.target.files[0]));
  };
  // const handleChange = (name) => (e) => {
  //   console.log(name, "nuk", e.target.value);

  //   setValues({ ...values, error: false, [name]: e.target.value, errorMessage: '' });
  // };
  
  const handleChange = (name) => (e) => {
    const value = e.target.value;
    const strongPasswordPattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
    console.log(strongPasswordPattern.test(value),"valuesss")
    setValues((prevValues) => {
      let errorMessage = "";
      let mobileError="";
      let pincodeError=""
      if ((name === "password" || name === "newPassword") && value && !strongPasswordPattern.test(value)) {
        errorMessage = "Password must be at least 6 characters long and contain letters, numbers, and a special character.";
    }
    if (name === "retypeNewPassword" && prevValues.newPassword && value && prevValues.newPassword !== value) {
        errorMessage = "New password and retyped password do not match.";
    }
      if (name === "mobile") {
        const mobilePattern = /^(?!0)\d{10}$/; // Must be 10 digits and cannot start with 0
        if (value && !mobilePattern.test(value)) {
          mobileError = "Mobile number must be 10 digits";
        } else {
          mobileError = "";  
        }
      }
  
      
      if (name === "pincode") {
        if (value && value.length !== 6) {
          pincodeError = "Pincode must be 6 digits long.";
        } else {
          pincodeError = ""; // Clear the error message if valid
        }
      }

      return {
        ...prevValues,

        [name]: value,
        errorMessage:name==="password"?errorMessage:prevValues.errorMessage,
        mobileError: name === "mobile" ? mobileError: prevValues.mobileError,
        pincodeError: name === "pincode" ? pincodeError : prevValues.pincodeError,
         
      };
    });
  };
 
 
   

  return (
    <div id="wrapper">
      <Topbar backgroundColor="#769A0F" />
      <Sidebar type="profiles" />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        {" "}
                        <Link to="/dashboard">
                          {STRING_CONSTANTS.DASHBOARD}
                        </Link>
                      </li>
                      <li className="breadcrumb-item active">
                        {STRING_CONSTANTS.ORG}
                      </li>
                    </ol>
                  </div>
                  <h4 className="page-title">{STRING_CONSTANTS.ORG} ({orgCount})</h4>
                </div>
              </div>
            </div>
            <div className="card">
              <div
                className="row container-fluid"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {permissions.some(
                  (item) =>
                    item.keyword === "profilesOrganisations" &&
                    item.isCreate === true
                ) && (
                  <Button onClick={handleShow}>
                    <span
                      className="btn btn-icon waves-effect waves-light btn-primary "
                      onClick={(e) => handleClearData()}
                      style={{ textTransform: "none" }}
                    >
                      {" "}
                      <i className="fa fa-users"></i> {STRING_CONSTANTS.ADD_ORG}
                    </span>
                  </Button>
                )}

                <div style={{ position: "relative", marginLeft: "auto" }}>
                  {permissions.some(
                    (item) =>
                      item.keyword === "profilesOrganisations" &&
                      item.isSearch === true
                  ) && (
                    <>
                      <input
                        type="text"
                        placeholder={STRING_CONSTANTS.SEARCH_PLACEHOLDER}
                        className="form-control"
                        style={{
                          width: "200px",
                          paddingLeft: "35px",
                          boxSizing: "border-box",
                          marginLeft: "auto",
                        }}
                        onChange={(e) => {
                          handleSearch(e);
                          if (e.target.value.trim() === "") {
                            e.target.nextElementSibling.style.display =
                              "inline-block";
                          } else {
                            e.target.nextElementSibling.style.display = "none";
                          }
                        }}
                      />
                      <i
                        className="fas fa-search"
                        style={{
                          position: "absolute",
                          left: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          color: "#aaa",
                        }}
                      ></i>
                    </>
                  )}
                </div>
              </div>
              <Modal
                open={show}
                onClose={handleClose}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: "100px",
                }}
              >
                <Box
                  sx={{
                    width: "900px",
                    backgroundColor: "white",
                    padding: "20px",
                    borderRadius: "8px",
                    boxShadow: 24,
                    outline: "none",
                    maxHeight: "80vh",
                    overflowY: "auto",
                  }}
                >
                  <h3 id="add-organisation-modal-title">
                    {orgId
                      ? `${STRING_CONSTANTS.EDIT_ORG}`
                      : `${STRING_CONSTANTS.ADD_ORG}`}
                  </h3>
                  <form onSubmit={orgId ? handleEditSubmit : handleSubmit}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isGroup}
                            onChange={handleCheckboxChange}
                          />
                        }
                        label="Group"
                        sx={{ marginBottom: "16px", marginTop: "20px" }}
                      />
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          label="Name"
                          type="text"
                          value={name}
                          onChange={handleChange("name")}
                          placeholder={STRING_CONSTANTS.NAME_PLACEHOLDER}
                          fullWidth
                          required
                          sx={{ marginBottom: "16px" }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          label="Display Name"
                          type="text"
                          value={displayName}
                          onChange={handleChange("displayName")}
                          placeholder={
                            STRING_CONSTANTS.DISPLAY_NAME_PLACEHOLDER
                          }
                          fullWidth
                          required
                          sx={{ marginBottom: "16px" }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          label="Email ID"
                          type="email"
                          value={email}
                          onChange={handleChange("email")}
                          placeholder={STRING_CONSTANTS.EMAIL_PLACEHOLDER}
                          fullWidth
                          required
                          sx={{ marginBottom: "16px" }}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          label="Contact Person"
                          type="text"
                          value={person}
                          onChange={handleChange("person")}
                          placeholder={
                            STRING_CONSTANTS.CONTACT_PERSON_PLACEHOLDER
                          }
                          fullWidth
                          required
                          sx={{ marginBottom: "16px" }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          label="Mobile Number"
                          type="tel"
                          value={mobile}
                          onChange={handleChange("mobile")}
                          placeholder={STRING_CONSTANTS.MOBILE_NO_PLACEHOLDER}
                          fullWidth
                          required
                          sx={{ marginBottom: "16px" }}
                        />
                          {values.mobileError && <span style={{ color: 'red' }}>{values.mobileError}</span>}
                      </Grid>

                      {orgId ? (
                        <>
                          <Grid item xs={12} sm={6} md={4}>
                            <Button
                              variant="outlined"
                              onClick={() =>
                                setShowPasswordFields(!showPasswordFields)
                              }
                            >
                              {showPasswordFields
                                ? STRING_CONSTANTS.CANCEL_CHANGE_PASSWORD
                                : STRING_CONSTANTS.CHANGE_PASSWORD}
                            </Button>
                          </Grid>
                          {showPasswordFields && (
                            <>
                              <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                  label="Enter New Password"
                                  type={showNewPassword?"text":"password"}
                                  value={newPassword}
                                  onChange={handleChange("newPassword")}
                                  placeholder={
                                    STRING_CONSTANTS.NEW_PASSWORD_PLACEHOLDER
                                  }
                                  fullWidth
                                  required
                                  sx={{ marginBottom: "16px" }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          onClick={()=> setNewPassword(!showNewPassword)}
                                        >
                                          {showNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                  label="Re-enter New Password"
                                  type={reshowPassword?"text":"password"}
                                  value={retypeNewPassword}
                                  onChange={handleChange("retypeNewPassword")}
                                  placeholder={
                                    STRING_CONSTANTS.RETYPE_PASSWORD_PLACEHOLDER
                                  }
                                  fullWidth
                                  required
                                  sx={{ marginBottom: "16px" }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          onClick={()=> setReshowPassword(!reshowPassword)}
                                          
                                        >
                                          {reshowPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                
                                />
                              </Grid>
                              {values.errorMessage && (
                                <Grid item xs={12}>
                                  <Typography variant="body2" color="error">
                                    {values.errorMessage}
                                  </Typography>
                                </Grid>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            label="Password"
                            type={showPassword ? "text" : "password"}
                            value={password}
                            onChange={handleChange("password")}
                            placeholder={STRING_CONSTANTS.PASSWORD_PLACEHOLDER}
                            fullWidth
                            required
                            sx={{ marginBottom: "16px" }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={()=> setShowPassword(!showPassword)}
                                  >
                                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                          {values.errorMessage && (
                                <Grid item xs={12}>
                                  <Typography variant="body2" color="error">
                                    {values.errorMessage}
                                  </Typography>
                                </Grid>
                              )}
                        </Grid>
                      )}
                    </Grid>

                    {/* Remaining form fields */}
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          label="State"
                          type="text"
                          value={state}
                          onChange={handleChange("state")}
                          placeholder={STRING_CONSTANTS.STATE_PLACEHOLDER}
                          fullWidth
                          required
                          sx={{ marginBottom: "16px" }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          label="District"
                          type="text"
                          value={district}
                          onChange={handleChange("district")}
                          placeholder={STRING_CONSTANTS.DISTRICT_PLACEHOLDER}
                          fullWidth
                          required
                          sx={{ marginBottom: "16px" }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          label="Place"
                          type="text"
                          value={place}
                          onChange={handleChange("place")}
                          placeholder={STRING_CONSTANTS.PLACE_PLACEHOLDER}
                          fullWidth
                          required
                          sx={{ marginBottom: "16px" }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          label="Pincode"
                          type="text"
                          value={pincode}
                          onChange={handleChange("pincode")}
                          placeholder={STRING_CONSTANTS.PINCODE_PLACEHOLDER}
                          fullWidth
                          required
                          sx={{ marginBottom: "16px" }}
                        />
                              {values.pincodeError && <span style={{ color: 'red' }}>{values.pincodeError}</span>}
    

                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          label={STRING_CONSTANTS.GSTN}
                          type="text"
                          value={gstin}
                          onChange={handleChange("gstin")}
                          placeholder={STRING_CONSTANTS.GSTN}
                          fullWidth
                          sx={{ marginBottom: "16px" }}
                        />
    

                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <input
                          type="file"
                          id="file"
                          multiple
                          onChange={onFileChange}
                          accept="application/pdf"
                          style={{ marginBottom: "16px" }}
                        />
                        <Typography variant="body2" color="primary">
                          {orgId
                            ? `${STRING_CONSTANTS.UPDATE}`
                            : `${STRING_CONSTANTS.UPLOAD_PDF}`}
                        </Typography>
                        <ul>
                          {file && file.length > 0 ? (
                            file.map((fileUrl, index) => {
                              const fileName = getFileNameFromUrl(fileUrl); // Assuming getFileNameFromUrl function is defined
                              return (
                                <li key={index}>
                                  <a
                                    href={fileUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {fileName}
                                  </a>
                                </li>
                              );
                            })
                          ) : (
                            <Typography variant="body2" color="textSecondary">
                              
                            </Typography>
                          )}
                        </ul>
                        <Grid item xs={12} sm={6} md={4}>
                          {selectedFiles.length > 0 && (
                            <ul>
                              {selectedFiles.map((file, index) => (
                                <li key={index}>
                                  {file.name} ({(file.size / 1024).toFixed(2)}{" "}
                                  KB)
                                </li>
                              ))}
                            </ul>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <input
                          type="file"
                          id="logo"
                          onChange={onLogoChange}
                          style={{ marginBottom: "16px" }}
                        />
                        <Typography variant="body2" color="primary">
                          {orgId
                            ? `${STRING_CONSTANTS.UPDATE}`
                            : `${STRING_CONSTANTS.UPLOAD_IMG}`}
                        </Typography>
                        <img
                          src={imageURL}
                          alt="Logo"
                          className="rounded-circle avatar-xl"
                          style={{ marginBottom: "16px" }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      justifyContent="flex-end"
                      style={{ marginTop: "20px" }}
                    >
                      <Grid item>
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={handleClose}
                        >
                          {STRING_CONSTANTS.CLOSE}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          type="submit"
                          variant="contained"
                          style={{
                            backgroundColor: "#769A0F",
                            color: "#fff",
                          }}
                        >
                          {orgId ? "Update" : "Submit"}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Box>
              </Modal>
              <div
                style={{
                  width: "98%",
                }}
              >
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>{STRING_CONSTANTS.SN_NO}</TableCell>
                        <TableCell>{STRING_CONSTANTS.LOGO}</TableCell>
                        <TableCell>{STRING_CONSTANTS.NAME}</TableCell>
                        <TableCell>{STRING_CONSTANTS.DISPLAY_NAME}</TableCell>
                        <TableCell>{STRING_CONSTANTS.EMAIL}</TableCell>
                        <TableCell>{STRING_CONSTANTS.REG_DATE}</TableCell>
                        <TableCell>{STRING_CONSTANTS.STATUS}</TableCell>
                        <TableCell>{STRING_CONSTANTS.ACTIONS}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {org.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          ).map((org, index) => (
                        <TableRow key={index}>
                          <TableCell>  {page * rowsPerPage + index + 1}</TableCell>
                          <TableCell>
                            <img
                              src={
                                org.logo === ""
                                  ? "/assets/images/no_image.png"
                                  : org.logo
                              }
                              alt="Logo"
                              style={{ maxWidth: 50 }}
                            />
                          </TableCell>
                          <TableCell>{org.name}</TableCell>
                          <TableCell>{org.displayName}</TableCell>
                          <TableCell>{org.email}</TableCell>

                          <TableCell>{formatDate(org.created)}</TableCell>
                          <TableCell>
                            {" "}
                            {org.status === true ? (
                              <span className="btn-sm btn-danger ">
                                {STRING_CONSTANTS.INACTIVE}
                              </span>
                            ) : (
                              <span className="btn-sm btn-success">
                                {STRING_CONSTANTS.ACTIVE}
                              </span>
                            )}{" "}
                          </TableCell>
                          <TableCell>
                            <Actions row={org} />
                          </TableCell>
                        </TableRow>
                      ))}
                      <Dialog
                        open={open1}
                        onClose={handleClose2}
                        aria-labelledby="details-modal-title"
                        aria-describedby="details-modal-description"
                        style={{
                          maxHeight: "80vh",
                          overflowY: "auto",
                          marginTop: "100px",
                        }}
                        fullWidth
                        disableEnforceFocus
                        maxWidth="lg"
                      >
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                          className="modal-content"
                        >
                          <Grid item xs={12} className="modal-header">
                            <h5 id="details-modal-title">
                              {STRING_CONSTANTS.DETAILS}
                            </h5>
                            <Button onClick={handleClose2} color="inherit">
                              {" "}
                              <span
                                style={{ fontSize: "20px" }}
                                aria-hidden="true"
                              >
                                &#128473;
                              </span>
                            </Button>
                          </Grid>
                          <Grid item xs={12} className="modal-body">
                            <Table className="col-12">
                              <TableRow>
                                <TableCell className="text-left">
                                  {STRING_CONSTANTS.PARENT_ORG}
                                </TableCell>
                                <TableCell className="text-dark text-right font-weight-normal">
                                  {details.parent_name}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className="text-left">
                                  {STRING_CONSTANTS.CONTACT_PERSON}
                                </TableCell>
                                <TableCell className="text-dark text-right font-weight-normal">
                                  {details.person}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className="text-left">
                                  {STRING_CONSTANTS.CUSTOMER_MOBILE}
                                </TableCell>
                                <TableCell className="text-dark text-right font-weight-normal">
                                  {details.mobile}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className="text-left">
                                  {STRING_CONSTANTS.STATE}
                                </TableCell>
                                <TableCell className="text-dark text-right font-weight-normal">
                                  {details.state}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className="text-left">
                                  {STRING_CONSTANTS.DISTRICT}
                                </TableCell>
                                <TableCell className="text-dark text-right font-weight-normal">
                                  {details.district}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className="text-left">
                                  {STRING_CONSTANTS.PLACE}
                                </TableCell>
                                <TableCell className="text-dark text-right font-weight-normal">
                                  {details.place}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className="text-left">
                                  {STRING_CONSTANTS.PINCODE}
                                </TableCell>
                                <TableCell className="text-dark text-right font-weight-normal">
                                  {details.pincode}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className="text-left">
                                  GSTN
                                </TableCell>
                                <TableCell className="text-dark text-right font-weight-normal">
                                  {details.gstin}
                                </TableCell>
                              </TableRow>
                            </Table>
                          </Grid>
                          <Grid item xs={12} className="modal-footer">
                            <Button
                              onClick={handleClose2}
                              variant="outlined"
                              color="error"
                            >
                              {STRING_CONSTANTS.CLOSE}
                            </Button>
                          </Grid>
                        </Grid>
                      </Dialog>
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 50, 100]}
                  component="div"
                  count={org.length} // Total number of records
                  rowsPerPage={rowsPerPage||0} // Rows per page
                  page={page||0} // Zero-based index for the current page
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Organisation;
