import React, { useState, useEffect } from "react";
import Topbar from "./topBar";
import Sidebar from "./sideBar";
import { Link } from "react-router-dom";
import { STRING_CONSTANTS } from "../../constants/stringConstants";
import { GOOGLE_MAP_API_KEY, RAZORPAY_API_KEY } from "../../constants/apiKey";
import { GoogleMap, Polygon, useJsApiLoader } from "@react-google-maps/api";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import {
  TableContainer,
  Button,
  Modal,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { RATE_PER_ACRE } from "../../constants/satFarm";
import { privateApiCall } from "../../api/privateApi";

const Marketplace = () => {
 
  const [showModal, setShowModal] = useState(false);
  const [acre, setAcre] = useState("");
  const [amount, setAmount] = useState("");
  const [error, setError] = useState("");
  const ratePerAcre = RATE_PER_ACRE;
  const minimumAcre = 0.3;
  console.log(RAZORPAY_API_KEY, "key ");
  const handleAcreChange = (e) => {
    const value = e.target.value;
    setAcre(value);
    if (isNaN(value) || value.trim() === "" || parseFloat(value) < 0) {
      setError("Invalid acre value");
      setAmount("");
      return;
    }
    const numericValue = parseFloat(value);
    console.log(numericValue,"numeric value")
    console.log(ratePerAcre,"RATE PER ACE")
    if (numericValue < minimumAcre) {
      setError(`Acre value must be at least ${minimumAcre}`);
      setAmount("");
    } else {
      setError("");
      setAmount((numericValue * ratePerAcre));
    }
  };

  
  const handleBuy = () => {
    setShowModal(true);
    setError("");
  };
  const handleModalClose = () => {
  
    setShowModal(false);
    setAmount("");
    setAcre("");
    setError("");
  };
  const payAcre =async (e) => {
    e.preventDefault();
    const createOrderResponse = await privateApiCall(
      "/api/user/start_order",
      "POST",{amount:amount});
      console.log(createOrderResponse,"**")
      if(createOrderResponse.data.success===true){
        const amountInPaise = parseInt(amount) * 100;
        const { order_id } = createOrderResponse.data;
        var options = {
          key: RAZORPAY_API_KEY,
          amount: amountInPaise,
          name: "ANH",
          currency: "INR",
          description: "Acre Purchase",
          order_id: order_id,
          handler:async function (response) {
            // Step 3: Handle the payment success response
            console.log("Payment Successful:", response);
            console.log("Razorpay Payment ID:", response.razorpay_payment_id);
            console.log("Razorpay Order ID:", response.razorpay_order_id);
            console.log("Razorpay Signature:", response.razorpay_signature);
            try {
              const createPayment=await privateApiCall("/api/organisation/create_payment","POST",{
                payment_id:response.razorpay_payment_id,
                amount:amount,
                 purpose: `${acre}Acre purchase`
              })
              console.log(createPayment,"response of create payment")
            } catch (error) {
              
            }
          },
        };
        var pay = new window.Razorpay(options);
        pay.open();

      }

    setShowModal(false);
  };

  return (
    <div id="wrapper">
      <Topbar backgroundColor="#0baae5" />
      <Sidebar />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="page-title float-left">
                    {STRING_CONSTANTS.SATFARM}
                  </h4>
                  <ol className="breadcrumb float-right">
                    <li className="breadcrumb-item">
                      <Link to="/organisation/dashboard">
                        {STRING_CONSTANTS.DASHBOARD}
                      </Link>
                    </li>
                    <li className="breadcrumb-item active">
                      {STRING_CONSTANTS.SATFARM}
                    </li>
                  </ol>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card-box">
                  <div className="row mb-2">
                    <div style={{ width: "98%" }}>
                      <div>
                       
                        <Modal
                          open={showModal}
                          onClose={() => handleModalClose()}
                          aria-labelledby="simple-modal-title"
                          aria-describedby="simple-modal-description"
                        >
                          <div
                            style={{
                              padding: "20px",
                              background: "white",
                              width: "30%",
                              margin: "100px auto",
                              position: "absolute",
                              top: "40%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            <form>
                              <FormControl fullWidth sx={{ mb: 3 }}>
                                <TextField
                                  label="Acre"
                                  type="text"
                                  placeholder="Enter Acre"
                                  value={acre}
                                  onChange={handleAcreChange}
                                  required
                                  style={{ marginTop: "20px" }}
                                  helperText={
                                    error && (
                                      <Typography color="error">
                                        {error}
                                      </Typography>
                                    )
                                  }
                                  error={!!error}
                                />
                              </FormControl>
                              <FormControl fullWidth sx={{ mb: 3 }}>
                                <TextField
                                  label="Amount"
                                  type="text"
                                  value={amount}
                                  readOnly
                                  required
                                  style={{ marginTop: "20px" }}
                                />
                              </FormControl>

                              <Grid
                                container
                                justifyContent="flex-end"
                                sx={{ mt: 3 }}
                              >
                                <Button
                                  variant="outlined"
                                  color="error"
                                  onClick={() => handleModalClose()}
                                  sx={{ mt: 2, mr: 1 }}
                                >
                                  {STRING_CONSTANTS.CLOSE}
                                </Button>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="success"
                                  sx={{ mt: 2 }}
                                  style={{ backgroundColor: "#0baae5" }}
                                  onClick={payAcre}
                                >
                                  Pay
                                </Button>
                              </Grid>
                            </form>
                          </div>
                        </Modal>
                        <p>Total Purchased Acres: 10acre <Button
                          sx={{ marginBottom: "10px" ,marginLeft:"3px"}}
                          variant="contained"
                          onClick={() => handleBuy()}
                        >
                          {STRING_CONSTANTS.BUY_ACRE}
                        </Button></p>
                        <p>Total Acres Used by Users: 5acre</p>
                        <p>Balanced Acres: 5acre</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Marketplace;
