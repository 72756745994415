import React, { useState, useEffect } from "react";
import Topbar from "./topBar";
import Sidebar from "./sideBar";

import { API as baseUrl } from "../config";

import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import {
  getSupplierInvoices,
  getSellerInvoices,
  handlePostingComment,
  getInvoiceCommentsList,
  handlePostVerifyStatus,
} from "../actions/adminAction";
import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Dialog,
  Grid,
  Button,
  CircularProgress,
  TextField,
  Box,
  Tab,
  Tabs,
  Typography,
  Paper,
} from "@mui/material";
import {
  ALERT_BUTTON_OK,
  CONFIRM__ACC_ALL_YES_BUTTON,
  CONFIRM_TITLE,
  CONFIRM_TXT_ACC_ALL,
  ERR_ALERT_TITLE,
  ERR_GETTING,
  WARNING,
} from "../constants/alertMessage";
import { privateApiCall } from "../api/privateApi";
import { STRING_CONSTANTS } from "../constants/stringConstants";
import { formatDate } from "../utils/dateUtils";

import { FiCheckCircle, FiEye, FiXCircle } from "react-icons/fi"; // Eye icon from react-icons
import {
  AiOutlineComment,
  AiOutlineEdit,
  AiOutlineFilePdf,
} from "react-icons/ai";

import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { APPROVE, REJECT, VIEW_DET, VIEW_PDF } from "../constants/url";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";

const Invoices = () => {
  const [selectedInvoiceType, setSelectedInvoiceType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [detailedInfo, setDetailedInfo] = useState({});
  const [hsnCodes, setHsnCodes] = useState([]);
  const [open1, setOpen1] = useState(false);
  const [tax, setTax] = useState({
    IGST: "0",
    CGST: "0",
    IGST: "0",
  });
  const [loading, setLoading] = useState(false);
  const [commentsList, setCommentsList] = useState([]);
  const [value, setValue] = useState("1");
  const [openCommentModal, setOpenCommentModal] = useState(false);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);
  const [comment, setComment] = useState("");

  const localRole = JSON.parse(localStorage.getItem("role"));
  const permissions =
    localRole && localRole.permissions ? localRole.permissions : "All";
  const [values, setValues] = useState({
    error: "",
    invoices: [],
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    loadInvoices();
  }, []);

  useEffect(() => {
    if (searchValue || startDate || endDate) {
      handleSearch();
    } else if (selectedInvoiceType) {
      handleInvoiceTypeChange(selectedInvoiceType);
    } else {
      loadInvoices();
    }
  }, [startDate, endDate, searchValue]);

  const handleChangeStart = (date) => {
    setStartDate(date ? dayjs(date).format("MM/DD/YYYY") : "");
  };
  const handleChangeEnd = (date) => {
    setEndDate(date ? dayjs(date).format("MM/DD/YYYY") : "");
  };

  const handleInvoiceTypeChange = (value) => {
    setSearchValue("");

    setSelectedInvoiceType(value);
    // You can add additional functionality here, such as triggering an API call or filtering data based on the selected type

    if (value === "seller") {
      getSellerInvoices()
        .then((response) => {
          if (response && response.status !== undefined && response.invoices) {
            if (response.status) {
              setValues({ ...values, invoices: response.invoices });
            } else {
              Swal.fire({
                title: `${ERR_ALERT_TITLE}`,
                icon: `${ERR_GETTING}`,
                allowOutsideClick: false,
                showCancelButton: false,
                confirmButtonText: `${ALERT_BUTTON_OK}`,
              });
            }
          } else {
            Swal.fire({
              title: `${ERR_ALERT_TITLE}`,
              icon: `${ERR_GETTING}`,
              allowOutsideClick: false,
              showCancelButton: false,
              confirmButtonText: `${ALERT_BUTTON_OK}`,
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            title: `${ERR_ALERT_TITLE}`,
            icon: `${ERR_GETTING}`,
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: `${ALERT_BUTTON_OK}`,
          });
        });
    } else if (value === "supplier") {
      getSupplierInvoices()
        .then((response) => {
          if (response && response.status !== undefined && response.invoices) {
            if (response.status) {
              setValues({ ...values, invoices: response.invoices });
            } else {
              Swal.fire({
                title: `${ERR_ALERT_TITLE}`,
                icon: `${ERR_GETTING}`,
                allowOutsideClick: false,
                showCancelButton: false,
                confirmButtonText: `${ALERT_BUTTON_OK}`,
              });
            }
          } else {
            Swal.fire({
              title: `${ERR_ALERT_TITLE}`,
              icon: `${ERR_GETTING}`,
              allowOutsideClick: false,
              showCancelButton: false,
              confirmButtonText: `${ALERT_BUTTON_OK}`,
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            title: `${ERR_ALERT_TITLE}`,
            icon: `${ERR_GETTING}`,
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: `${ALERT_BUTTON_OK}`,
          });
        });
    }
  };

  const handleChanges = (event, newValue) => {
    event.preventDefault();
    const type = newValue === "1" ? "seller" : "supplier";
    handleInvoiceTypeChange(type);
    setValue(newValue); // Update the value state to reflect the selected tab
  };

  const handleVerifyStatus = (status, invoice_id) => {
    const text = status === 1 ? "Verify" : "Uncheck";
    Swal.fire({
      title: `${CONFIRM_TITLE}`,
      text: ` ${STRING_CONSTANTS.ARE_YOUR_SURE} ${text} ${STRING_CONSTANTS.THIS_INVOICE}`,
      icon: `${WARNING}`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `${STRING_CONSTANTS.YES_PROCEED}`,
    }).then((result) => {
      if (result.isConfirmed) {
        handlePostVerifyStatus(status, invoice_id).then(() => {
          getSellerInvoices()
            .then((response) => {
              if (
                response &&
                response.status !== undefined &&
                response.invoices
              ) {
                if (response.status) {
                  setValues({ ...values, invoices: response.invoices });
                } else {
                  Swal.fire({
                    title: `${ERR_ALERT_TITLE}`,
                    icon: `${ERR_GETTING}`,
                    allowOutsideClick: false,
                    showCancelButton: false,
                    confirmButtonText: `${ALERT_BUTTON_OK}`,
                  });
                }
              } else {
                Swal.fire({
                  title: `${ERR_ALERT_TITLE}`,
                  icon: `${ERR_GETTING}`,
                  allowOutsideClick: false,
                  showCancelButton: false,
                  confirmButtonText: `${ALERT_BUTTON_OK}`,
                });
              }
            })
            .catch((err) => {
              Swal.fire({
                title: `${ERR_ALERT_TITLE}`,
                icon: `${ERR_GETTING}`,
                allowOutsideClick: false,
                showCancelButton: false,
                confirmButtonText: `${ALERT_BUTTON_OK}`,
              });
            });
        });
      }
    });
  };

  const handleViewDetails = async (id) => {
    const viewDetailsInfo = values.invoices.filter((item) => {
      return item._id === id;
    });
    setDetailedInfo(...viewDetailsInfo);

    let data = { ...viewDetailsInfo };
    data = data[0].items;

    const hsnCodesArray = data.map((item) => item?.hsnCode || "") || [];
    // // Set the HSN codes in state
    setHsnCodes(hsnCodesArray);

    const taxes = data.map((item) => item?.taxes || []);
    let cgst = 0;
    let igst = 0;
    let sgst = 0;

    // Iterate through each tax array
    taxes.forEach((taxArray) => {
      taxArray.forEach((tax) => {
        if (tax.taxType === "CGST") {
          cgst += tax.taxAmount;
        } else if (tax.taxType === "SGST") {
          sgst += tax.taxAmount;
        } else if (tax.taxType === "IGST") {
          igst += tax.taxAmount;
        }
      });
    });

    console.log("CGST Total:", cgst);
    console.log("SGST Total:", sgst);
    console.log("IGST Total:", igst);

    setTax({
      CGST: cgst.toFixed(2), // Round to 2 decimal places
      SGST: sgst.toFixed(2),
      IGST: igst.toFixed(2),
    });

    setOpen1(true);
  };

  const downloadPdf = async (id) => {
    const requestUrl = `${baseUrl}/api/payments/getPdf1?invoice_id=${id}`;
    const response = await axios.get(requestUrl, {
      responseType: "blob", // Ensure the response is handled as a blob
    });

    const blob = response.data; // Get the response data (which is the Blob)
    const url = URL.createObjectURL(blob); // Create a download URL for the Blob

    // Open the URL in a new tab
    const newTab = window.open();

    if (newTab) {
      newTab.document.location = url; // Set the new tab's location to the PDF Blob URL
    } else {
      console.error(
        "Unable to open a new tab. Please check your browser settings."
      );
    }

    // const blob = await response.blob(); // Get the response as a Blob
    // const link = document.createElement('a');
    // link.href = URL.createObjectURL(blob); // Create a download URL for the Blob
    // link.download = 'invoice.pdf'; // Set the download file name
    // link.click();
  };

  const handleAddComment = async (invoiceId,type) => {
    getInvoiceComments(invoiceId);
    handleInvoiceTypeChange(type)
    


      setSelectedInvoiceId(invoiceId); // Set the current invoice ID
      const selectedInvoice = values.invoices.find(
        (invoice) => invoice._id === invoiceId
      );

      if (selectedInvoice) {
        setSelectedInvoiceId(selectedInvoice.invoiceNumber);
        // Assuming the comments are stored in the `comments` field of the invoice object
        const comments = selectedInvoice.comment || []; // Fallback to an empty array if no comments
        setCommentsList(comments);
        // Set the comments to the state or update UI with the filtered comments
        // Example: setCommentsList(comments);
      }

      setOpenCommentModal(true);
    
  };

  const handleSaveComment = async () => {
    if (!comment || !comment.trim()) return;
    const userName = localStorage.getItem("userNameInfo");
    const data = {
      name: userName,
      invoiceId: selectedInvoiceId,
      comment: comment,
    };
    handlePostingComment(data);
    setComment(null);

    handleCloseCommentModal();
  };
  const handleCloseCommentModal = () => {
    setOpenCommentModal(false); // Close the modal
    setSelectedInvoiceId(null); // Reset the invoice ID
  };

  const loadInvoices = () => {
    getSellerInvoices()
      .then((response) => {
        if (response && response.status !== undefined && response.invoices) {
          if (response.status) {
            setValues({ ...values, invoices: response.invoices });
          } else {
            Swal.fire({
              title: `${ERR_ALERT_TITLE}`,
              icon: `${ERR_GETTING}`,
              allowOutsideClick: false,
              showCancelButton: false,
              confirmButtonText: `${ALERT_BUTTON_OK}`,
            });
          }
        } else {
          Swal.fire({
            title: `${ERR_ALERT_TITLE}`,
            icon: `${ERR_GETTING}`,
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: `${ALERT_BUTTON_OK}`,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `${ERR_GETTING}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      });
    return { status: true };
  };

  const getInvoiceComments = (invoiceId) => {
    getInvoiceCommentsList(invoiceId)
      .then((response) => {
        if (response.success) {
          setCommentsList(response.data);
        } else {
          Swal.fire({
            title: `${ERR_ALERT_TITLE}`,
            icon: `${ERR_GETTING}`,
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: `${ALERT_BUTTON_OK}`,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: `${ERR_ALERT_TITLE}`,
          icon: `${ERR_GETTING}`,
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: `${ALERT_BUTTON_OK}`,
        });
      });
  };
  const handleSearch = async () => {
    try {
      const requestUrl = `/app/searchInvoice?&query=${searchValue}&startDate=${startDate}&endDate=${endDate}&type=${selectedInvoiceType}`;
      const response = await privateApiCall(requestUrl, "GET");

      if (response.data.invoice) {
        setValues({ ...values, invoices: response.data.invoice });
        setPage(0);
      } else {
        setValues({
          ...values,
          invoices: [],
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClose1 = () => {
    setOpen1(false);
    setHsnCodes([]);
  };

  return (
    <div id="wrapper">
      <Topbar backgroundColor="#769A0F" />
      <Sidebar />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">
                          {STRING_CONSTANTS.DASHBOARD}
                        </Link>
                      </li>
                      <li className="breadcrumb-item active">
                        {STRING_CONSTANTS.INVOICES}
                      </li>
                    </ol>
                  </div>
                  <h4 className="page-title">
                    {STRING_CONSTANTS.INVOICES} ({values.invoices.length})
                  </h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card-box">
                  <div style={{ width: "98%" }}>
                    <div
                      className="row container-fluid"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {permissions.some(
                        (item) =>
                          item.keyword === "invoices" &&
                          item.isDateSearch === true
                      ) && (
                        <>
                          <div style={{ paddingRight: "16px" }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DatePicker"]}>
                                <DatePicker
                                  label="Start Date&nbsp;&nbsp;&nbsp;"
                                  format="DD/MM/YYYY"
                                  defaultValue={dayjs(startDate)}
                                  name="startDate"
                                  onChange={handleChangeStart}
                                  required
                                  slotProps={{
                                    textField: {
                                      error:
                                        !!startDate &&
                                        !dayjs(startDate).isValid(),
                                      sx: {
                                        width: "160px", // Adjust width
                                        "& .MuiInputBase-root": {
                                          fontSize: "11px", // Adjust font size
                                          padding: "-4px", // Adjust padding
                                        },
                                        "& .MuiInputLabel-root": {
                                          fontSize: "14px", // Adjust label size
                                        },
                                      },
                                    },
                                  }}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </div>

                          <div>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DatePicker"]}>
                                <DatePicker
                                  label="End Date&nbsp;&nbsp;&nbsp;"
                                  format="DD/MM/YYYY"
                                  defaultValue={dayjs(endDate)}
                                  name="endDate"
                                  onChange={handleChangeEnd}
                                  required
                                  // You can control the error state with this condition
                                  slotProps={{
                                    textField: {
                                      error:
                                        !!endDate && !dayjs(endDate).isValid(),
                                      sx: {
                                        width: "160px", // Adjust width
                                        "& .MuiInputBase-root": {
                                          fontSize: "11px", // Adjust font size
                                          padding: "-4px", // Adjust padding
                                        },
                                        "& .MuiInputLabel-root": {
                                          fontSize: "14px", // Adjust label size
                                        },
                                      },
                                    },
                                  }}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </div>
                        </>
                      )}
                      {/* <div
                        style={{
                          position: "relative",
                          marginLeft: "auto",
                        }}
                      >
                        {permissions.some(
                          (item) =>
                            item.keyword === "payment" && item.isSearch === true
                        ) && (
                          <>
                            <select
                              className="form-control"
                              style={{
                                width: "200px",
                                paddingLeft: "20px",
                                boxSizing: "border-box",
                                marginLeft: "auto",
                              }}
                              onChange={(e) =>
                                handleInvoiceTypeChange(e.target.value)
                              }
                            >
                              <option value="" disabled selected>
                                Select Invoice Type
                              </option>
                              <option value="seller">Seller Invoice</option>
                              <option value="supplier">Supplier Invoice</option>
                            </select>
                            <i
                              className="fas fa-file-invoice"
                              style={{
                                position: "absolute",
                                left: "10px",
                                top: "50%",
                                transform: "translateY(-50%)",
                                color: "#aaa",
                              }}
                            ></i>
                          </>
                        )}
                      </div> */}

                      <div
                        style={{
                          position: "relative",
                          marginLeft: "auto",
                        }}
                      >
                        {permissions.some(
                          (item) =>
                            item.keyword === "invoices" && item.isSearch === true
                        ) && (
                          <>
                            <input
                              type="text"
                              placeholder={STRING_CONSTANTS.SEARCH_PLACEHOLDER}
                              className="form-control"
                              value={searchValue}
                              style={{
                                width: "200px",
                                paddingLeft: "35px",
                                boxSizing: "border-box",
                                marginLeft: "auto",
                              }}
                              onChange={(e) => setSearchValue(e.target.value)}
                            />
                            <i
                              className="fas fa-search"
                              style={{
                                position: "absolute",
                                left: "10px",
                                top: "50%",
                                transform: "translateY(-50%)",
                                color: "#aaa",
                              }}
                            ></i>
                          </>
                        )}
                      </div>
                    </div>

                    <TabContext value={value}>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <TabList
                          onChange={handleChanges}
                          aria-label="lab API tabs example"
                        >
                          <Tab label="Seller Invoice" value="1" />
                          {permissions.some(
                        (item) =>
                          item.keyword === "invoices" &&
                          item.isEdit === true
                      ) && (
                    
                          <Tab label="Purchase Invoice" value="2" />
                      )}
                        </TabList>
                      </Box>
                      <TabPanel value="1">
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>{STRING_CONSTANTS.SN_NO}</TableCell>
                                <TableCell>
                                  {STRING_CONSTANTS.INVOICE_NO}
                                </TableCell>

                                <TableCell>
                                  {STRING_CONSTANTS.CUSTOMER_MOBILE}
                                </TableCell>
                                <TableCell>{STRING_CONSTANTS.AMOUNT}</TableCell>
                                <TableCell>
                                  {STRING_CONSTANTS.TAX_AMOUNT}
                                </TableCell>
                                <TableCell>{STRING_CONSTANTS.DATE}</TableCell>
                                <TableCell>
                                  {STRING_CONSTANTS.ACTIONS}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {loading ? (
                                <TableRow>
                                  <TableCell
                                    colSpan={10}
                                    style={{ textAlign: "center" }}
                                  >
                                    <CircularProgress />
                                  </TableCell>
                                </TableRow>
                              ) : values.invoices.length === 0 ? (
                                <TableRow>
                                  <TableCell
                                    colSpan={10}
                                    style={{ textAlign: "center" }}
                                  >
                                    No data found
                                  </TableCell>
                                </TableRow>
                              ) : (
                                values.invoices
                                  .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                  )
                                  .map((invoice, index) => (
                                    <TableRow key={invoice._id}>
                                      <TableCell>
                                        {page * rowsPerPage + index + 1}
                                      </TableCell>
                                      <TableCell>
                                        {invoice.invoiceNumber}
                                      </TableCell>

                                      <TableCell>
                                        {invoice?.customerMobile ||
                                          invoice?.buyerMobile}
                                      </TableCell>
                                      <TableCell>
                                        {invoice.totals.totalAmount}
                                      </TableCell>
                                      <TableCell>
                                        {invoice.totals.totalTax}
                                      </TableCell>
                                      <TableCell>
                                        {formatDate(
                                          invoice.orderDetails.orderDate
                                        )}
                                      </TableCell>
                                      <TableCell align="center">
                                        <IconButton
                                          onClick={() =>
                                            handleViewDetails(invoice._id)
                                          }
                                        >
                                          <i
                                            className="ml-1"
                                            data-tooltip-id="1"
                                            data-tooltip-content={`${VIEW_DET}`}
                                            style={{ fontStyle: "normal" }}
                                          >
                                            {" "}
                                            <FiEye size="20" color="#9368f3" />
                                            <Tooltip
                                              id="1"
                                              style={{ fontSize: "12px" }}
                                            />
                                          </i>
                                        </IconButton>

                                        <IconButton
                                          onClick={() =>
                                            downloadPdf(invoice.invoiceNumber)
                                          }
                                        >
                                          <i
                                            className="ml-1"
                                            data-tooltip-id="1"
                                            data-tooltip-content={`${VIEW_PDF}`}
                                            style={{ fontStyle: "normal" }}
                                          >
                                            {" "}
                                            <AiOutlineFilePdf
                                              size="20"
                                              color="#9368f3"
                                            />
                                            <Tooltip
                                              id="1"
                                              style={{ fontSize: "12px" }}
                                            />
                                          </i>
                                        </IconButton>
                                        <IconButton
                                          onClick={() =>
                                            handleAddComment(invoice._id,"seller")
                                          }
                                        >
                                          <i
                                            data-tooltip-id="2"
                                            data-tooltip-content="Add Comment"
                                            style={{ fontStyle: "normal" }}
                                          >
                                            <AiOutlineEdit
                                              size="20"
                                              color="#4CAF50"
                                            />
                                            <Tooltip
                                              id="2"
                                              style={{ fontSize: "12px" }}
                                            />
                                          </i>
                                        </IconButton>
                                        <IconButton>
                                          <>
                                            {invoice.verified === "true" ? (
                                              <i
                                                data-tooltip-id="2"
                                                data-tooltip-content={`Verified`}
                                              >
                                                <FiCheckCircle
                                                  onClick={() => {
                                                    handleVerifyStatus(
                                                      2,
                                                      invoice._id
                                                    ); // Handle reject logic here
                                                  }}
                                                  color="#4fbde9"
                                                  size="20"
                                                />
                                              </i>
                                            ) : (
                                              <i
                                                data-tooltip-id="1"
                                                data-tooltip-content={`Not verified`}
                                              >
                                                <FiCheckCircle
                                                  onClick={() => {
                                                    handleVerifyStatus(
                                                      1,
                                                      invoice._id
                                                    ); // Handle approve logic here
                                                  }}
                                                  color="yellow"
                                                  size="20"
                                                />
                                              </i>
                                            )}
                                          </>
                                        </IconButton>
                                      </TableCell>
                                    </TableRow>
                                  ))
                              )}
                              <Dialog
                                open={open1}
                                onClose={handleClose1}
                                aria-labelledby="details-modal-title"
                                aria-describedby="details-modal-description"
                                style={{
                                  maxHeight: "80vh",
                                  overflowY: "auto",
                                  marginTop: "100px",
                                }}
                                fullWidth
                                disableEnforceFocus
                                maxWidth="lg"
                              >
                                <Grid
                                  container
                                  justifyContent="center"
                                  alignItems="center"
                                  className="modal-content"
                                >
                                  <Grid item xs={12} className="modal-header">
                                    <h5 id="details-modal-title">
                                      {STRING_CONSTANTS.DETAILS}
                                    </h5>
                                    <Button
                                      onClick={handleClose1}
                                      color="inherit"
                                    >
                                      <span
                                        style={{ fontSize: "20px" }}
                                        aria-hidden="true"
                                      >
                                        &#128473;
                                      </span>
                                    </Button>
                                  </Grid>
                                  <Grid item xs={12} className="modal-body">
                                    <Table className="col-12">
                                      <TableRow>
                                        <TableCell className="text-left">
                                          {STRING_CONSTANTS.BILLING_ADD}
                                        </TableCell>
                                        <TableCell className="text-dark text-right font-weight-normal">
                                          {detailedInfo.billingAddress
                                            ?.address1 || ""}
                                          <div>
                                            {detailedInfo.billingAddress
                                              ?.address2 || ""}
                                          </div>
                                          <div>
                                            {detailedInfo.billingAddress
                                              ?.landMark || ""}
                                          </div>
                                          <div>
                                            {detailedInfo.billingAddress
                                              ?.state || ""}
                                          </div>
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell className="text-left">
                                          {STRING_CONSTANTS.SHIPPING_ADDRESS}
                                        </TableCell>
                                        <TableCell className="text-dark text-right font-weight-normal">
                                          {detailedInfo.shippingAddress
                                            ?.address1 || ""}
                                          <div>
                                            {detailedInfo.shippingAddress
                                              ?.address2 || ""}
                                          </div>
                                          <div>
                                            {detailedInfo.shippingAddress
                                              ?.landMark || ""}
                                          </div>
                                          <div>
                                            {detailedInfo.shippingAddress
                                              ?.state || ""}
                                          </div>
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell className="text-left">
                                          {STRING_CONSTANTS.SOLD_BY}
                                        </TableCell>
                                        <TableCell className="text-dark text-right font-weight-normal">
                                          {detailedInfo.soldBy?.companyName ||
                                            ""}
                                          <div>
                                            {" "}
                                            {detailedInfo.soldBy?.address || ""}
                                          </div>
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell className="text-left">
                                          {STRING_CONSTANTS.ORD_NO}
                                        </TableCell>
                                        <TableCell className="text-dark text-right font-weight-normal">
                                          {detailedInfo.orderDetails
                                            ?.orderNumber || ""}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell className="text-left">
                                          {STRING_CONSTANTS.IGST}
                                        </TableCell>
                                        <TableCell className="text-dark text-right font-weight-normal">
                                          {tax.IGST}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell className="text-left">
                                          {STRING_CONSTANTS.CGST}
                                        </TableCell>
                                        <TableCell className="text-dark text-right font-weight-normal">
                                          {tax.CGST}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell className="text-left">
                                          {STRING_CONSTANTS.SGST}
                                        </TableCell>
                                        <TableCell className="text-dark text-right font-weight-normal">
                                          {tax.SGST}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell className="text-left">
                                          {STRING_CONSTANTS.HSN_CODE}
                                        </TableCell>

                                        {hsnCodes.map((hsnCode, index) => (
                                          <TableCell
                                            key={index}
                                            className="text-dark text-right font-weight-normal"
                                          >
                                            {hsnCode || "N/A"}{" "}
                                            {/* Show 'N/A' if hsnCode is empty */}
                                          </TableCell>
                                        ))}
                                      </TableRow>
                                      <TableRow>
                                        <TableCell className="text-left">
                                          {STRING_CONSTANTS.ORDER_DATE}
                                        </TableCell>
                                        <TableCell className="text-dark text-right font-weight-normal">
                                          {formatDate(
                                            detailedInfo.orderDetails?.orderDate
                                          ) || ""}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell className="text-left">
                                          {STRING_CONSTANTS.PAYMENT_ID}
                                        </TableCell>
                                        <TableCell className="text-dark text-right font-weight-normal">
                                          {detailedInfo?.paymentDetails
                                            ?.transactionId || ""}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell className="text-left">
                                          {STRING_CONSTANTS.INVOICE_DATE}
                                        </TableCell>
                                        <TableCell className="text-dark text-right font-weight-normal">
                                          {formatDate(
                                            detailedInfo?.invoiceDate
                                          ) || ""}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell className="text-left">
                                          {STRING_CONSTANTS.TOTAL_AMOUNT}
                                        </TableCell>
                                        <TableCell className="text-dark text-right font-weight-normal">
                                          {"₹"}{" "}
                                          {detailedInfo.totals?.totalAmount ||
                                            ""}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell className="text-left">
                                          {STRING_CONSTANTS.TOTAL_TAX}
                                        </TableCell>
                                        <TableCell className="text-dark text-right font-weight-normal">
                                          {"₹"}{" "}
                                          {detailedInfo.totals?.totalTax || ""}
                                        </TableCell>
                                      </TableRow>
                                    </Table>
                                  </Grid>
                                  <Grid item xs={12} className="modal-footer">
                                    <Button
                                      onClick={handleClose1}
                                      variant="outlined"
                                      color="error"
                                    >
                                      {STRING_CONSTANTS.CLOSE}
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Dialog>
                              <Dialog
                                open={openCommentModal}
                                onClose={handleCloseCommentModal}
                                aria-labelledby="comment-modal-title"
                                aria-describedby="comment-modal-description"
                                fullWidth
                                maxWidth="sm"
                              >
                                <Grid
                                  container
                                  justifyContent="center"
                                  alignItems="center"
                                >
                                  {/* Modal Header */}
                                  <Grid
                                    item
                                    xs={12}
                                    className="modal-header"
                                    style={{
                                      padding: "16px",
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <h5 id="comment-modal-title">
                                      Invoice No: {selectedInvoiceId}
                                    </h5>
                                    <Button
                                      onClick={handleCloseCommentModal}
                                      color="inherit"
                                    >
                                      <span
                                        style={{ fontSize: "20px" }}
                                        aria-hidden="true"
                                      >
                                        &#128473;
                                      </span>
                                    </Button>
                                  </Grid>

                                  {/* Modal Body */}
                                  <Grid
                                    item
                                    xs={12}
                                    className="modal-body"
                                    style={{ padding: "16px" }}
                                  >
                                    {/* Display existing comments */}
                                    <div
                                      style={{
                                        maxHeight: "300px",
                                        overflowY: "auto",
                                        marginBottom: "16px",
                                      }}
                                    >
                                     {commentsList.length === 0 ? (
                                        <p>No comments available.</p>
                                      ) : (
                                        commentsList.map((comment, index) => {
                                          const date = new Date(comment.date); // Convert UTC to Date object
                                      
                                          
                                          const time = date.toLocaleTimeString('en-GB', {
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hour12: true
                                          }); // Format date and time

                                          return (
                                            <div
                                              key={index}
                                              style={{ marginBottom: "8px" }}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <strong
                                                  style={{ color: "grey" }}
                                                >
                                                  {comment.name}
                                                </strong>
                                                <span
                                                  style={{
                                                    fontSize: "0.8rem",
                                                    color: "gray",
                                                  }}
                                                >
                                                 {formatDate(
                                            comment.date
                                          ) || ""}
                                       {"  "}
                                       <span style={{ fontSize: '0.8em', color: 'grey' }}> {time}</span>
                                                </span>
                                              </div>
                                              <div>{comment.commentText}</div>
                                            </div>
                                          );
                                        })
                                      )}
                                    </div>

                                    {/* TextField for adding new comment */}
                                    <TextField
                                      fullWidth
                                      variant="standard"
                                      value={comment}
                                      onChange={(e) =>
                                        setComment(e.target.value)
                                      }
                                      multiline
                                      rows={1}
                                      placeholder="Type your comment here..."
                                    />
                                  </Grid>

                                  {/* Modal Footer */}
                                  <Grid
                                    item
                                    xs={12}
                                    className="modal-footer"
                                    style={{
                                      padding: "16px",
                                      display: "flex",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <Button
                                      onClick={handleCloseCommentModal}
                                      variant="outlined"
                                      color="error"
                                    >
                                      Close
                                    </Button>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      style={{ marginLeft: "8px" }}
                                      onClick={handleSaveComment}
                                    >
                                      Comment
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Dialog>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </TabPanel>
                      {permissions.some(
                        (item) =>
                          item.keyword === "invoices" &&
                          item.isEdit === true
                      ) && (
                        <TabPanel value="2">
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>{STRING_CONSTANTS.SN_NO}</TableCell>
                                <TableCell>
                                  {STRING_CONSTANTS.INVOICE_NO}
                                </TableCell>
                                <TableCell>
                                  {STRING_CONSTANTS.SOLD_BY}
                                </TableCell>
                                <TableCell>
                                  {STRING_CONSTANTS.CUSTOMER_MOBILE}
                                </TableCell>
                                <TableCell>{STRING_CONSTANTS.AMOUNT}</TableCell>
                                <TableCell>{STRING_CONSTANTS.DATE}</TableCell>
                                <TableCell>
                                  {STRING_CONSTANTS.ACTIONS}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {loading ? (
                                <TableRow>
                                  <TableCell
                                    colSpan={10}
                                    style={{ textAlign: "center" }}
                                  >
                                    <CircularProgress />
                                  </TableCell>
                                </TableRow>
                              ) : values.invoices.length === 0 ? (
                                <TableRow>
                                  <TableCell
                                    colSpan={10}
                                    style={{ textAlign: "center" }}
                                  >
                                    No data found
                                  </TableCell>
                                </TableRow>
                              ) : (
                                values.invoices
                                  .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                  )
                                  .map((invoice, index) => (
                                    <TableRow key={invoice._id}>
                                      <TableCell>
                                        {page * rowsPerPage + index + 1}
                                      </TableCell>
                                      <TableCell>
                                        {invoice.invoiceNumber}
                                      </TableCell>
                                      <TableCell>
                                        <TableCell>
                                          {invoice?.soldBy?.companyName ||
                                            invoice?.sellerAddress?.name}
                                        </TableCell>
                                      </TableCell>
                                      <TableCell>
                                        {invoice?.customerMobile ||
                                          invoice?.buyerMobile}
                                      </TableCell>
                                      <TableCell>
                                        {invoice.totals.totalAmount}
                                      </TableCell>
                                      <TableCell>
                                        {formatDate(
                                          invoice.orderDetails.orderDate
                                        )}
                                      </TableCell>
                                      <TableCell align="center">
                                        <IconButton
                                          onClick={() =>
                                            handleViewDetails(invoice._id)
                                          }
                                        >
                                          <i
                                            className="ml-1"
                                            data-tooltip-id="1"
                                            data-tooltip-content={`${VIEW_DET}`}
                                            style={{ fontStyle: "normal" }}
                                          >
                                            {" "}
                                            <FiEye size="20" color="#9368f3" />
                                            <Tooltip
                                              id="1"
                                              style={{ fontSize: "12px" }}
                                            />
                                          </i>
                                        </IconButton>

                                        <IconButton
                                          onClick={() =>
                                            downloadPdf(invoice.invoiceNumber)
                                          }
                                        >
                                          <i
                                            className="ml-1"
                                            data-tooltip-id="1"
                                            data-tooltip-content={`${VIEW_PDF}`}
                                            style={{ fontStyle: "normal" }}
                                          >
                                            {" "}
                                            <AiOutlineFilePdf
                                              size="20"
                                              color="#9368f3"
                                            />
                                            <Tooltip
                                              id="1"
                                              style={{ fontSize: "12px" }}
                                            />
                                          </i>
                                        </IconButton>
                                        <IconButton
                                          onClick={() =>
                                            handleAddComment(invoice._id,"supplier")
                                          }
                                        >
                                          <i
                                            className="ml-1"
                                            data-tooltip-id="2"
                                            data-tooltip-content="Add Comment"
                                            style={{ fontStyle: "normal" }}
                                          >
                                            <AiOutlineEdit
                                              size="20"
                                              color="#4CAF50"
                                            />
                                            <Tooltip
                                              id="2"
                                              style={{ fontSize: "12px" }}
                                            />
                                          </i>
                                        </IconButton>
                                      </TableCell>
                                    </TableRow>
                                  ))
                              )}
                              <Dialog
                                open={open1}
                                onClose={handleClose1}
                                aria-labelledby="details-modal-title"
                                aria-describedby="details-modal-description"
                                style={{
                                  maxHeight: "80vh",
                                  overflowY: "auto",
                                  marginTop: "100px",
                                }}
                                fullWidth
                                disableEnforceFocus
                                maxWidth="lg"
                              >
                                <Grid
                                  container
                                  justifyContent="center"
                                  alignItems="center"
                                  className="modal-content"
                                >
                                  <Grid item xs={12} className="modal-header">
                                    <h5 id="details-modal-title">
                                      {STRING_CONSTANTS.DETAILS}
                                    </h5>
                                    <Button
                                      onClick={handleClose1}
                                      color="inherit"
                                    >
                                      <span
                                        style={{ fontSize: "20px" }}
                                        aria-hidden="true"
                                      >
                                        &#128473;
                                      </span>
                                    </Button>
                                  </Grid>
                                  <Grid item xs={12} className="modal-body">
                                    <Table className="col-12">
                                      <TableRow>
                                        <TableCell className="text-left">
                                          {STRING_CONSTANTS.BILLING_ADD}
                                        </TableCell>
                                        <TableCell className="text-dark text-right font-weight-normal">
                                          {detailedInfo.billingAddress
                                            ?.address1 || ""}
                                          <div>
                                            {detailedInfo.billingAddress
                                              ?.address2 || ""}
                                          </div>
                                          <div>
                                            {detailedInfo.billingAddress
                                              ?.landMark || ""}
                                          </div>
                                          <div>
                                            {detailedInfo.billingAddress
                                              ?.state || ""}
                                          </div>
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell className="text-left">
                                          {STRING_CONSTANTS.SHIPPING_ADDRESS}
                                        </TableCell>
                                        <TableCell className="text-dark text-right font-weight-normal">
                                          {detailedInfo.shippingAddress
                                            ?.address1 || ""}
                                          <div>
                                            {detailedInfo.shippingAddress
                                              ?.address2 || ""}
                                          </div>
                                          <div>
                                            {detailedInfo.shippingAddress
                                              ?.landMark || ""}
                                          </div>
                                          <div>
                                            {detailedInfo.shippingAddress
                                              ?.state || ""}
                                          </div>
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell className="text-left">
                                          {STRING_CONSTANTS.SOLD_BY}
                                        </TableCell>
                                        <TableCell className="text-dark text-right font-weight-normal">
                                          {detailedInfo.soldBy?.name || ""}
                                          <div>
                                            {" "}
                                            {detailedInfo.soldBy?.address || ""}
                                          </div>
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell className="text-left">
                                          {STRING_CONSTANTS.ORDER_NO}
                                        </TableCell>
                                        <TableCell className="text-dark text-right font-weight-normal">
                                          {detailedInfo.orderDetails
                                            ?.orderNumber || ""}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell className="text-left">
                                          {STRING_CONSTANTS.ORDER_DATE}
                                        </TableCell>
                                        <TableCell className="text-dark text-right font-weight-normal">
                                          {formatDate(
                                            detailedInfo.orderDetails?.orderDate
                                          ) || ""}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell className="text-left">
                                          {STRING_CONSTANTS.INVOICE_NO}
                                        </TableCell>
                                        <TableCell className="text-dark text-right font-weight-normal">
                                          {detailedInfo.orderDetails
                                            ?.invoiceNumber || ""}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell className="text-left">
                                          {STRING_CONSTANTS.INVOICE_DATE}
                                        </TableCell>
                                        <TableCell className="text-dark text-right font-weight-normal">
                                          {formatDate(
                                            detailedInfo.orderDetails
                                              ?.invoiceDate
                                          ) || ""}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell className="text-left">
                                          {STRING_CONSTANTS.TOTAL_AMOUNT}
                                        </TableCell>
                                        <TableCell className="text-dark text-right font-weight-normal">
                                          {"₹"}{" "}
                                          {detailedInfo.totals?.totalAmount ||
                                            ""}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell className="text-left">
                                          {STRING_CONSTANTS.TOTAL_TAX}
                                        </TableCell>
                                        <TableCell className="text-dark text-right font-weight-normal">
                                          {"₹"}{" "}
                                          {detailedInfo.totals?.totalTax || ""}
                                        </TableCell>
                                      </TableRow>
                                    </Table>
                                  </Grid>
                                  <Grid item xs={12} className="modal-footer">
                                    <Button
                                      onClick={handleClose1}
                                      variant="outlined"
                                      color="error"
                                    >
                                      {STRING_CONSTANTS.CLOSE}
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Dialog>
                              <Dialog
                                open={openCommentModal}
                                onClose={handleCloseCommentModal}
                                aria-labelledby="comment-modal-title"
                                aria-describedby="comment-modal-description"
                                fullWidth
                                maxWidth="sm"
                              >
                                <Grid
                                  container
                                  justifyContent="center"
                                  alignItems="center"
                                >
                                  {/* Modal Header */}
                                  <Grid
                                    item
                                    xs={12}
                                    className="modal-header"
                                    style={{
                                      padding: "16px",
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <h5 id="comment-modal-title">
                                      Invoice No: {selectedInvoiceId}
                                    </h5>
                                    <Button
                                      onClick={handleCloseCommentModal}
                                      color="inherit"
                                    >
                                      <span
                                        style={{ fontSize: "20px" }}
                                        aria-hidden="true"
                                      >
                                        &#128473;
                                      </span>
                                    </Button>
                                  </Grid>

                                  {/* Modal Body */}
                                  <Grid
                                    item
                                    xs={12}
                                    className="modal-body"
                                    style={{ padding: "16px" }}
                                  >
                                    {/* Display existing comments */}
                                    <div
                                      style={{
                                        maxHeight: "300px",
                                        overflowY: "auto",
                                        marginBottom: "16px",
                                      }}
                                    >
                                   {commentsList.length === 0 ? (
                                        <p>No comments available.</p>
                                      ) : (
                                        commentsList.map((comment, index) => {
                                          const date = new Date(comment.date); // Convert UTC to Date object
                                          console.log(date,"date,,");
                                          
                                          const time = date.toLocaleTimeString('en-GB', {
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hour12: true
                                          }); // Format date and time

                                          return (
                                            <div
                                              key={index}
                                              style={{ marginBottom: "8px" }}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <strong
                                                  style={{ color: "grey" }}
                                                >
                                                  {comment.name}
                                                </strong>
                                                <span
                                                  style={{
                                                    fontSize: "0.8rem",
                                                    color: "gray",
                                                  }}
                                                >
                                                 {formatDate(
                                            comment.date
                                          ) || ""}
                                       {"  "}
                                       <span style={{ fontSize: '0.8em', color: 'grey' }}> {time}</span>
                                                </span>
                                              </div>
                                              <div>{comment.commentText}</div>
                                            </div>
                                          );
                                        })
                                      )}
                                    </div>

                                    {/* TextField for adding new comment */}
                                    <TextField
                                      fullWidth
                                      variant="standard"
                                      value={comment}
                                      onChange={(e) =>
                                        setComment(e.target.value)
                                      }
                                      multiline
                                      rows={1}
                                      placeholder="Type your comment here..."
                                    />
                                  </Grid>

                                  {/* Modal Footer */}
                                  <Grid
                                    item
                                    xs={12}
                                    className="modal-footer"
                                    style={{
                                      padding: "16px",
                                      display: "flex",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <Button
                                      onClick={handleCloseCommentModal}
                                      variant="outlined"
                                      color="error"
                                    >
                                      Close
                                    </Button>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      style={{ marginLeft: "8px" }}
                                      onClick={handleSaveComment}
                                    >
                                      Comment
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Dialog>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </TabPanel>
                      )}
                    
                    </TabContext>

                    <TablePagination
                      rowsPerPageOptions={[10, 50, 100]}
                      component="div"
                      count={values.invoices.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Invoices;
