import React, { useEffect } from "react";
import Cookies from "universal-cookie";
import { Scrollbars } from "react-custom-scrollbars-2";
import { FaCoins } from "react-icons/fa";
import { BsFillFileWordFill, BsFillCartCheckFill } from "react-icons/bs";
import { FcDocument, FcFile, FcPaid, FcSurvey } from "react-icons/fc";
import { Link } from "react-router-dom";
import { FaRegCalendar } from "react-icons/fa";
import { MdPayment } from "react-icons/md";
import $ from "jquery";
import { STRING_CONSTANTS } from "../constants/stringConstants";
import { AiOutlineFileText } from "react-icons/ai";

const cookies = new Cookies();

const Sidebar = ({ type }) => {
  const localRole = JSON.parse(localStorage.getItem("role"));
  const permissions =
    localRole && localRole.permissions ? localRole.permissions : "All";

  useEffect(() => {
    if (window.innerWidth <= 768) {
      document.body.classList.remove("sidebar-enable");
    }
    $(".nav-second-level").removeClass("mm-show").addClass("mm-collapse");
    if (type === "sellProducts") {
      $("#sellProducts").removeClass("mm-collapse").addClass("mm-show");
    } else if (type === "buyProducts") {
      $("#buyProducts").removeClass("mm-collapse").addClass("mm-show");
    } else if (type === "businessProfiles") {
      $("#businessProfilesGrp").removeClass("mm-collapse").addClass("mm-show");
    } else if (type === "profiles") {
      $("#profilesGrp").removeClass("mm-collapse").addClass("mm-show");
    } else if (type === "subscription") {
      $("#subscriptionGrp").removeClass("mm-collapse").addClass("mm-show");
    } else if (type === "settings") {
      $("#settingsGrp").removeClass("mm-collapse").addClass("mm-show");
    } else if (type == "configurations") {
      $("#configGrp").removeClass("mm-collapse").addClass("mm-show");
    }
  }, []);

  const handleClick = (e) => {
    if ($(e.target).next().hasClass("mm-collapse")) {
      $(".nav-second-level").removeClass("mm-show").addClass("mm-collapse");
      $(e.target).next().removeClass("mm-collapse").addClass("mm-show");
    } else {
      $(".nav-second-level").removeClass("mm-show").addClass("mm-collapse");
    }
  };

  return (
    <div className="left-side-menu">
      <Scrollbars style={{ width: "100%", height: "100%" }}>
        <div id="sidebar-menu">
          <ul className="metismenu" id="side-menu">
            {permissions === "All" ||
              (permissions.some(
                (item) => item.keyword === "dashboard" && item.isView === true
              ) && (
                <li>
                  <Link to="/dashboard">
                    <i className="fe-airplay"></i>{" "}
                    <span>{STRING_CONSTANTS.DASHBOARD}</span>
                  </Link>
                </li>
              ))}

            {permissions !== "All" &&
            permissions.some(
              (item) =>
                item.keyword === "buyProductPendingList" &&
                item.isView === true
            ) &&
            permissions.some(
              (item) =>
                item.keyword === "buyProductApprovedList" &&
                item.isView === true
            ) &&
            permissions.some(
              (item) =>
                item.keyword === "buyProductRejectedList" &&
                item.isView === true
            ) &&
            permissions.some(
              (item) =>
                item.keyword === "buyProductExpiredList" &&
                item.isView === true
            ) &&
            permissions.some(
              (item) =>
                item.keyword === "buyProductCompletedList" &&
                item.isView === true
            ) && (
              <li>
                <a className="groupMenu" href="#" onClick={handleClick}>
                  <i className="fe-shopping-cart"></i> Buy Products
                  <span className="menu-arrow"></span>
                </a>
                <ul id="buyProducts" className="nav-second-level  mm-collapse">
                  {permissions === "All" ||
                    (permissions.some(
                      (item) =>
                        item.keyword === "buyProductPendingList" &&
                        item.isView === true
                    ) && (
                      <li>
                        <Link
                          to="/buyProducts/penlist"
                          className="waves-effect Products"
                        >
                          <span className="font-size-16">
                            {STRING_CONSTANTS.PENDING_LIST}
                          </span>
                        </Link>
                      </li>
                    ))}

                  {permissions === "All" ||
                    (permissions.some(
                      (item) =>
                        item.keyword === "buyProductApprovedList" &&
                        item.isView === true
                    ) && (
                      <li>
                        <Link
                          to="/buyProducts/applist"
                          className="waves-effect Products"
                        >
                          <span className="font-size-16">
                            {STRING_CONSTANTS.APPROVELIST}
                          </span>
                        </Link>
                      </li>
                    ))}

                  {permissions === "All" ||
                    (permissions.some(
                      (item) =>
                        item.keyword === "buyProductRejectedList" &&
                        item.isView === true
                    ) && (
                      <li>
                        <Link
                          to="/buyProducts/rejlist"
                          className="waves-effect Products"
                        >
                          <span className="font-size-16">
                            {STRING_CONSTANTS.REJECTED_LIST}
                          </span>
                        </Link>
                      </li>
                    ))}

                  {permissions === "All" ||
                    (permissions.some(
                      (item) =>
                        item.keyword === "buyProductExpiredList" &&
                        item.isView === true
                    ) && (
                      <li>
                        <Link
                          to="/buyProducts/explist"
                          className="waves-effect Products"
                        >
                          <span className="font-size-16">
                            {STRING_CONSTANTS.EXPIRED_LIST}
                          </span>
                        </Link>
                      </li>
                    ))}

                  {permissions === "All" ||
                    (permissions.some(
                      (item) =>
                        item.keyword === "buyProductCompletedList" &&
                        item.isView === true
                    ) && (
                      <li>
                        <Link
                          to="/buyProducts/complist"
                          className="waves-effect Products"
                        >
                          <span className="font-size-16">
                            {STRING_CONSTANTS.COMPLETED_LIST}
                          </span>
                        </Link>
                      </li>
                    ))}
                </ul>
              </li>
            )}
            {permissions !== "All" &&
            permissions.some(
              (item) =>
                item.keyword === "sellProductPendingList" &&
                item.isView === true
            ) &&
            permissions.some(
              (item) =>
                item.keyword === "sellProductApprovedList" &&
                item.isView === true
            ) &&
            permissions.some(
              (item) =>
                item.keyword === "sellProductRejectedList" &&
                item.isView === true
            ) &&
            permissions.some(
              (item) =>
                item.keyword === "sellProductExpiredList" &&
                item.isView === true
            ) &&
            permissions.some(
              (item) =>
                item.keyword === "sellProductSoldoutList" &&
                item.isView === true
            ) &&(
              <li>
                <a className="groupMenu" href="#" onClick={handleClick}>
                  <i className="fe-list"></i> {STRING_CONSTANTS.SELL_PRODUCTS}
                  <span className="menu-arrow"></span>
                </a>
                <ul
                  id="sellProducts"
                  className="nav-second-level"
                  aria-expanded="false"
                >
                  {permissions === "All" ||
                    (permissions.some(
                      (item) =>
                        item.keyword === "sellProductPendingList" &&
                        item.isView === true
                    ) && (
                      <li>
                        <Link
                          to="/sellProducts/pendinglist"
                          className="waves-effect"
                        >
                          <span className="font-size-16">
                            {STRING_CONSTANTS.PENDING_LIST}
                          </span>
                        </Link>
                      </li>
                    ))}
                  {permissions === "All" ||
                    (permissions.some(
                      (item) =>
                        item.keyword === "sellProductApprovedList" &&
                        item.isView === true
                    ) && (
                      <li>
                        <Link
                          to="/sellProducts/approvedlist"
                          className="waves-effect"
                        >
                          <span className="font-size-16">
                            {STRING_CONSTANTS.APPROVELIST}
                          </span>
                        </Link>
                      </li>
                    ))}
                  {permissions === "All" ||
                    (permissions.some(
                      (item) =>
                        item.keyword === "sellProductRejectedList" &&
                        item.isView === true
                    ) && (
                      <li>
                        <Link
                          to="/sellProducts/rejectedlist"
                          className="waves-effect"
                        >
                          <span className="font-size-16">
                            {STRING_CONSTANTS.REJECTED_LIST}
                          </span>
                        </Link>
                      </li>
                    ))}
                  {permissions === "All" ||
                    (permissions.some(
                      (item) =>
                        item.keyword === "sellProductExpiredList" &&
                        item.isView === true
                    ) && (
                      <li>
                        <Link
                          to="/sellProducts/expiredlist"
                          className="waves-effect"
                        >
                          <span className="font-size-16">
                            {STRING_CONSTANTS.EXPIRED_LIST}
                          </span>
                        </Link>
                      </li>
                    ))}
                  {permissions === "All" ||
                    (permissions.some(
                      (item) =>
                        item.keyword === "sellProductSoldoutList" &&
                        item.isView === true
                    ) && (
                      <li>
                        <Link
                          to="/sellProducts/soldlist"
                          className="waves-effect"
                        >
                          <span className="font-size-16">
                            {STRING_CONSTANTS.SOLD_OUT_LIST}
                          </span>
                        </Link>
                      </li>
                    ))}
                </ul>
              </li>
            )}
            {permissions !== "All" &&
            permissions.some(
              (item) =>
                item.keyword === "businessProfilesPendingList" &&
                item.isView === true
            ) &&
            permissions.some(
              (item) =>
                item.keyword === "businessProfilesApprovedList" &&
                item.isView === true
            ) &&
            permissions.some(
              (item) =>
                item.keyword === "businessProfilesRejectedList" &&
                item.isView === true
            ) &&(
              <li>
                <a className="groupMenu" href="#" onClick={handleClick}>
                  <i className="fas fa-building"></i>
                  {STRING_CONSTANTS.BUSINESS_PROFILE}
                  <span className="menu-arrow"></span>
                </a>
                <ul
                  id="businessProfilesGrp"
                  className="nav-second-level  mm-collapse"
                  aria-expanded="false"
                >
                  {permissions === "All" ||
                    (permissions.some(
                      (item) =>
                        item.keyword === "businessProfilesPendingList" &&
                        item.isView === true
                    ) && (
                      <li>
                        <Link to="/BusinessProfile/pendinglist">
                          <span>{STRING_CONSTANTS.PENDING_LIST}</span>
                        </Link>
                      </li>
                    ))}
                  {permissions === "All" ||
                    (permissions.some(
                      (item) =>
                        item.keyword === "businessProfilesApprovedList" &&
                        item.isView === true
                    ) && (
                      <li>
                        <Link to="/BusinessProfile/approvedlist">
                          <span>{STRING_CONSTANTS.APPROVELIST}</span>
                        </Link>
                      </li>
                    ))}
                  {permissions === "All" ||
                    (permissions.some(
                      (item) =>
                        item.keyword === "businessProfilesRejectedList" &&
                        item.isView === true
                    ) && (
                      <li>
                        <Link to="/BusinessProfile/rejectedlist">
                          <span>Rejected list</span>
                        </Link>
                      </li>
                    ))}
                </ul>
              </li>
            )}
            {cookies.get("login_type") !== "callSupport" && (
              <>
                {permissions !== "All" &&
                permissions.some(
                  (item) =>
                    item.keyword === "profilesCustomers" &&
                    item.isView === true
                ) &&
                permissions.some(
                  (item) =>
                    item.keyword === "profilesOrganisations" &&
                    item.isView === true
                ) &&
                permissions.some(
                  (item) =>
                    item.keyword === "profilesVolunteers" &&
                    item.isView === true
                ) &&(
                  <li>
                    <a className="groupMenu" href="#" onClick={handleClick}>
                      <i className="fas fa-users"></i>{" "}
                      {STRING_CONSTANTS.PROFILES}
                      <span className="menu-arrow"></span>
                    </a>
                    <ul
                      id="profilesGrp"
                      className="nav-second-level"
                      aria-expanded="false"
                    >
                      {permissions === "All" ||
                        (permissions.some(
                          (item) =>
                            item.keyword === "profilesCustomers" &&
                            item.isView === true
                        ) && (
                          <li>
                            <Link to="/profiles/customer">
                              {STRING_CONSTANTS.CUSTOMERS}
                            </Link>
                          </li>
                        ))}
                      {permissions === "All" ||
                        (permissions.some(
                          (item) =>
                            item.keyword === "profilesOrganisations" &&
                            item.isView === true
                        ) && (
                          <li>
                            <Link to="/profiles/organisation">
                              {STRING_CONSTANTS.ORG}
                            </Link>
                          </li>
                        ))}
                      {permissions === "All" ||
                        (permissions.some(
                          (item) =>
                            item.keyword === "profilesVolunteers" &&
                            item.isView === true
                        ) && (
                          <li>
                            <Link to="/profiles/members">
                              {STRING_CONSTANTS.VOLUNTEERS}
                            </Link>
                          </li>
                        ))}
                    </ul>
                  </li>
                )}
                {permissions !== "All" &&
                permissions.some(
                  (item) =>
                    item.keyword === "subscriptionManage" &&
                    item.isView === true
                ) &&
                permissions.some(
                  (item) =>
                    item.keyword === "subscriptionList" && item.isView === true
                ) && (
                  <li>
                    <a className="groupMenu" href="#" onClick={handleClick}>
                      <i className="fa fa-book fa-fw"></i>
                      {STRING_CONSTANTS.SUBSCRIPTION}
                      <span className="menu-arrow"></span>
                    </a>
                    <ul
                      id="subscriptionGrp"
                      className="nav-second-level"
                      aria-expanded="false"
                    >
                      {permissions === "All" ||
                        (permissions.some(
                          (item) =>
                            item.keyword === "subscriptionManage" &&
                            item.isView === true
                        ) && (
                          <li>
                            <Link to="/subscription/manage">
                              {STRING_CONSTANTS.MANAGE}
                            </Link>
                          </li>
                        ))}
                      {permissions === "All" ||
                        (permissions.some(
                          (item) =>
                            item.keyword === "subscriptionList" &&
                            item.isView === true
                        ) && (
                          <li>
                            <Link to="/subscription/list">
                              {STRING_CONSTANTS.LIST}
                            </Link>
                          </li>
                        ))}
                    </ul>
                  </li>
                )}
              </>
            )}
            {permissions === "All" ||
              (permissions.some(
                (item) => item.keyword === "Keyword" && item.isView === true
              ) && (
                <li>
                  <Link to="/keywords/keywords">
                    <BsFillFileWordFill className="ml-1" />
                    <span className="ml-2">{STRING_CONSTANTS.KEYWORDS}</span>
                  </Link>
                </li>
              ))}
            {permissions === "All" ||
              (permissions.some(
                (item) => item.keyword === "survey" && item.isView === true
              ) && (
                <li>
                  <Link to="/survey">
                    <FcSurvey className="ml-1" />
                    <span className="ml-2">{STRING_CONSTANTS.SURVEY}</span>
                  </Link>
                </li>
              ))}
            {cookies.get("login_type") !== "callSupport" && (
              <>
                {permissions === "All" ||
                  (permissions.some(
                    (item) => item.keyword === "coins" && item.isView === true
                  ) && (
                    <li>
                      <Link to="/coins/coins">
                        <FaCoins className="ml-1" />
                        <span className="ml-2">{STRING_CONSTANTS.COINS}</span>
                      </Link>
                    </li>
                  ))}
                {permissions === "All" ||
                  (permissions.some(
                    (item) => item.keyword === "orders" && item.isView === true
                  ) && (
                    <li>
                      <Link to="/orders">
                        <BsFillCartCheckFill className="ml-1" />
                        <span className="ml-2">{STRING_CONSTANTS.ORDERS}</span>
                      </Link>
                    </li>
                  ))}
                {permissions === "All" ||
                  (permissions.some(
                    (item) => item.keyword === "payment" && item.isView === true
                  ) && (
                    <li>
                      <Link to="/payment">
                        <MdPayment className="ml-1" />
                        <span className="ml-2">
                          {STRING_CONSTANTS.PAYMENTS}
                        </span>
                      </Link>
                    </li>
                  ))}
     {permissions === "All" ||
              (permissions.some(
                (item) => item.keyword === "invoices" && item.isView === true
              ) && (
                <li>
                  <Link to="/invoices">
                  <AiOutlineFileText className="ml-1" />
                    <span className="ml-2">{STRING_CONSTANTS.INVOICES}</span>
                  </Link>
                </li>
              ))}
                {permissions === "All" ||
                  (permissions.some(
                    (item) =>
                      item.keyword === "harvestplanner" && item.isView === true
                  ) && (
                    <li>
                      <Link to="/harvestPlanner">
                        <FaRegCalendar className="ml-1" />
                        <span className="ml-2">
                          {STRING_CONSTANTS.HARVEST_PLANNER}
                        </span>
                      </Link>
                    </li>
                  ))}
                {permissions === "All" ||
                  (permissions.some(
                    (item) =>
                      item.keyword === "configurations" && item.isView === true
                  ) && (
                    <li>
                      <a className="groupMenu" href="#" onClick={handleClick}>
                        <i className="fas fa-users-cog"></i>{" "}
                        {STRING_CONSTANTS.CONFIG}
                        <span className="menu-arrow"></span>
                      </a>
                      <ul
                        id="configGrp"
                        className="nav-second-level"
                        aria-expanded="false"
                      >
                        {permissions.some(
                          (item) =>
                            item.keyword === "configurationsUsers" &&
                            item.isView === true
                        ) && (
                          <>
                            <li>
                              <Link to="/configurations/users">
                                {STRING_CONSTANTS.PORTAL_USERS}
                              </Link>
                            </li>
                          </>
                        )}
                        {permissions.some(
                          (item) =>
                            item.keyword === "configurationsRole" &&
                            item.isView === true
                        ) && (
                          <>
                            <li>
                              <Link to="/configurations/roles">
                                {STRING_CONSTANTS.ROLES}
                              </Link>
                            </li>
                          </>
                        )}
                        {permissions.some(
                          (item) =>
                            item.keyword === "configurationsPermission" &&
                            item.isView === true
                        ) && (
                          <>
                            <li>
                              <Link to="/configurations/rolepermissions">
                                {STRING_CONSTANTS.PERMISSON}
                              </Link>
                            </li>
                          </>
                        )}
                         {permissions.some(
                          (item) =>
                            item.keyword === "configurationsPermission" &&
                            item.isView === true
                        ) && (
                          <>
                            <li>
                              <Link to="/configurations/mobilepermissions">
                                {STRING_CONSTANTS.MOBILE_PERMISSON}
                              </Link>
                            </li>
                          </>
                        )}
                      </ul>
                    </li>
                  ))}
                  
                {permissions === "All" ||
                  (permissions.some(
                    (item) =>
                      item.keyword === "settings" && item.isView === true
                  ) && (
                    <li>
                      <a className="groupMenu" href="#" onClick={handleClick}>
                        <i className="fe-settings"></i>{" "}
                        {STRING_CONSTANTS.SETTINGS}
                        <span className="menu-arrow"></span>
                      </a>
                      <ul
                        id="settingsGrp"
                        className="nav-second-level"
                        aria-expanded="false"
                      >
                          {permissions.some(
                          (item) =>
                            item.keyword === "settingsVouchers" &&
                            item.isView === true
                        ) && (
                          <>
                            <li>
                          <Link to="/settings/vocher">
                            {STRING_CONSTANTS.VOUCHER}
                          </Link>
                        </li>
                          </>
                        )}
                       
                       {permissions.some(
                          (item) =>
                            item.keyword === "settingsBTypes" &&
                            item.isView === true
                        ) && (
                          <>
                           <li>
                          <Link to="/settings/variety">
                            {STRING_CONSTANTS.VARIETY}
                          </Link>
                        </li>
                          </>
                        )}
                           {permissions.some(
                          (item) =>
                            item.keyword === "settingsBTypes" &&
                            item.isView === true
                        ) && (
                          <>
                           <li>
                          <Link to="/settings/gst">
                            {STRING_CONSTANTS.GST}
                          </Link>
                        </li>
                          </>
                        )}
                        {permissions.some(
                          (item) =>
                            item.keyword === "settingsCrops" &&
                            item.isView === true
                        ) && (
                          <>
                           <li>
                          <Link to="/settings/crop">
                            {STRING_CONSTANTS.CROP}
                          </Link>
                        </li>
                          </>
                        )}
                        {permissions.some(
                          (item) =>
                            item.keyword === "settingsFaq" &&
                            item.isView === true
                        ) && (
                          <>
                            <li>
                          <Link to="/settings/faq">
                            {STRING_CONSTANTS.FAQS}
                          </Link>
                        </li>
                          </>
                        )}
                          {permissions.some(
                          (item) =>
                            item.keyword === "settingsLanguages" &&
                            item.isView === true
                        ) && (
                          <>
                          <li>
                          <Link to="/settings/language">
                            {STRING_CONSTANTS.LANGUAGES}
                          </Link>
                        </li>
                          </>
                        )}
                         {permissions.some(
                          (item) =>
                            item.keyword === "settingsCustomerType" &&
                            item.isView === true
                        ) && (
                          <>
                           <li>
                          <Link to="/settings/userType">
                            {STRING_CONSTANTS.CUSTOMER_TYPE}
                          </Link>
                        </li>
                          </>
                        )}
                          {permissions.some(
                          (item) =>
                            item.keyword === "settingsIntrestedCrops" &&
                            item.isView === true
                        ) && (
                          <>
                         <li>
                          <Link to="/settings/intrestedCrops">
                            {STRING_CONSTANTS.INTRESTED_CROP}
                          </Link>
                        </li>
                          </>
                        )}
                          {permissions.some(
                          (item) =>
                            item.keyword === "settingsBTypes" &&
                            item.isView === true
                        ) && (
                          <>
                         <li>
                          <Link to="/settings/btypes">
                            {STRING_CONSTANTS.BUSSINESS_TYPE}
                          </Link>
                        </li>
                          </>
                        )}
                     
                      
                      </ul>
                    </li>
                  ))}
              </>
            )}
          
          </ul>
        </div>
        <div className="clearfix"></div>
      </Scrollbars>
    </div>
  );
};

export default Sidebar;
