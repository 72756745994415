import React, { useState, useEffect, useRef } from "react";
import {
  Avatar,
  Badge,
  Box,
  IconButton,
  InputAdornment,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import { io } from "socket.io-client";

import Cookies from "universal-cookie";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SendIcon from "@mui/icons-material/Send";
import { formatChatTime } from "../../../utils/chatTime";
import { ADD_EMPLOYEE } from "../../../constants/url";
import { privateApiCall } from "../../../api/privateApi";
import { API } from "../../../config";
import { formatDate } from "../../../utils/dateUtils";
const StyledInput = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input": {
    paddingTop: "12px",
    paddingBottom: "12px",
  },
}));

const ChatBox = ({ selectedChate, onShowContactInfo }) => {
  console.log(selectedChate?.chatId, "selected data");
  const cookies = new Cookies();
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [org_id] = useState(cookies.get("org_id"));
  const socket = useRef();
  const [messageInput, setMessageInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [chatID, setChatID] = useState(null);
  const userName = selectedChate?.otherParticipant?.name || "Unknown User";
  const userImage =
    selectedChate?.otherParticipant?.image ||
    "/static/images/default-avatar.jpg";
  const handleUserClick = () => {
    setChatID(selectedChate.chatId);
    if (onShowContactInfo && selectedChate?.otherParticipant) {
      onShowContactInfo(selectedChate.otherParticipant); // Pass the otherParticipant object
    }
  };
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setIsModalOpen(false);
  };

  useEffect(() => {
    socket.current = io(API);
    socket.current.emit("new-user-add", org_id);

    socket.current.on("receiver-msg", (data) => {
      console.log(data, "Received live message");
      if (data.ChatId === selectedChate?.chatId) {
        if (data.chatImage?.length > 0) {
          const normalizedImages = data.chatImage.map((img) =>
            img.startsWith("http") ? img : `data:image/jpeg;base64,${img}`
          );
          setMessages((prevMessages) => [
            ...prevMessages,
            { ...data, chatImage: normalizedImages, type: "image" },
          ]);
        }
        else
        {
          setMessages((prevMessages) => [...prevMessages, data]);
        }
        
      }
    });

    return () => {
      socket.current.disconnect();
    };
  }, [org_id, selectedChate?.chatId]);
  // const handleGetMessages = async (chatID) => {
  //   try {
  //     const response = await privateApiCall(
  //       `/api/organisation/getMessages/${chatID}`,
  //       "GET"
  //     );
  //     // console.log(response.data, "message data from api call");
  //     setMessages(response.data);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
  const handleGetMessages = async (chatID) => {
    try {
      const response = await privateApiCall(
        `/api/organisation/getMessages/${chatID}`,
        "GET"
      );
  
      const normalizedMessages = response.data.map((message) => {
        if (message.chatImage) {
          if (Array.isArray(message.chatImage)) {
            // Handle multiple images
            message.chatImage = message.chatImage.map((img) => {
              return img.startsWith("http") ? img : `data:image/jpeg;base64,${img}`;
            });
          } else {
            // Handle single image
            message.chatImage = message.chatImage.startsWith("http")
              ? message.chatImage
              : `data:image/jpeg;base64,${message.chatImage}`;
          }
        }
        return message;
      });
  
      setMessages(normalizedMessages);
    } catch (error) {
      console.error(error);
    }
  };
  
  useEffect(() => {
    

    if (selectedChate?.chatId) {
      setMessages([]);
      handleGetMessages(selectedChate.chatId);
    }
  }, [selectedChate]);
  if (!selectedChate) {
    return (
      <Stack
        height={"100%"}
        maxHeight={"100vh"}
        width={"auto"}
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="subtitle1">Tap to select a user chat</Typography>
      </Stack>
    );
  }
  const handleInputChange = (e) => {
    setMessageInput(e.target.value);
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setPreviewImage(URL.createObjectURL(file));
    }
  };
  const handleRemoveImage = () => {
    setSelectedFile(null);
    setPreviewImage(null);
  };
  const handleSendMessage = async () => {
    if (!messageInput.trim() && !selectedFile) return;
    const formData = new FormData();
    formData.append("ChatId", selectedChate.chatId);
    formData.append("receiverId", selectedChate.messages[0].senderId);
    formData.append("senderId", org_id);
    formData.append("productId", selectedChate.product.productId);

    if (messageInput.trim()) formData.append("text", messageInput);
    if (selectedFile) formData.append("chatImage", selectedFile);
    const messageData = {
      ChatId: selectedChate.chatId,
      receiverId: selectedChate.messages[0].senderId,
      senderId: org_id,
      productId: selectedChate.product.productId,
      text: messageInput,
      chatImage: previewImage,
    };
    console.log(messageData, "messageData******");
    socket.current.emit("send-message", messageData);
    // console.log(messageData,"message data")
    try {
      const requestUrl = `${ADD_EMPLOYEE}/admsg`;
      const response = await privateApiCall(requestUrl, "POST", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(response, "after send response");
     

      // console.log(response,"message send response")
      setMessages((prevMessages) => [...prevMessages, messageData]);
      setMessageInput("");
      handleRemoveImage();
      handleGetMessages(selectedChate.chatId);
    } catch (error) {}
  };
  console.log(messages, "inside chatbox");

  return (
    <Stack height={"100%"} maxHeight={"100vh"} width={"auto"}>
      {/* Chat Header */}
      <Box
        p={2}
        sx={{
          width: "100%",
          backgroundColor: "#F8FAFF",
          //   boxShadow: "0px 0px 2px rgba(0,0,0,0.25)",
          boxShadow: "red",
        }}
      >
        <Stack
          alignItems={"center"}
          direction="row"
          justifyContent={"space-between"}
          sx={{ width: "100%", height: "100%" }}
        >
          <Stack direction={"row"} spacing={2}>
            <Box>
              <Badge>
                <Avatar src={userImage} sx={{ width: 50, height: 50 }} />
              </Badge>
            </Box>
            <Stack spacing={0.2}>
              <Typography
                onClick={handleUserClick}
                variant="subtitle2"
                sx={{ cursor: "pointer" }}
              >
                {userName}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Box>
      {/* Msg */}
      <Box
        width={"100%"}
        sx={{
          flexGrow: 1,
          padding: 2,
          height: 80,
          overflowY: "auto",
          backgroundColor: "#E9ECEF",
        }}
      >
        {messages?.map((message, index) => (
          <Stack
            key={index}
            direction={message.senderId == org_id ? "row-reverse" : "row"}
            alignItems={"center"}
            spacing={1}
            sx={{ marginBottom: 2 }}
          >
            <Avatar
              src={
                message.senderId == org_id
                  ? selectedChate.OrgLogo
                  : selectedChate.otherParticipant.image
              }
              sx={{ width: 32, height: 32 }}
            />
            <Box
              sx={{
                backgroundColor:
                  selectedChate.messages[0].senderId == org_id
                    ? "#0baae5"
                    : "#FFFFFF",
                borderRadius: 1,
                padding: 1.5,
                maxWidth: "75%",
              }}
            >
              {/* Render text if available */}
              {message.text && (
                <Typography variant="body2">{message.text}</Typography>
              )}

              {message.chatImage && Array.isArray(message.chatImage) && (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 1,
                    marginTop: 1,
                  }}
                >
                  {message.chatImage.map((image, imgIndex) => (
                    <img
                      key={imgIndex}
                      src={image}
                      alt={`chat-image-${imgIndex}`}
                      style={{
                        maxWidth: "100px",
                        maxHeight: "100px",
                        borderRadius: "4px",
                        objectFit: "cover",
                      }}
                      onClick={() => openModal(image)}
                    />
                  ))}
                </Box>
              )}

              {/* Time display */}
            </Box>
          </Stack>
        ))}
      </Box>

      {/* Chat footer */}

      <Box p={2} sx={{ backgroundColor: "#F8FAFF" }}>
        {previewImage && (
          <Box
            sx={{
              marginBottom: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <img
              src={previewImage}
              alt="preview"
              style={{
                maxWidth: "100px",
                borderRadius: "4px",
              }}
            />
            <IconButton onClick={handleRemoveImage}>
              <DeleteIcon />
            </IconButton>
          </Box>
        )}
        <Stack direction="row" alignItems={"center"} spacing={1}>
          <StyledInput
            fullWidth
            placeholder="write a message..."
            variant="filled"
            value={messageInput}
            onChange={handleInputChange}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="file-upload"
                    type="file"
                    onChange={handleFileChange}
                  />
                  <label htmlFor="file-upload">
                    <IconButton component="span">
                      <AttachFileIcon />
                    </IconButton>
                  </label>
                </InputAdornment>
              ),
            }}
          />
          <Box>
            <IconButton>
              <SendIcon onClick={handleSendMessage} />
            </IconButton>
          </Box>
        </Stack>
      </Box>
      {isModalOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 500,
          }}
          onClick={closeModal}
        >
          <div
            style={{
              position: "absolute",
              top: "16px",
              
              cursor: "pointer",
              color: "white",
              fontSize: "24px",
              fontWeight: "bold",
              background: "rgba(0, 0, 0, 0.5)",
              borderRadius: "50%",
              padding: "8px",
              zIndex: 600,
            }}
            onClick={closeModal}
          >
            ✖
          </div>

          <img
            src={selectedImage}
            alt="Selected chat"
            style={{
              maxWidth: "80%",
              maxHeight: "70%",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
            }}
            onClick={(e) => e.stopPropagation()} // Prevent modal background click
          />
        </div>
      )}
    </Stack>
  );
};

export default ChatBox;
